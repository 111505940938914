export default {

    props: {
        currentStep: Number,
        imgSrc: String,
        step: Number,
        isDone: Boolean,
    },
    data() {
        return {
            containerClass: 'step-process-card-container',
            isDone_: false,
        };
    },
    methods: {
        initStyle() {
            this.initButtonStyle()

            if (this.isDone) {
                this.isDone_ = true
                this.containerClass += ' done'
                return
            }

            if (this.step < this.currentStep) {
                this.isDone_ = true
                this.containerClass += ' done'
            }
            if (this.step === this.currentStep) {
                this.containerClass += ' current'
            }
        },
        initButtonStyle() {
            const buttonEl = this.$refs.stepCard.querySelector('.n-button');
            if (buttonEl) {
                buttonEl.classList.add('outlined')
                buttonEl.disabled = true
                buttonEl.classList.remove('filled')
            }
            if (this.step < this.currentStep) {
                if (buttonEl) {
                    buttonEl.disabled = false
                }
            }
            if (this.step === this.currentStep) {
                if (buttonEl) {
                    buttonEl.classList.add('filled')
                    buttonEl.classList.remove('outlined')
                    buttonEl.disabled = false
                    return this.containerClass + ' current'
                }
            }
        }
    },
    created() {
    },
    mounted() {
        this.initStyle()
    },
    template: `
    <div :class="containerClass" ref="stepCard" >
        <div v-if="!isDone_" class="step-process-card-marker">[[[step]]]</div>
        <div v-if="isDone_" class="step-process-card-marker"><i class="fas fa-check"></i></div>
        <img class="step-process-card-image" :src="imgSrc">
        <h3>
            <slot name="title">Title</slot>
        </h3>
        <p>
        <slot name="description"></slot>
        </p>
        <slot name="content"></slot>
    </div>
    `,
};