class EVENTS {
}

EVENTS.NAVIGATE = 'on-navigate'

export default {
    props: {
        border: {
            type: Boolean,
            default: true
        }
    },
    emits: Object.values(EVENTS),
    data() {
        return {};
    },
    methods: {
        navigate() {
            this.$emit(EVENTS.NAVIGATE)
        }
    },
    mounted() {
    },
    template: `
        <tr class="section-row"  @click="navigate">
        <td colspan="100%">
                <slot></slot>
        </td>
        </tr>
    `,
};