import NTooltip from "../n-tooltip.js";
import NCheckbox from "./n-checkbox.js";
import NPopper from "../n-popper.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'
EVENTS.SAVE = 'auto-save'

export default {
    inject: ["errors"],
    components: {
        "n-tooltip": NTooltip,
        "n-checkbox": NCheckbox,
        "n-popper": NPopper,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        }
    },
    emits: Object.values(EVENTS),
    props: {
        filterField: String,
        filterOn: String,
        modelValue: Array,
        optional: Boolean,
        hideTooltip: Boolean,
        id: String,
        name: String,
        label: String,
        initial: Array,
        helpText: String,
        placeholder: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        hideLabel: Boolean,
        fullWidth: Boolean,

        choices: Array,  // of objects with text and value
        groupChoices: Array,  //  of objects with arrays of text and value
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            error: null,
            labelClass: null,
            optionalText: gettext('Optional'),
            selectedItems: [],
            groupKeys: []
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setFilterParam(this.filterField, value)
            }
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.selectedItems)
        },
        onChange(choice) {
            this.selectItem(choice)
            this.propagateValue(choice.value)
            if (this.error) {
                this.initStyle()
                this.error = false
            }
            const event = {'name': this.name, 'values': this.selectedItems}
            this.$emit(EVENTS.CHANGE, this.selectedItems)
            this.$emit(EVENTS.SAVE, event)
        },
        onChangeGroup(choice, index) {
            const item = {value: choice.value, text: `${this.groupKeys[index]} ${choice.text}`}
            this.selectItem(item)
            this.propagateValue(choice.value)
            if (this.error) {
                this.initStyle()
                this.error = false
            }
            const event = {'name': this.name, 'values': this.selectedItems}
            this.$emit(EVENTS.CHANGE, this.selectedItems)
            this.$emit(EVENTS.SAVE, event)
        },
        initStyle() {
            this.labelClass = 'field-title'
        },
        makeInvalid(error) {
            this.error = error
            this.selectClass = 'select error'
        },
        getInputSelectClass() {
            let selectClass = 'multi-select'

            if (this.fullWidth) {
                selectClass += ' full-width'
            }

            if (this.disabled) {
                selectClass += ' disabled'
            }

            return selectClass
        },
        getContainerClass() {
            let containerClass = 'input-container multi'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        },
        selectItem(item) {
            if (this.selectedItems.some(i => item.value === i.value)) {
                this.selectedItems = this.selectedItems.filter(i => item.value !== i.value)
                return
            }
            this.selectedItems.push(item)
        },
        isItemSelected(item) {
            return this.selectedItems.some(i => item.value === i.value)
        },
        initChoices() {
            const params = pHandler.getOrRegisterParamBuilder(this.filterOn).getAllFilterParams(this.filterField)
            if (params?.length > 0) {
                this.selectedItems = this.choices.filter(choice => params.includes(choice.value.toString()))
            }
        },
        initGroupChoices() {
            this.groupChoices.forEach(group => {
                Object.keys(group).forEach(key => this.groupKeys.push(key))
                const params = pHandler.getOrRegisterParamBuilder(this.filterOn).getAllFilterParams(this.filterField)
                if (params?.length > 0) {
                    Object.values(group).forEach(choices => {
                        const checkedChoices = choices.filter(choice => params.includes(choice.value.toString()))
                        this.selectedItems = [...this.selectedItems, ...checkedChoices]
                    })
                }

            })
        },
    },
    created() {
        this.initStyle()
        pHandler.registerParamBuilder(this.filterOn)
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial?.length > 0) {
            this.selectedItems = this.initial
            this.propagateValue(this.initial)
        }
    },
    mounted() {
        if (this.groupChoices?.length > 0) {
            this.initGroupChoices()
            return
        }
        this.initChoices()
    },
    template: `
    <div :class="getContainerClass()">
         <div class="n-flex gap-m " style="justify-content: space-between">
            <label v-if="!hideLabel" :class="labelClass" :for="id">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
            <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <n-popper full-width>
            <template #trigger>
                <div :class="getInputSelectClass()">
                    <div class="multi-selected-item-pill"  v-for="item in selectedItems"   @click.stop.prevent="onChange(item)">
                        <p>[[[item.text]]]</p>
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="choices?.length > 0">
                    <div v-for="choice in choices" class="multi-select-item"  @click="onChange(choice)">
                        <input class="form-check-input" type="checkbox" :id="choice.text" :name="choice.value" :checked="isItemSelected(choice)" >
                        <label style="text-overflow: ellipsis; overflow: hidden;  white-space: nowrap;" :for="choice.value">[[[choice.text]]]</label>
                    </div>
                </template>
                 <template v-if="groupChoices?.length > 0">
                   <template v-for="choices,index in groupChoices">
                   <div class="n-flex n-col">
                        <div class="multi-select-group-item">[[[ groupKeys[index] ]]]</div>
                        <div v-for="choice in choices[groupKeys[index]]" class="multi-select-item"  @click="onChangeGroup(choice, index)">
                            <input class="form-check-input" type="checkbox" :id="choice.text" :name="choice.value" :checked="isItemSelected(choice)" >
                            <label style="text-overflow: ellipsis; overflow: hidden;  white-space: nowrap;" :for="choice.value">[[[choice.text]]]</label>
                        </div>
                    </div>
                    </template>
                </template>
            </template>
        </n-popper>

        <div v-if="error" class="invalid-field-error">
        [[[ error ]]]
        </div>
    </div>

    `,
}