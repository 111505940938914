import NModal from "./n-modal.js";
import NButton from "../buttons/n-button.js";

const EVENTS = {
    OPEN: 'open',
}


export default {
    extends: {...NModal, ...NButton},

    emits: Object.values(EVENTS),
    props: {
        id: String,
        text: {
            type: String,
        },
        color: {
            type: String,
            default: 'primary'
        },
        iconLeft: {
            type: String,
            default: null
        },
        iconRight: {
            type: String,
            default: null
        },
        small: Boolean,
        disabled: Boolean
    },
    data() {
        return {
            color_: null,

            // MODAL
            html: null,
            content: null
        };
    },
    methods: {
        onClick($event) {
            this.$emit(EVENTS.OPEN, $event)
        },
        initStyle() {
            this.color_ = this.color || " primary"
        },
        getButtonClass() {
            let buttonClass = 'n-button ' + this.color_
            if (this.small) {
                buttonClass += ' small'
            }
            return buttonClass
        },

        // MODAL
        renderContent() {
            let app = this.$root
            this.content = Vue.shallowRef({
                template: this.html,
                methods: app.$options.methods,
                data() {
                    return {
                        ...app.$data
                    }
                },
                computed: app.$options.computed,
                components: app.$options.components,
                watch: app.$options.watch,
                provide: app.$options.provide
            })
        }
    },
    created() {
        this.initStyle()
    },
    mounted() {
    },
    template: `
        <button :disabled="disabled" :class="getButtonClass()" 
            data-bs-toggle="modal"
            :data-bs-target="'#' + id"
            @click="onClick()"
            >
          <i v-if="iconLeft" :class="iconLeft" />
            [[[text]]]
          <i v-if="iconRight" :class="iconRight" />
        </button>        
        
        <div :id="id" class="n-modal fade" tabindex="-1" aria-hidden="true"> 
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="n-modal-header">
                    <h2 class="n-modal-title">Modal title</h2>
                    <div class="n-modal-close"> <!-- implement close handling -->
                        <i class="fa fa-times"></i>
                    </div>
                  </div>
                  <div class="n-modal-body">
                      <component :is="content"></component>
                  </div>
                </div>
              </div>
            </div>
        `,
};
