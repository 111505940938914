import NButtonDropdown from "../buttons/n-button-dropdown.js";
import NButton from "../buttons/n-button.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'

export default {
    inject: ["errors"],
    components: {
        'n-button-dropdown': NButtonDropdown,
        'n-button': NButton,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            return false
        },
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        optional: Boolean,

        id: String,
        name: String,
        initial: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        fullWidth: Boolean,

        choices: Array,  // of objects with text and value
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            selectedValue: null,
            error: null,
            optionalText: gettext('Optional')

        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.selectedValue = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            if (this.error) {
                this.error = false
            }
            this.$emit(EVENTS.CHANGE, value)
        },
        makeInvalid(error) {
            this.error = error
        },
        getSelectedText() {
            let choice = this.choices.find((choice) => choice.value === this.selectedValue)
            if (choice) {
                return choice.text
            }
            return ''
        }
    },
    created() {

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            this.propagateValue(this.initial)

            return
        }
        if (this.modelValue)
            this.selectedValue = this.modelValue

        if (!this.selectedValue && this.choices?.length > 0)
            this.propagateValue(this.choices[0].value)
    },
    template: `
        <n-button-dropdown :name="name" 
        style="max-width: 200px; border-width: 1px"
        anchor-right
        text-align-left
        :full-width="fullWidth"
        outlined
        color="transparent"
        small
        :disabled="disabled"
        :text="getSelectedText()"
        icon-right="fa fa-ellipsis-v"
       >
            <template v-for="(choice, index) in choices" :key="selectedValue + index">
                    <n-button @click="onChange(choice.value)"
                              small
                              outlined
                              full-width
                              :color="selectedValue !==  choice.value  ? 'transparent' : ''"
                              :text="choice.text"
                              :disabled="disabled"
                    >
                    <input style="position:absolute; inset: 0; opacity: 0" type="radio"
                           :value="choice.value"
                           :name="name">
                    </n-button>
            </template>
            <slot></slot>
        </n-button-dropdown>
  

    `,
}