export default {
    props: {
        id: String,
        icon: String
    },
    data() {
        return {
            nClass: 'tab-container '
        };
    },
    methods: {},
    computed: {},
    created() {
    },
    template: `
       <div class="dropdown">
          <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" 
                data-bs-toggle="dropdown" aria-expanded="false">
            <i v-if="icon" :class="'fas ' + icon"></i>
          </a>
        <ul class="dropdown-menu">
            <slot></slot>
        </ul>
        </div>
    `,
};