export default {
    props: {
        pdf: String,
    },
    data() {
        return {
            resizeObserver: null,
        };
    },
    methods: {
        initResize() {
            let pdf = document.getElementById(this.pdf)
            if (!pdf) {
                return
            }

            const originalWidth = pdf?.offsetWidth;
            let pdfContainer = document.getElementById("pdfContainer")
            this.resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    const newWidth = entry.contentRect.width;

                    const scale = Math.min(1, newWidth / originalWidth);

                    pdf.style.transform = `scale(${scale})`;
                    pdf.style.transformOrigin = 'top left';
                })
            })

            this.resizeObserver.observe(pdfContainer)
        },
        observeSlotContentChanges() {
            const targetNode = document.querySelector('.pdf-container')
            const config = {attributes: true, childList: true, subtree: true};

            const callback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "childList") {
                        this.initResize()
                    }
                }
            };
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        },
    },
    mounted() {
        this.observeSlotContentChanges()

    },
    template: `
    <div id="pdfContainer" class="pdf-container">
            <slot></slot>
    </div>
    `,
}
