class EVENTS {
}

EVENTS.GO_TO_TAB = 'go-to'

export default {
    props: {
        primaryStyle: Boolean,
        tabs: Array,
        hideCount: Boolean,
        small: Boolean,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            currentTab: 1
        };
    },
    methods: {
        getTabClass(tabIndex) {
            let tabClass = 'dashboard-tab'

            if (tabIndex === this.currentTab) {
                tabClass += ' current'
            }

            if (this.small) {
                tabClass += ' small'
            }

            if (this.primaryStyle) {
                tabClass += ' primary-style'

            }
            return tabClass
        },
        getTabContainerClass(tabIndex) {
            let tabContainerClass = 'dashboard-tab-container'

            if (this.primaryStyle) {
                tabContainerClass += '  primary-style'
            }

            if (this.small) {
                tabContainerClass += '  small'
            }
            return tabContainerClass
        },
        getTabCounterClass(tabIndex) {
            let tabCounterClass = 'tab-counter'

            if (tabIndex === this.currentTab) {
                tabCounterClass += ' current'
            }
            return tabCounterClass
        },
        selectTab(tabIndex) {
            pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.PAGE_CONTROLLER).setTabParam(tabIndex)
            this.currentTab = tabIndex
            this.$emit(EVENTS.GO_TO_TAB)
        }
    },
    computed: {},
    created() {
        pHandler.registerParamBuilder(NORSE_FILTERS.PAGE_CONTROLLER)
    },
    mounted() {
        if (this.tabs?.length > 0) {
            const tab = pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.PAGE_CONTROLLER).getTabParam()
            if (tab) {
                this.currentTab = tab
                return
            }
            pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.PAGE_CONTROLLER).setTabParam(1)
        }
    },
    template: `
    <div :class="getTabContainerClass()" :style="tabs?.length > 0 ? {} : {minHeight: '3px', marginTop: '.5rem'}">
        <div v-for="tab, i in tabs" :class="getTabClass(i + 1)" @click="selectTab(i + 1)">
        [[[tab.name]]]        <div v-if="tab.count > 0 && !hideCount" :class="getTabCounterClass(i + 1)" > [[[tab.count]]]</div>
        </div>
    
    </div>

    `,
};