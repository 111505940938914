// IOApplicationStates
class CASE_STATE {
}

CASE_STATE.ENTRY = '1'
CASE_STATE.RENEWAL = '2'
CASE_STATE.SPOT_CHECK = '3'

export default {
    components: {},
    props: {
        small: Boolean,
        status: String,
    },
    data() {
        return {
            renewalText: gettext('Renewal'),
            spotcheckText: gettext('Spot-check'),
            entryText: gettext('Entry'),
        }
    },
    methods: {
        getStatusClass() {
            let statusClass = 'status-label '
            if (this.status === CASE_STATE.ENTRY) {
                statusClass += ' pending'
            }
            if (this.status === CASE_STATE.RENEWAL) {
                statusClass += ' created'
            }
            if (this.status === CASE_STATE.SPOT_CHECK) {
                statusClass += ' confirmed'
            }
            if (this.small) {
                statusClass += ' small'
            }
            return statusClass
        },
        getStatusText() {
            if (this.status === CASE_STATE.ENTRY) {
                return this.entryText
            }
            if (this.status === CASE_STATE.RENEWAL) {
                return this.renewalText
            }
            if (this.status === CASE_STATE.SPOT_CHECK) {
                return this.spotcheckText
            }
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
       <div style="min-width: 100px; width: max-content; padding-left: .5rem; padding-right: .5rem;" :class="getStatusClass()" :data-string="getStatusText()"></div>
        `,
}
