import NTextInput from "./n-text-input.js";
import NDateInput from "./n-date-input.js"
import NInlineEdit from "./n-inline-edit.js"
import NCheckbox from "./n-checkbox.js"
import NSelect from "./n-select.js"
import NHiddenInput from "./n-hidden-input.js";
import BldImageInput from "./bld-image-input.js";
import BldFileInput from "./bld-file-input.js";
import NTextareaInput from "./n-textarea-input.js";
import NFormError from "./n-form-error.js";
import NInlineEditDate from "./n-inline-edit-date.js";
import NInlineEditSelect from "./n-inline-edit-select.js";
import NTimeInput from "./n-time-input.js";
import NInlineEditTime from "./n-inline-edit-time.js";
import BldSelect from "./bld-select.js";
import NSearchInput from "./n-search-input.js";
import NFileInput from "./n-file-input.js";
import NInputHistory from "./n-input-history.js";
import NAutoSaveTextInput from "./n-auto-save-text-input.js";
import NAutoSaveTextarea from "./n-auto-save-textarea.js";
import NRadio from "./n-radio.js";
import NMultiSelect from "./n-multi-select.js";
import NToggle from "./n-toggle.js";
import NDatetimeInput from "./n-datetime-input.js";
import NImageInput from "./n-image-input.js";
import NInlineTextarea from "./n-inline-textarea.js";

export default {
    "n-text-input": NTextInput,
    "n-inline-edit": NInlineEdit,
    "bld-image-input": BldImageInput,
    "bld-file-input": BldFileInput,
    "bld-select": BldSelect,
    "n-date-input": NDateInput,
    "n-datetime-input": NDatetimeInput,
    "n-time-input": NTimeInput,
    "n-inline-edit-time": NInlineEditTime,
    "n-checkbox": NCheckbox,
    "n-select": NSelect,
    'n-hidden-input': NHiddenInput,
    "n-textarea-input": NTextareaInput,
    "n-form-error": NFormError,
    "n-inline-edit-date": NInlineEditDate,
    "n-inline-edit-select": NInlineEditSelect,
    "n-inline-textarea": NInlineTextarea,
    "n-search-input": NSearchInput,
    "n-file-input": NFileInput,
    "n-input-history": NInputHistory,
    "n-auto-save-text-input": NAutoSaveTextInput,
    "n-auto-save-textarea": NAutoSaveTextarea,
    "n-radio": NRadio,
    "n-multi-select": NMultiSelect,
    "n-toggle": NToggle,
    "n-image-input": NImageInput,
};
