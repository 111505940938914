export default {
    props: {
        postalCode: Boolean,
        postalCity: Boolean,
        fieldName: String,
    },
    data() {
        return {
            autocomplete: 'address-line1',
            fields: [],
            inputs: []
        };
    },
    methods: {
        initAutofill() {
            this.initFields()
            this.setupRelevantFields()
            this.setupAutofill()
        },
        initFields() {
            if (this.postalCode) {
                this.fields.push(MAPBOX_AUTOFILL_FIELDS.postalCode)
            }
            if (this.postalCity) {
                this.fields.push(MAPBOX_AUTOFILL_FIELDS.postalCity)
            }
        },
        setupRelevantFields() {
            if (this.fields.length <= 0) {
                return
            }

            this.inputs = Array.from(document.querySelectorAll('input')).filter((inputEl) => {
                return this.fields.includes(inputEl.name)
            })
        },
        setupAutofill() {
            const autofillElement = new mapboxsearch.MapboxAddressAutofill()
            autofillElement.options = {
                country: MAPBOX_GEOLOCATION_COUNTRIES,
            }
            autofillElement.popoverOptions = {
                flip: true,
            }
            autofillElement.browserAutofillEnabled = false;
            autofillElement.accessToken = this.$root.MAPBOX_API
            autofillElement.addEventListener('retrieve', (event) => {
                const {features} = event.detail;
                if (features?.length > 0) {
                    const address = features[0].properties;
                    this.populateRelevantFields(address);
                }
            });
            //get input element within this component
            const inputEl = document.querySelector(`input[name="${this.fieldName}"]`)
            const parent = inputEl.parentNode;
            const nextSibling = inputEl.nextSibling;
            if (!inputEl) {
                console.error("Could not find a an input HTML element with that name")
                return
            }
            // mapboxsearch requires a form, create one
            const form = document.createElement('form');
            // prevent form from submitting oif user selects location using enter key
            form.addEventListener('keydown', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            });

            if (!inputEl) {
                console.error('Input element not found!');
                return
            }

            inputEl.autocomplete = this.autocomplete;

            // build HTML:
            autofillElement.appendChild(inputEl);
            form.appendChild(autofillElement);
            form.style.width = '100%'

            const dialog = document.querySelector('dialog');
            if (dialog) {
                dialog.style.overflow = "visible"
                setTimeout(() => {
                    const element = document.querySelectorAll('[class*="MapboxSearch"]')[0]
                    if (element) {
                        parent.appendChild(element);
                    }
                }, 0)
            }

            if (nextSibling) {
                parent.insertBefore(form, nextSibling);
                return
            }
            parent.appendChild(form);

        },
        populateRelevantFields(address) {

            if (!address) {
                console.error("missing Mapbox address object")
                return
            }

            this.inputs.forEach((input) => {
                switch (input.name) {
                    case MAPBOX_AUTOFILL_FIELDS.postalCode:
                        input.value = address?.postcode || '';
                        break;
                    case MAPBOX_AUTOFILL_FIELDS.postalCity:
                        input.value = address?.address_level2 || '';
                        break;
                }
                this.dispatchEvents(input)
            });
            this.closeAutofill();
        },
        dispatchEvents(input) {
            input.dispatchEvent(new Event('input', {bubbles: true}));
            input.dispatchEvent(new Event('change', {bubbles: true}));
            input.dispatchEvent(new Event('blur', {bubbles: true}));
        },
        closeAutofill() {
            const inputEl = document.querySelector(`input[name="${this.fieldName}"]`)

            if (inputEl) {
                inputEl.blur();
            }
        },
    },
    mounted() {
        if (!this.fieldName) {
            console.error("Must provide field to target (Input name attribute)")
            return
        }
        this.initAutofill()
    },
    template: `
        <slot></slot>
    `,
};