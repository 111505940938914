import NButton from "./n-button.js";
import NFlex from "../styling/n-flex.js";

const SCREEN_OFFSET = 30 // px

export default {
    components: {
        'n-button': NButton,
        'n-flex': NFlex
    },
    props: {
        anchorRight: Boolean,
        textAlignLeft: Boolean,
        text: {
            type: String,
        },
        color: {
            type: String,
        },
        size: {
            type: String,
            default: ""
        },
        iconLeft: {
            type: String,
            default: null
        },
        iconRight: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        growUp: Boolean,
    },
    data() {
        return {
            isOpen: false,
            _growUp: false,
        };
    },
    methods: {
        toggleIsOpen() {
            this.$refs.box.classList.add('open')
            const boxRect = this.$refs.box.getBoundingClientRect()
            //if the dropdown list height is about to overflow the screen height
            // we force a grow up ^
            this.$refs.box.classList.remove('open')
            this.isOpen = !this.isOpen

            if ((window.innerHeight - SCREEN_OFFSET) <= (boxRect.y + boxRect.height)) {
                this._growUp = true
                return
            }

            this._growUp = false
        },
        getContainerClass() {
            let containerClass = 'n-button-dropdown-container ' + this.size
            if (this.fullWidth) {
                containerClass += ' full-width'
            }
            if (this.textAlignLeft) {
                containerClass += ' align-left'
            }

            return containerClass
        },
        getButtonContainerClass() {
            let btnContainerClass = 'n-button-dropdown-button-container left'

            if (this.anchorRight) {
                btnContainerClass = 'n-button-dropdown-button-container right'
            }
            if (this.textAlignLeft) {
                btnContainerClass += ' align-left'
            }


            if (this.disabled) {
                btnContainerClass += ' disabled'
                return btnContainerClass
            }
            if (this.isOpen) {
                btnContainerClass += ' open'
            }

            if (this.growUp || this._growUp) {
                btnContainerClass += ' grow-up'
            }
            return btnContainerClass
        },
        onClickAway() {
            if (this.isOpen) {
                this.toggleIsOpen()
            }
        }
    },
    created() {
    },
    template: `
        <span :class="getContainerClass()" @click="toggleIsOpen()" v-click-away="onClickAway">
            <n-button 
            :outlined="outlined" 
            :size="size" 
            :disabled="disabled" 
            :icon-left="iconLeft" 
            :icon-right="iconRight" 
            :text="text" 
            :color="color"
            :full-width="fullWidth">
            </n-button>
            <div ref="box" :class="getButtonContainerClass()" @click.stop="">
                <slot></slot>
            </div>        
        </span>
        `,
};
