import PlanningItemCluster from "./planning-item-cluster.js";
import PlanningItem from "./planning-item.js";
import InspectionPlanningCalendar from "./inspection-planning-calendar.js";
import InspectionPlanningWeek from "./inspection-planning-week.js";
import InspectionPlanningWeekdays from "./inspection-planning-weekdays.js";


export default {
    "planning-item-cluster": PlanningItemCluster,
    "planning-item": PlanningItem,
    "inspection-planning-calendar": InspectionPlanningCalendar,
    "inspection-planning-week": InspectionPlanningWeek,
    "inspection-planning-weekdays": InspectionPlanningWeekdays
};
