export default {
    inject: ['current_device', 'popper'],
    props: {
        helpText: String,
        label: String,
        noPersist: Boolean,
    },
    data() {
        return {
            instance: null,
        };
    },
    methods: {
        show() {
            const tooltip = this.$refs.tooltip
            tooltip.setAttribute('data-show', '');

            this.instance.update();
        },
        hide() {
            const tooltip = this.$refs.tooltip
            if (tooltip) {
                tooltip.removeAttribute('data-show');
            }
        },
        onClick(e) {
            e.stopPropagation()
            if (this.noPersist) {
                return
            }
            const tooltip = this.$refs.tooltip
            tooltip.setAttribute('data-show', '');

            document.addEventListener('click', this.closeToolTip, true);
            this.$refs.parent.removeEventListener('mouseleave', this.hide, false);

            this.instance.update();
        },
        closeToolTip(event) {
            const tooltip = this.$refs.tooltip;
            if (tooltip && !tooltip.contains(event.target)) {
                tooltip.removeAttribute('data-show');
                this.$refs.parent.addEventListener('mouseleave', this.hide, false);
                document.removeEventListener('click', this.closeToolTip, true);
            }
        },
        initTooltip() {
            if (!this.$refs.parent) {
                return
            }
            const popcorn = this.$refs.parent;
            const tooltip = this.$refs.tooltip
            this.instance = this.popper.createPopper(popcorn, tooltip, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });

            const mouseShowEvents = ['mouseenter', 'focus'];
            const mouseHideEvents = ['mouseleave', 'blur'];

            mouseShowEvents.forEach((event) => {
                this.$refs.parent.addEventListener(event, this.show);
            });

            mouseHideEvents.forEach((event) => {
                this.$refs.parent.addEventListener(event, this.hide);
            });
        }
    },
    mounted() {
        this.initTooltip()
    },
    template: `
    <div v-if="helpText"  @click="onClick" ref="parent" class="input-tooltip-container" >
        <slot></slot>
            <div ref="tooltip" class="n-tooltip"  >
                <strong><i class="fa-sharp fa-regular fa-circle-info" style="margin-right: .25rem;"></i>[[[ label ]]]</strong>
                [[[helpText]]]
            </div>
    </div>
    `,
}
