import NTooltip from "../n-tooltip.js";
import NIconButton from "../buttons/n-icon-button.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'
EVENTS.KEY_ENTER = 'enter'

export default {
    emits: Object.values(EVENTS),
    components: {
        "n-tooltip": NTooltip,
        "n-icon-button": NIconButton,
    },
    inject: ["errors"],
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    props: {
        filterOn: String,
        uppercase: Boolean,
        modelValue: null,
        optional: Boolean,
        hideTooltip: Boolean,
        hideLabel: Boolean,
        id: String,
        name: String,
        label: String,
        initial: String,
        type: String,
        inputmode: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        fullWidth: Boolean,
        placeholder: String,
    },
    data() {
        return {
            value: null,
            error: null,
            labelClass: null,
            optionalText: gettext('Optional')
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = this.uppercase ? value.toUpperCase() : value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.CHANGE, value)
        },
        onEnter(value) {
            this.$emit(EVENTS.KEY_ENTER, value)
        },
        onSearch() {
            pHandler.getOrRegisterParamBuilder(this.filterOn).setQueryParam(this.value)
            this.$emit(EVENTS.KEY_ENTER, this.value)
        },
        onClear() {
            this.value = ''
            this.onSearch()
        },
        initStyle() {
            this.labelClass = 'field-title'
        },
        makeInvalid(error) {
            this.error = error
        },
        getInputTextClass() {
            let textClass = 'textfield'

            if (this.disabled) {
                textClass += ' disabled'
            }

            if (this.error) {
                textClass += ' error'
            }
            if (this.filterOn) {
                textClass += ' search'
            }

            return textClass
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            if (this.filterOn) {
                containerClass += ' search'
            }

            return containerClass
        }
    },
    created() {
        this.initStyle()

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            this.propagateValue(this.initial)
            return
        }
        if (this.filterOn) {
            pHandler.registerParamBuilder(this.filterOn)
            this.value = pHandler.getOrRegisterParamBuilder(this.filterOn).getQueryParam()
            return
        }
        if (this.modelValue)
            this.value = this.modelValue


    },
    template: `
    <div :class="getContainerClass()">
        <div class="n-flex gap-m " style="justify-content: space-between">
            <label v-if="!hideLabel" :class="labelClass" :for="id">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
           <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        
        <div v-if="filterOn" class="n-flex align-center text-search-container">
            <input :inputmode="inputmode" :id="id" :name="name" :type="type" :value="value" :class="getInputTextClass()" :placeholder="placeholder"
                :disabled="disabled"
                 @input="propagateValue($event.target.value)"
                 @change="onChange($event.target.value)"
                 @click.stop=""
                 @keyup.enter="onSearch()"/>
                 <n-icon-button v-if="value" class="remove-input" color="transparent"  @click="onClear()" :disabled="disabled"  small outlined icon-class="fa-sharp fa-solid fa-times"></n-icon-button>
                 <n-icon-button @click="onSearch()" :disabled="disabled" search small outlined icon-class="fa-sharp fa-solid fa-magnifying-glass"></n-icon-button>
        </div>
        <input v-if="!filterOn" :inputmode="inputmode" :id="id" :name="name" :type="type" :value="value" :class="getInputTextClass()" :placeholder="placeholder"
            :disabled="disabled"
             @input="propagateValue($event.target.value)"
             @change="onChange($event.target.value)"
             @click.stop=""
             @keyup.enter="onEnter($event.target.value)"/>
        <div v-if="error" class="invalid-field-error">
        [[[ error ]]]
        </div>
    </div>

    `,
}