import NFlex from "../styling/n-flex.js";

export default {
    components: {
        "n-flex": NFlex,
    },
    props: {
        id: {type: String, default: 'n-tabs'}
    },
    data() {
        return {};
    },
    methods: {
    },
    computed: {},
    mounted() {
    },
    template: `
        <nav class="page-tabs">
            <div class="nav nav-tabs" :id="id" role="tablist" style="border: 0; flex-wrap: nowrap">
                <slot></slot>
            </div>
        </nav>
    `,
};