export default {
    props: {
        color: {
            type: String,
            default: 'primary',
        },
        small: Boolean,
        tiny: Boolean,
        iconClass: String,
        disabled: Boolean,
        outlined: Boolean,
        search: Boolean,
    },
    data() {
        return {
            color_: null
        };
    },
    methods: {
        initStyle() {
            this.color_ = this.color
        },
        getButtonClass() {
            let buttonClass = "n-button icon-button " + this.color_

            if (this.search) {
                buttonClass += ' outlined small search'
            }

            if (this.outlined) {
                buttonClass += ' outlined'
            }

            if (!this.outlined) {
                buttonClass += ' filled'
            }

            if (this.small) {
                buttonClass += ' small'
            }

            if (this.tiny) {
                buttonClass += ' tiny'
            }

            return buttonClass
        },
    },
    created() {
        this.initStyle()
    },
    template: `
        <button :disabled="disabled" :class="getButtonClass()" >
          <i :class="iconClass" />
          <slot></slot>
        </button>
        `,
};
