class EVENTS {
}

EVENTS.CLOSE_ME = 'on-close'
EVENTS.SELECT_HISTORY_ITEM = 'on-select-item'

export default {
    props: {
        historyItems: Array,
        showHistory: Boolean
    },
    data() {
        return {
            showSave: false,
            mock_history: [
                {author: "Tim Mellander", role: "IO", date_time: '23-02-2020 23:26', value: 'Profit'},
                {author: "Johan Håkansson", role: "CAB", date_time: '23-02-2020 23:26', value: 'Profsdt'},
            ]
        }
    },
    emits: Object.values(EVENTS),
    methods: {
        closeHistory() {
            this.$emit(EVENTS.CLOSE_ME,)
        },
        selectItem(value) {
            this.$emit(EVENTS.SELECT_HISTORY_ITEM, value)
        }
    },
    created() {

    },
    mounted() {
    },

    template: `
        <div v-if="showHistory" v-click-away="closeHistory" class="input-history-container gap-m">
                <div class="n-flex n-col value" v-for="item in historyItems ">
                    <a class="n-flex gap-xs align-center inline-undo" @click="selectItem(item)">
<!--                        <h3>[[[item.value]]]</h3>    -->
                        <h3>[[[item]]]</h3>    
                    </a>
<!--                    <div class="n-flex gap-s meta-info">-->
<!--                        <p :title="item.author" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">[[[item.author]]]</p>-->
<!--                        <p >[[[item.role]]]</p>-->
<!--                        <p :title="item.date_time"  style="margin-left: auto; min-width: max-content">[[[item.date_time]]]</p>-->
<!--                    </div>-->
                </div>
        </div>
    `,
}