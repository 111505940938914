import NTooltip from "../n-tooltip.js";

const EVENTS = {
    CHANGE: 'onChange',
}

export default {
    inject: ["errors"],
    components: {
        'n-tooltip': NTooltip
    },
    watch: {
        errors(newValues, oldValue) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            return false
        },
        initialError(newError) {
            if (newError) {
                this.makeInvalid(newError)
            }
        },
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: null,
        initial: String,
        initialError: String,
        disabled: Boolean,
        hideLabel: Boolean,
        isFocused: Boolean,
        id: String,
        name: String,
        label: String,
        fullWidth: Boolean,
        horizontal: Boolean,
        choices: Array,
        helpText: String,
        hideTooltip: Boolean,

    },
    data() {
        return {
            value: '',
            choices_: [],
            error: null,
        }
    },
    methods: {
        onChange(target, choice) {
            this.value = choice['value']
            const event = {'name': this.name, 'value': this.value}
            this.$emit(EVENTS.CHANGE, event)
        },
        getInputClass() {
            let textClass = 'textfield inline'

            if (this.disabled) {
                textClass += ' disabled'
            }

            return textClass
        },
        makeInvalid(error) {
            this.error = error
        },
        getContainerClass() {
            let containerClass = 'n-radio-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            if (this.horizontal) {
                containerClass += ' horizontal'
            }

            return containerClass
        },
        getInputTextClass() {
            let textClass = 'textfield'

            if (this.disabled) {
                textClass += ' disabled'
            }

            return textClass
        },
        getInputProperties() {
            return {
                name: this.name,
                disabled: this.disabled,
                type: 'radio',
            }
        },
    },
    created() {
        if (this.choices) {
            this.choices_ = this.choices
        }

        if (this.initial) {
            this.value = this.initial
            return
        }

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }

        if (this.modelValue) {
            this.value = this.modelValue
        }
    },
    mounted() {
        if (this.isFocused) {
            this.$refs.inputRef.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        }
    },
    template: `
    <div :class="getContainerClass()" >
        <div class="n-flex gap-m " style="justify-content: space-between">
            <label v-if="!hideLabel" class="field-title" :id="id" >[[[ label ]]]</label>
            <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <div class="n-radio-label-container" :class="horizontal ? ' horizontal': ''">
        <label v-for="choice in choices_" 
                ref="inputRef"
                class="n-radio-button"  
                :id="choice.label"
                :autofocus="isFocused"
                :key="choice.value" 
                :for="choice.text">
            <input  
                    :id="choice.text"
                    v-bind="getInputProperties()"
                    :value="choice.value === value ? choice.value : null"
                    :checked="choice.value === value"
                    :name="name"
                    @change="onChange($event.target, choice)"
                    />
               <span>[[[ choice.text ]]]</span> 
        </label>
        </div>
        <div v-if="error"  class="invalid-field-error inline">
        [[[ error ]]]
        </div>
    </div>
    `,
}
