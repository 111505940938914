import NModal from "./n-modal.js"
import NModalButton from "./n-modal-button.js";
import NDropdown from "./n-dropdown.js";
import NSheetComponent from "./n-sheet-component.js";
import NModalStepper from "./n-modal-stepper.js";
import NPopper from "./n-popper.js";

export default {
    "n-modal": NModal,
    "n-modal-button": NModalButton,
    "n-modal-stepper": NModalStepper,
    "n-dropdown": NDropdown,
    "n-sheet-component": NSheetComponent,
    "n-popper": NPopper
};
