// Vue3DatePicker docs
//
// CURRENTLY USED PROPS
// placeholder ->> https://vue3datepicker.com/api/props/#placeholder
// modelType --> https://vue3datepicker.com/api/props/#modeltype
// cancelText --> https://vue3datepicker.com/api/props/#canceltext
// selectText --> https://vue3datepicker.com/api/props/#selecttext
// textInputOptions --> https://vue3datepicker.com/api/props/#textinputoptions
// disabled --> https://vue3datepicker.com/api/props/#disabled
// inputClassName --> https://vue3datepicker.com/api/props/#inputclassname
// state --> https://vue3datepicker.com/api/props/#state
// autoApply --> https://vue3datepicker.com/api/props/#autoapply
// closeOnAutoApply --> https://vue3datepicker.com/api/props/#closeonautoapply
// textInput --> https://vue3datepicker.com/api/props/#textinput
// locale --> https://vue3datepicker.com/api/props/#locale
// enableTimePicker --> https://vue3datepicker.com/api/props/#enabletimepicker
// @update:modelValue --> https://vue3datepicker.com/api/events/#update-modelvalue


import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    CHANGE: 'change',
}

export default {
    inject: ['errors', 'current_device'],
    components: {
        'n-date-picker': VueDatePicker,
        'n-tooltip': NTooltip
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.inputClass = 'datepicker'
            return false
        },
        // modelValue(newValue) {
        //     this.value = newValue
        // }
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        hideTooltip: Boolean,
        filterField: String,
        filterOn: String,
        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        placeholder: String,
        proposedDate: String,
        proposedTime: String,
        fullWidth: Boolean,
        optional: Boolean,
        inline: Boolean,
        //VueDatepickerProps

        autoApply:
            {
                type: Boolean,
                default: true
            },
        allowTextInput:
            {
                type: Boolean,
                default: true
            },
    },
    data() {
        return {
            dateValue: null,
            timeValue: null,
            isValid: null,
            error: null,

            TIME_PICKER_HEIGHT: 120, // in px

            //Vue3DatePicker data
            inputClass: 'datepicker',
            cancelText: gettext('Cancel'),
            selectText: gettext('Select'),
            textInputOptions: {
                enterSubmit: true,
                tabSubmit: true,
                openMenu: true,
                format: (val) => this.onInput(val),
            },
            validationState: null, // set to false on error, reset to null when handled
        }
    },
    methods: {
        propagateValue() {

            this.error = null
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.getDatetime())
        },
        clearTimeValue() {
            this.timeValue = null
        },
        clearDateValue() {
            this.dateValue = null
        },
        getDatetime() {
            let dateTime = moment(this.dateValue || new Date(), Constants.DATE_FORMAT);


            if (!dateTime.isValid()) {
                throw new Error("Invalid date format");
            }
            if (!this.timeValue) {
                return dateTime.format('YYYY-MM-DD HH:mm')
            }

            const [hours, minutes] = this.timeValue.split(':').map(Number);
            if (isNaN(hours) || isNaN(minutes)) {
                throw new Error("Invalid time format");
            }

            dateTime.set({hour: hours, minute: minutes});

            return dateTime.format('YYYY-MM-DD HH:mm');
        },
        onDateChange(value) {
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setDateParam(this.filterField, value)
            }
            this.$nextTick(() => {
                this.propagateValue()
                this.$emit(EVENTS.CHANGE, this.getDatetime())
                this.resetError()
            })
        },
        onTimeChange(value) {
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setDateParam(this.filterField, value)
            }
            this.$nextTick(() => {
                if (!this.dateValue && value) {
                    this.dateValue = moment(new Date()).format(Constants.DATE_FORMAT);
                }
                this.propagateValue()
                this.$emit(EVENTS.CHANGE, this.getDatetime())
                this.resetError()
            })
        },
        onInput(value) {

            if (this.isValidDateString(value)) {
                this.resetError()
                return moment(value).toDate()
            }

            const time = this.getTimeObjectFromString(value)
            const dateTime = new Date()
            dateTime.setHours(time.hours)
            dateTime.setMinutes(time.minutes)
            if (this.isValidTimeString(value)) {
                this.resetError()
            }

            this.makeInvalid("Invalid date")
            return dateTime
        },
        resetError() {
            this.error = ''
            this.inputClass = 'datepicker'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        },
        isValidTimeString(time) {
            const isStrict = true
            return moment(time, [Constants.TIME_FORMAT, 'HHmm'], isStrict).isValid()
        },
        isValidDateString(date) {
            const isStrict = true
            return moment(date, [Constants.DATE_FORMAT, 'YYYYMMDD'], isStrict).isValid()
        },
        getTimeObjectFromString(string) {
            const timeString = moment(string, [Constants.TIME_FORMAT, 'HHmm']).format(Constants.TIME_FORMAT)
            const timeSplit = timeString.split(':')
            const hour = timeSplit[0] //HH
            const minutes = timeSplit[1] // mm
            return {hours: hour, minutes: minutes, seconds: 0}
        },
        getContainerClass() {
            let containerClass = 'input-container datetime'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        },
        getConfig() {
            return {
                'modeHeight': this.TIME_PICKER_HEIGHT,
                'closeOnAutoApply': true
            }

        },
    },
    created() {
        if (this.filterOn) {
            pHandler.registerParamBuilder(this.filterOn)
        }

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            this.dateValue = moment(new Date(this.initial)).format(Constants.DATE_FORMAT);
            this.timeValue = this.getTimeObjectFromString(this.initial)
            this.propagateValue()
            return
        }
        if (this.modelValue)
            this.getDatetime()
    },
    mounted() {
        if (this.filterOn) {
            const params = pHandler.getOrRegisterParamBuilder(this.filterOn)
            this.value = params.getDateParam()
        }
    },
    template: `
    <div :class="getContainerClass()" :key="modelValue">
        <div class="n-flex gap-m align-center" style="justify-content: space-between">
            <label :for="id" class="field-title">[[[ label ]]]</label>
            <n-tooltip v-if="!hideTooltip"  :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <div class="n-flex gap-s align-center">
             <n-date-picker  
                v-model="dateValue" 
                :uid="id" 
                :name="name" 
                format="yyyy-MM-dd" 
                :placeholder="placeholder || 'yyyy-mm-dd'"
                modelType="format"
                :cancelText="cancelText"
                :selectText="selectText"
                :textInputOptions="textInputOptions"
                :disabled="disabled"
                :inputClassName="inputClass"
                :state="validationState"
                :autoApply="autoApply"
                :textInput="current_device !== 'MOBILE'" 
                :closeOnAutoApply="true"
                :inline="inline"
                locale="sv" 
                @cleared="clearDateValue"
                :enableTimePicker="false" 
                @update:modelValue="onDateChange(dateValue)" 
                >
                    <template #input-icon>
                        <i class="fa fa-calendar" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                    </template>
                </n-date-picker>
            <div class="range-divider"></div>
            <n-date-picker 
                style="width: 150px"
                v-model="timeValue" 
                :uid="id" 
                :name="name" 
                timePicker
                :placeholder="placeholder || '--:--'"
                modelType="format"
                :cancelText="cancelText"
                :selectText="selectText"
                :textInputOptions="textInputOptions"
                :disabled="disabled"
                :inputClassName="inputClass"
                :state="validationState"
                :autoApply="autoApply"
                :textInput="current_device !== 'MOBILE'" 
                :inline="inline"
                :config="getConfig()"
                keep-action-row
                locale="sv" 
                @cleared="clearTimeValue"
                @update:modelValue="onTimeChange(timeValue)" 
                >
                <template #input-icon>
                    <i class="fa fa-clock" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>           
        </div>
        <div v-if="error" class="invalid-field-error" >
        [[[ error ]]]
        </div>
    </div>
    `,
}