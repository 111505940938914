import NPopper from "../popups/n-popper.js";
import NFlex from "../styling/n-flex.js";

export default {
    components: {
        'n-popper': NPopper,
        'n-flex': NFlex,
    },
    props: {
        name: String,
        date: String,
        state: Number,
    },
    data() {
        return {
            STATES: WORK_ORDER_STATES,
            el: null
        };
    },
    methods: {
        getInspectionStateClass() {
            let planningClass = 'planning-item '
            switch (this.state) {
                case this.STATES.CREATED:
                    break;
                case this.STATES.DONE:
                    planningClass += 'approved';
                    break;
                case this.STATES.CANCELLED:
                    planningClass += ' rejected';
                    break;
                case this.STATES.CONFIRMED:
                    planningClass += 'confirmed';
                    break;
                case this.STATES.ONGOING:
                    planningClass += 'ongoing';
                    break;
                default:
                    break;
            }
            return planningClass
        },
    },
    mounted() {
    },
    template: `
        <div ref="item" :class="getInspectionStateClass()">[[[name]]]</div>
    `,
};