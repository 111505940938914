export default {
    inject: ['popper'],
    props: {
        fullWidth: Boolean,
    },
    data() {
        return {
            instance: null,
        };
    },
    methods: {
        show() {
            this.$refs.tooltip.setAttribute('data-show', '');
            this.instance.update();
        },
        hide() {
            const tooltip = this.$refs.tooltip
            if (tooltip) {
                tooltip.removeAttribute('data-show');
            }
        },
        onClick(e) {
            e.stopPropagation()
            this.$refs.tooltip.setAttribute('data-show', '');

            document.addEventListener('click', this.closeToolTip, true);

            this.instance.update();
        },
        closeToolTip(event) {
            const tooltip = this.$refs.tooltip;
            if (tooltip && !tooltip.contains(event.target)) {
                tooltip.removeAttribute('data-show');
                document.removeEventListener('click', this.closeToolTip, true);
            }
        },
        initTooltip() {
            if (!this.$refs.parent) {
                return
            }

            const parent = this.$refs.parent;
            const tooltip = this.$refs.tooltip
            this.instance = this.popper.createPopper(parent, tooltip, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });

            const mouseShowEvents = ['focus'];
            const mouseHideEvents = ['blur'];

            mouseShowEvents.forEach((event) => {
                this.$refs.parent.addEventListener(event, this.show);
            });

            mouseHideEvents.forEach((event) => {
                this.$refs.parent.addEventListener(event, this.hide);
            });
        }
    },
    mounted() {
        this.initTooltip()
    },
    template: `
    <div  @click="onClick" ref="parent" class="input-tooltip-container" >
            <slot name="trigger"></slot>
            
        <div  ref="tooltip" class="n-tooltip" :class="fullWidth ? 'full-width' : ''" >
            <slot name="content"></slot>
        </div>
    </div>
    `,
}
