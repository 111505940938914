class EVENTS {
}

EVENTS.UPDATE_COLUMNS = 'update'

class RESULTS {
}

RESULTS.APPROVAL = 1
RESULTS.REJECTION = 2
RESULTS.IGNORED = 2

export default {
    props: {
        item: Number,
        type: String,
        progress: String,
        result: Number,
        rejections: Number,
        approved: Boolean,
        rejected: Boolean,
        notApplicable: Boolean,
        awaiting: Boolean,
    },
    emits: Object.values(EVENTS),
    inject: [
        'selectItem',
        'columnId',
    ],
    data() {
        return {
            RESULTS: RESULTS,
            approveText: gettext('Approved'),
            rejectText: gettext('Rejected')
        };
    },
    methods: {
        onClick(event) {
            this.setSelectedItem()
            this.selectItem(event)
        },
        setSelectedItem() {
            const handler = pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CHECKLIST)
            handler.setChecklistParam(this.columnId, `${this.type}_${this.item}`)
        },
        getResultClass() {
            if (this.approved) {
                return ' approved'
            }
            if (this.rejected) {
                return ' rejected'
            }
            if (this.notApplicable) {
                return ' ignored'
            }
            if (this.awaiting) {
                return ' awaiting'
            }
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
        <div ref="item" class="checklist-item" :class="getResultClass()" @click="onClick" :data-item="item" :data-type="type">
            <div class="checklist-item-selected-marker"></div>
            <div class="checklist-item-content">
                <span v-if="notApplicable" style="text-decoration:line-through;"><slot></slot></span>
                <slot v-if="!notApplicable"></slot>
                
                <template v-if="result">
                    <div v-if="result === RESULTS.APPROVAL" class="status-label  approved" style="font-size: 11px; height: 20px;padding: 2px 8px;margin-right: 1rem">[[[approveText]]]</div>
                    <div v-if="result === RESULTS.REJECTION" class="status-label  rejected" style="font-size: 11px; height: 20px;padding: 2px 8px;margin-right: 1rem" >[[[rejectText]]]</div>
<!--    WAITING FOR IO  <div v-if="result === RESULTS.REJECTION" class="status-label  rejected" style="font-size: 11px; height: 20px;padding: 2px 8px;margin-right: 1rem" >[[[rejectText]]]</div>-->
<!--    WAITING FOR CAB  <div v-if="result === RESULTS.REJECTION" class="status-label  rejected" style="font-size: 11px; height: 20px;padding: 2px 8px;margin-right: 1rem" >[[[rejectText]]]</div>-->
                </template>
                <template v-if="!result">
                    <div v-if="progress" class="checklist-progress-label"> [[[progress]]]</div>
                    <div v-if="rejections" class="checklist-progress-label rejected" > [[[rejections]]]</div>
                </template>
                <div class="checklist-item-chevron fas fa-chevron-right"></div>
            </div>
            <div class="checklist-item-handled-marker" :class="getResultClass()"></div>
        </div>
    `,
};