import NFlex from "../styling/n-flex.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'  // Use to call events after load
EVENTS.SAVE = 'auto-save'  // Use to call events after load

export default {
    inject: ["errors"],
    components: {
        "n-flex": NFlex
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            return false
        },
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            value: null,
            error: null
        }
    },
    props: {
        filterOn: String,
        filterField: String,
        modelValue: Boolean,
        fullWidth: Boolean,
        id: String,
        name: String,
        label: String,
        initial: Boolean,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        isChecked: {
            type: Boolean,
            default: undefined,
        },

        action: {
            type: Function,
            default: () => {
            }
        }
    },
    methods: {
        propagateValue(value) {
            if (typeof value === 'string')
                value = value === 'true'
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setFilterParam(this.filterField, value)
            }
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.CHANGE, value)
            this.$emit(EVENTS.SAVE, {name: this.name, value: this.value})
        },

        makeInvalid(error) {
            this.error = error
        },
        getValue() {
            if (this.isChecked !== undefined) {
                return this.isChecked
            }
            return this.value
        },
        getInputClass() {
            let checkboxClass = 'form-check-input'

            if (this.disabled) {
                checkboxClass += ' disabled'
            }

            if (this.error) {
                checkboxClass += ' border-danger'
            }

            return checkboxClass
        },
        getContainerClass() {
            let containerClass = 'n-checkbox-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            if (this.disabled) {
                containerClass += ' disabled'
            }

            return containerClass
        },
    },
    created() {

        if (this.isChecked !== undefined) {
            this.propagateValue(this.isChecked)
            return
        }

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }

        if (this.initial) {
            // initial is a boolean
            this.propagateValue(this.initial)
            return
        }
        if (this.filterOn) {
            const params = pHandler.getOrRegisterParamBuilder(this.filterOn).getAllFilterParams(this.filterField)
            if (params?.length > 0) {
                this.value = true
            }
            return;
        }
        if (this.modelValue !== undefined) {
            console.log(this.modelValue)
            this.propagateValue(this.modelValue)
        }

    },
    template: `
    <div :class="getContainerClass()" @click.stop="">
        <input :disabled="disabled" :id="id || label" :name="name" :class="getInputClass()" type="checkbox" :value="getValue()" :checked="getValue()"
             @input="onChange($event.target.checked)"
        />
        <label v-if="label" class="field-title" :for="id || label">
            <h4 style="display: inline-block;">[[[label]]]</h4>
        </label>
        <slot></slot>
        <div v-if="error" class="invalid-field-error">
            [[[ error ]]]
        </div>
    </div>
    `
}