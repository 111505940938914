import NPopper from "./n-popper.js";
import NIconButton from "./buttons/n-icon-button.js";
import NButton from "./buttons/n-button.js";

class EVENTS {
}

EVENTS.UPDATE_COLUMNS = 'update'

export default {
    components: {
        'n-popper': NPopper,
        'n-icon-button': NIconButton,
        'n-button': NButton
    },
    props: {
        callback: Function
    },
    inject: ['checkList'],
    emits: Object.values(EVENTS),
    data() {
        return {
            PREVIOUS_INDEX: -2,
            CURRENT_INDEX: -1
        };
    },
    methods: {
        toggleColumn(index) {
            const id = this.checkList.activeColumns.at(index)
            if (id) {
                this.checkList.toggleColumn(id)
                return
            }
            console.error('column id does not exist')
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
        <n-icon-button v-if="this.checkList.activeColumns.length > 1"  small icon-class="fas fa-chevron-left" outlined @click="toggleColumn(PREVIOUS_INDEX)"></n-icon-button>
    `,
};