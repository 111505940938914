export default {
    props: {
        text: String,
    },
    data() {
        return {}
    },
    methods: {
        copyText() {
            navigator.clipboard.writeText(this.text).then(() => alert('Text copied to clipboard!')).catch((err) => console.error('Failed to copy text: ', err))
        }
    },
    created() {

    },
    mounted() {

    },
    template: `
          <p v-bind="$attrs" @click="copyText" :title="text" style="cursor: pointer ">[[[ text ]]]</p>
        `,
}
