import NButton from "../buttons/n-button.js";


export default {
    inject: ['current_device', 'loader'],
    components: {
        'n-button': NButton
    },
    props: {
        light: Boolean,
        cols: String,
        name: String,
        results: String,
        download: String,
        small: Boolean,
        tiny: Boolean,
        fitContent: Boolean,
        fixedWidth: Boolean,
        fixedHeight: Boolean,
        static: Boolean,
        id: String,
    },
    data() {
        return {
            resultText: gettext('Results'),
            downloadText: gettext('Download table as excel')
        };
    },
    provide() {
        const name = this.name || NORSE_FILTERS.PAGE_CONTROLLER
        pHandler.registerParamBuilder(name)
        return {
            tableparam: pHandler.getOrRegisterParamBuilder(name)
        };
    },
    methods: {
        getClass() {
            let tableClass = 'table-wrapper'
            if (this.static) {
                tableClass += ' static'
            }
            if (this.light) {
                tableClass += ' light'
            }
            if (this.fitContent) {
                tableClass += ' fit-content'
            }
            if (this.small) {
                tableClass += ' small'
            }

            if (this.tiny) {
                tableClass += ' tiny'
            }

            if (this.fixedHeight) {
                tableClass += ' fixed-height'
            }

            if (this.fixedWidth) {
                tableClass += ' fixed-width'
            }

            if (this.current_device === 'TABLET') {
                tableClass = tableClass.replace('small', 'tiny')
            }

            return tableClass
        },
        getWrappingClass() {
            let wrapClass = 'n-flex table-wrapping-class'
            if (this.fitContent && this.current_device === 'DESKTOP') {
                wrapClass += ' max-w-max'
            }

            if (this.download) {
                wrapClass += ' download'
            }
            return wrapClass
        },
        initTables() {
            const expandableRows = this.$refs.table.querySelectorAll('.expandable-row');
            expandableRows.forEach(row => {
                row.addEventListener('click', (event) => {
                    row.nextElementSibling.classList.toggle('show');
                });
            });
        },
        isLoading() {
            return this.loader.isActionLoading(this.download)
        },
        downloadTable() {
            let paramBuilder = pHandler.getOrRegisterParamBuilder(this.name)
            let data = paramBuilder.getBrowserParamsDict()
            let action = new Action(this.download)
            return action.dispatchStartAction(this).download.xls(data).then(() => {
                action.dispatchStopAction(this)
            })
        }
    },
    mounted() {
        this.initTables()

        if (this.tiny) {
            const containerHTMLCollection = this.$el.getElementsByClassName('pagination-container')
            if (containerHTMLCollection[0]) {
                containerHTMLCollection[0].style.gap = '.5rem'
                const buttonsyHTMLCollection = this.$el.getElementsByClassName('pagination-button')
                for (var i = 0; i < buttonsyHTMLCollection.length; i++) {
                    buttonsyHTMLCollection[i].classList.add('tiny')
                }
            }

        }

    },
    template: `
    <div :class="getWrappingClass()">
        <n-button class="download-table-button" :is-loading="isLoading()" v-if="download && results > 0" icon-right="fa-sharp fa-regular fa-download" :text="downloadText"  @click="downloadTable" small outlined> </n-button>
        <div v-if="results" class="results-container">[[[resultText]]]: [[[results]]]</div>
        <div class="n-flex n-col gap-m" :style="tiny ? {gap: '1rem'} :{width:'100%'}  ">
            <div :id="id" :class="getClass()" >
                <table ref="table">
                    <thead><slot name="header"></slot></thead>
                    <tbody :id="name"><slot name="body"></slot></tbody>
                    <tfoot><slot name="footer"></slot></tfoot>
                </table>
            </div>
            <slot name="pagination"></slot>
        </div>
    </div>
    `,
};