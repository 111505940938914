class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.ON_SEARCH = 'search'
EVENTS.ON_ENTER = 'enter'


export default {
    emits: Object.values(EVENTS),
    props: {
        modelValue: null,
        hideLabel: Boolean,
        id: String,
        name: String,
        initial: String,
        helpText: String,
        disabled: Boolean,
    },
    data() {
        return {
            value: null,
            searchText: gettext('Search')
        }
    },
    methods: {
        propagateValue(value) {
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        searchValue(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.ON_SEARCH, value)
        },
        onEnter(value) {
            this.searchValue(value)
            this.$emit(EVENTS.ON_ENTER, value)
        },
        getInputTextClass() {
            let textClass = 'n-search'

            if (this.disabled) {
                textClass += ' disabled'
            }

            return textClass
        },
        getSearchButtonClass() {

            if (!this.value) {
                return 'n-search-button disabled'
            }

            if (this.value?.length <= 0) {
                return 'n-search-button disabled'
            }

            return 'n-search-button';
        }
    },
    created() {
        if (this.initial) {
            this.searchValue(this.initial)
            return
        }
        if (this.modelValue)
            this.value = this.modelValue
    },
    template: `
    <div class="input-container full-width" style="max-width: 500px">
        <label v-if="!hideLabel" class="field-title" :for="id">[[[ searchText ]]]</label>
        <div class="search-container" >
            <input 
                :id="id" 
                :name="name" 
                :value="value" 
                :class="getInputTextClass()" 
                :placeholder="helpText || searchText + '...'"
                :disabled="disabled"
                @input="propagateValue($event.target.value)"
                @click.stop=""
                @keyup.enter="onEnter($event.target.value)"/>
            <div :class="getSearchButtonClass()" @click="searchValue(value)"> <i class="fa fa-search"></i></div>
        </div>
    </div>

    `,
}