import NPopper from "../popups/n-popper.js";
import PlanningItem from "./planning-item.js";
import NFlex from "../styling/n-flex.js";

export default {
    components: {
        'n-popper': NPopper,
        'planning-item': PlanningItem,
        'n-flex': NFlex,
    },
    props: {
        inspections: String,
        state: Number,
    },
    data() {
        return {
            STATES: WORK_ORDER_STATES,
        };
    },
    methods: {
        getInspectionStateClass() {
            let planningClass = 'planning-item-cluster '
            switch (this.state) {
                case this.STATES.CREATED:
                    break;
                case this.STATES.DONE:
                    planningClass += 'approved';
                    break;
                case this.STATES.CONFIRMED:
                    planningClass += 'confirmed';
                    break;
                case this.STATES.ONGOING:
                    planningClass += 'ongoing';
                    break;
                default:
                    break;
            }
            return planningClass
        }
    },
    mounted() {
        console.log(this.inspections)
    },
    template: `
    <n-popper>
        <div :class="getInspectionStateClass()" >[[[inspections]]]</div>
        <template #content>
        <n-flex column gap="4px" width="150px">
        <planning-item v-for="n in inspections" :name="'inspection' + n" :state="n"></planning-item>
        </n-flex>
        </template>
    </n-popper> 
    `,
};