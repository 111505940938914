// IOApplicationStates
class CASE_RESULT_STATES {
}

CASE_RESULT_STATES.APPROVED = '1'
CASE_RESULT_STATES.REJECTED = '2'

export default {
    components: {},
    props: {
        small: Boolean,
        status: String,
    },
    data() {
        return {
            approvedText: gettext('Approved'),
            rejectedText: gettext('Rejected'),
        }
    },
    methods: {
        getStatusClass() {
            let statusClass = 'status-label '
            if (this.status === CASE_RESULT_STATES.APPROVED) {
                statusClass += ' approved'
            }
            if (this.status === CASE_RESULT_STATES.REJECTED) {
                statusClass += ' rejected'
            }
            if (this.small) {
                statusClass += ' small'
            }
            return statusClass
        },
        getStatusText() {
            if (this.status === CASE_RESULT_STATES.APPROVED) {
                return this.approvedText
            }
            if (this.status === CASE_RESULT_STATES.REJECTED) {
                return this.rejectedText
            }
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
       <div :class="getStatusClass()" :data-string="getStatusText()"></div>
        `,
}
