import NFlex from "../styling/n-flex.js";
import NIconButton from "./n-icon-button.js";
import NPopper from "../n-popper.js";

export default {
    components: {
        'n-icon-button': NIconButton,
        'n-flex': NFlex,
        'n-popper': NPopper
    },
    props: {
        color: {
            type: String,
        },
        size: {
            type: String,
            default: undefined
        },
        iconClass: String,
        disabled: {
            type: Boolean,
            default: false
        },
        anchorRight: Boolean,
        outlined: {
            type: Boolean,
            default: false
        },
        growUp: Boolean,


    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleIsOpen() {
            this.isOpen = !this.isOpen
        },
        getContainerClass() {
            return 'n-button-dropdown-container icon'
        },
        getButtonContainerClass() {
            let containerClass = 'n-button-dropdown-button-container left icon '

            if (this.anchorRight) {
                containerClass = 'n-button-dropdown-button-container right icon '
            }


            if (this.disabled) {
                containerClass += ' disabled'
                return containerClass
            }
            if (this.isOpen) {
                containerClass += ' open'
            }

            if (this.growUp) {
                containerClass += ' grow-up'
            }
            return containerClass
        },
        onClickAway() {
            if (this.isOpen) {
                this.toggleIsOpen()
            }
        }
    },
    created() {
    },
    template: `
        <span :class="getContainerClass()" @click="toggleIsOpen()" v-click-away="onClickAway">
            <n-icon-button 
            :outlined="outlined" 
            :small="size" 
            :disabled="disabled" 
            :icon-class="iconClass" 
            :color="color">
            </n-icon-button>
                <slot></slot>
        </span>
        `,
};
