export default {
    props: {
        id: String,
        initialOpen: Boolean
    },
    data() {
        return {
            nClass: 'page-tab-item '
        };
    },
    methods: {},
    computed: {},
    mounted() {
        if (this.initialOpen)
            this.nClass += ' active'
    },
    template: `
     <button :class="nClass"
             :id="id + '-button'"
             data-bs-toggle="tab"
             :data-bs-target="'#' + id"
             type="button"
             role="tab">
         <slot></slot>
     </button>
    `,
};