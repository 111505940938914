export default {
    props: {
        cols: String,
        dataLabel: String,
    },
    data() {
        return {};
    },
    methods: {},
    computed: {
        getClass() {
            return this.types[this.type]
        },
    },
    mounted() {
    },
    template: `
 
      <td :colspan="cols" :data-label="dataLabel">
        <slot></slot>
      </td>
    `,
};