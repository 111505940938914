import NInputHistory from "./n-input-history.js";
import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    SAVE: 'autoSave',
}

var CHECKMARK_LIFETIME = 1500 //1second

export default {
    components: {
        "n-input-history": NInputHistory,
        "n-tooltip": NTooltip,
    },
    inject: ["errors"],
    watch: {
        errors(newValues, oldValue) {

            const error = NorseComponent.getError(this.name, newValues, this.$el)

            if (error) {
                this.makeInvalid(error)
                return true
            }

            return false
        },
        initialError(newValue) {
            // I can't figure out what is causing this to render before the template update.
            // but this is the solution for IOE update application bug
            if (newValue) {
                this.makeInvalid(newValue)
            }
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    emits: Object.values(EVENTS),
    props: {
        uppercase: Boolean,
        modelValue: null,
        disabled: Boolean,
        inputmode: String,
        hideLabel: Boolean,
        isFocused: Boolean,
        id: String,
        hideTooltip: Boolean,
        name: String,
        label: String,
        type: String,
        initial: String,
        initialError: String,
        placeholder: String,
        fullWidth: Boolean,
        disableUndo: Boolean,
        history: [],
        helpText: String,
        persistResult: Boolean,
    },
    data() {
        return {
            value: null,
            initialValue: null,
            error: null,
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.shouldShowError(this.error)

            this.value = this.uppercase ? value.toUpperCase() : value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        save() {
            if (this.value === this.initialValue) {
                return
            }
            this.previousValue = this.initialValue

            const event = {name: this.name, value: this.value, oldValue: this.initialValue}
            this.initialValue = this.value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.value)
            this.$emit(EVENTS.SAVE, event)


            this.$refs.inputRef.blur()
            this.shouldShowSuccess()
        },
        shouldShowSave(error) {
            const {loader, checkmark} = this.$refs;
            if (!checkmark || !loader) {
                return
            }
            if (!error) {
                loader.classList.add('show')
                loader.classList.remove('load-complete')
            }

        },
        shouldShowSuccess() {
            const {loader, checkmark} = this.$refs;

            loader.classList.add('show')
            checkmark.classList.add('show')
            loader.classList.add('load-complete')
            checkmark.classList.add('draw')
            if (!this.persistResult) {
                setTimeout(() => {
                    loader.classList.remove('load-complete')
                    checkmark.classList.remove('draw')
                    loader.classList.remove('show')
                    checkmark.classList.remove('show')
                }, CHECKMARK_LIFETIME)
            }
        },
        shouldShowError(error) {
            const {loader, checkmark} = this.$refs;
            if (!checkmark || !loader) {
                return
            }
            if (error) {

                loader.classList.remove('load-complete')
                checkmark.classList.remove('draw')

                loader.classList.add('show')
                loader.classList.add('load-error')
                checkmark.classList.add('show')
                checkmark.classList.add('error')
                return
            }
            loader.classList.add('load-complete')
            checkmark.classList.add('draw')

            loader.classList.remove('show')
            loader.classList.remove('load-error')
            checkmark.classList.remove('show')
            checkmark.classList.remove('error')
        },
        makeInvalid(error) {
            this.error = error
            this.shouldShowError(this.error)
        },
        getContainerClass() {
            let containerClass = 'auto-save-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            if (this.isFocused) {
            }

            return containerClass
        },
        getInputTextClass() {
            let textClass = 'textfield'

            if (this.disabled) {
                textClass += ' disabled'
            }
            if (this.error) {
                textClass += ' error'
            }

            return textClass
        },
        getInputProperties() {
            return {
                autofocus: this.isFocused,
                inputmode: this.inputmode,
                id: this.id,
                name: this.name,
                type: this.type,
                value: this.value,
                placeholder: this.placeholder,
                disabled: this.disabled,
                class: this.getInputTextClass()
            }
        },
        showHelpText(event) {
            this.$refs.tooltip.classList.add('show')
            const rect = this.$refs.tooltip.getBoundingClientRect()
            if (rect.top < 5) {
                this.$refs.tooltip.classList.add('show')
                this.$refs.tooltip.classList.toggle('bottom')

            }
            document.addEventListener('click', this.handleClickedOutside, true)
        },
        handleClickedOutside(event) {
            const elementToTrack = this.$refs.tooltip;
            if (!!(elementToTrack && !elementToTrack.contains(event.target))) {
                this.hideHelpText()
            }
        },
        hideHelpText(event) {
            this.$refs.tooltip.classList.remove('show')
            document.removeEventListener('click', this.handleClickedOutside, true)
        }
    },
    created() {
        // initial has precedence over modelValue
        if (this.initial) {
            this.initialValue = this.initial
            this.propagateValue(this.initial)
            return
        }
        // Make sure to propagate initial modelValue to value
        if (this.modelValue) {
            this.initialValue = this.modelValue
            this.value = this.modelValue
        }
    },
    mounted() {
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.isFocused) {
            this.$refs.inputRef.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        }
    },
    template: `
    <div :class="getContainerClass()" ref="autosave">
        <div class="n-flex gap-m " style="justify-content: space-between">
            <label v-if="!hideLabel" class="field-title" :for="id">[[[ label ]]]</label>
            <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>

        <input  
                v-bind="getInputProperties()"
                ref="inputRef" 
                @input="propagateValue($event.target.value)"
                @click.stop=""
                @blur="save"
                @keyup.exact.enter="save"/>
        <div ref="loader" class="circle-loader">
            <div ref="checkmark" class="checkmark draw"></div>
        </div>
        <div v-if="error"  class="invalid-field-error auto-save">
        [[[ error ]]]
        </div>
    </div>
    `,
}
