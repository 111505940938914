export default {
    props: {
        collapseId: Number
    },
    data() {
        return {
        };
    },
    methods: {
        getCollapsableId(id, isTarget = false) {
            if (isTarget) {
                return '#' + 'collapse-' + id
            }
            return 'collapse-' + id
        },
    },
    computed: {
    },
    mounted() {},
    template: `
      <tr class="collapse" :id="getCollapsableId(collapseId)" style="transition: none;">
        <slot></slot>
      </tr>
    `,
};