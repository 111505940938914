import NMapboxPopup from "./n-mapbox-popup.js";
import NMapboxTextfieldAutofill from "./n-mapbox-textfield-autofill.js";
import NFacilityMapbox from "./n-facility-mapbox.js";
import NInspectionPlanningMapbox from "./n-inspection-planning-mapbox.js";


export default {
    "n-inspection-planning-mapbox": NInspectionPlanningMapbox,
    "n-facility-mapbox": NFacilityMapbox,
    'n-mapbox-popup': NMapboxPopup,
    'n-mapbox-textfield-autofill': NMapboxTextfieldAutofill,
};
