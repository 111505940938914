export default {
    props: {
        className: String,
        margin: String,
        padding: String,
        width: String,
        height: String,
        maxWidth: String,
        bgColor: String,
        gap: String,
    },
    data() {
        return {
            children: null,
            containerClass: null,
            containerStyle: null,
            calculatedBasis: 100
        };
    },
    methods: {
        initContainer() {
            this.initClass()
            this.initStyle()
        },
        initClass() {
            this.containerClass = this.className || "n-block ";
        },
        initStyle() {
            this.containerStyle = {
                margin: this.margin || undefined,
                padding: this.padding || undefined,
                width: this.width || undefined,
                height: this.height || undefined,
                "max-width": this.maxWidth || undefined,
                "background-color": this.bgColor || undefined,
                gap: this.gap || undefined,
            }
        }
    },
    created() {
        this.initContainer()
    },
    template: `
        <div :class="containerClass" :style="{...containerStyle}">
          <slot></slot>
        </div>
        `,
}
