import NButton from "../buttons/n-button.js";

class EVENTS {
}

EVENTS.UPLOAD_FILES = 'upload-files'
EVENTS.REMOVE_FILE = 'remove-file'

class FILE_ICONS {
}

class ACCEPTED_FILES {
}

FILE_ICONS.PDF = 'fa-sharp fa-regular fa-file-pdf'
FILE_ICONS.EXCEL = 'fa-sharp fa-regular fa-file-excel'
FILE_ICONS.WORD = 'fa-sharp fa-regular fa-file-word'
FILE_ICONS.IMAGE = 'fa-sharp fa-regular fa-file-image'
FILE_ICONS.GENERIC = 'fa-sharp fa-regular fa-file'

ACCEPTED_FILES.PDF = 'application/pdf,'
ACCEPTED_FILES.EXCEL = '.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,.xlsx,.xls,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.template'
ACCEPTED_FILES.WORD = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
ACCEPTED_FILES.IMAGE = 'image/'
ACCEPTED_FILES.VIDEO = 'video/'

const ALLOWED_FILE_TYPES =
    ['png', 'jpg', 'jpeg', 'webp',
        'svg', 'dot', 'doc', 'docx',
        'xls', 'xlsx', 'csv', 'pdf',
        'ogm', 'wmv', 'mpg', 'webm',
        'mov', 'mpeg', 'mp4', 'avi']

export default {
    inject: ['toaster'],
    emits: Object.values(EVENTS),
    components: {
        "n-button": NButton,
    },
    props: {
        disabled: Boolean,
        input_files: {
            type: Array,
            default: []
        },
        fullWidth: Boolean,
        id: String,
        image: Boolean,
        video: Boolean,
        word: Boolean,
        excel: Boolean,
        pdf: Boolean,
        single: Boolean,
        buttonText: {
            type: String,
            default: gettext('Upload document')
        }
    },
    data() {
        return {
            dataTransfer: new DataTransfer(),
            value: null,
            buttonStyle: {},
            acceptedFilesString: ""
        }
    },
    methods: {
        handleInputClick() {
            this.$refs.inputRef.click()
        },
        initAcceptedString() {
            let acceptedFiles = ''

            if (this.image) {
                acceptedFiles += 'image/*,'
            }
            if (this.video) {
                acceptedFiles += 'video/*,'
            }
            if (this.word) {
                // borrowed from mdn Web Docs for accepting word files
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
                acceptedFiles += ACCEPTED_FILES.WORD
            }
            if (this.excel) {
                // .csv : For CSV files
                // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet : Excel Files 2007+ (.xlsx)
                // application/vnd.ms-excel : For  Excel Files 97-2003 (.xls)
                acceptedFiles += ACCEPTED_FILES.EXCEL
            }
            if (this.pdf) {
                acceptedFiles += ACCEPTED_FILES.PDF
            }

            this.acceptedFilesString = acceptedFiles
        },
        handleAddFile(e) {
            if (e.target.files?.length) {
                this.dataTransfer = new DataTransfer()
                const files = Array.from(e.target.files)

                if (this.acceptedFilesString.length > 0) {
                    const fileEndingsArray = files.map(file => {
                            const fileEnding = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()
                            if (!ALLOWED_FILE_TYPES.includes(fileEnding)) {
                                return fileEnding
                            }
                        }
                    )
                    const isAcceptedFiletype = fileEndingsArray.filter(fileEnding => fileEnding)

                    if (isAcceptedFiletype.length > 0) {
                        this.toaster.addMessage(gettext(`File types not supported: ${isAcceptedFiletype.join(', ')}`))
                        return
                    }
                }

                for (let i = 0; i < e.target.files.length; ++i) {
                    this.dataTransfer.items.add(e.target.files[i])
                }
                this.$emit(EVENTS.UPLOAD_FILES, this.dataTransfer.files)
            }
        },
        getFileIcon(fileType) {
            const regex = new RegExp(fileType, 'i');

            if (regex.test(ACCEPTED_FILES.PDF)) return FILE_ICONS.PDF
            if (regex.test(ACCEPTED_FILES.EXCEL)) return FILE_ICONS.EXCEL
            if (regex.test(ACCEPTED_FILES.WORD)) return FILE_ICONS.WORD
            if (fileType.includes('image')) return FILE_ICONS.IMAGE
            if (fileType.includes('video')) return FILE_ICONS.GENERIC

            return FILE_ICONS.GENERIC
        },
        removeItem(fileToRemove) {
            this.$emit(EVENTS.REMOVE_FILE, fileToRemove)
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        }
    },
    created() {
        this.initAcceptedString()
    },
    mounted() {
    },
    template: `
        <div :class="getContainerClass()" style="width: unset" >
            <n-button v-if="!disabled" small @click="handleInputClick" outlined :text="buttonText" icon-right="fa-regular fa-cloud-arrow-up" >
                <input 
                    ref="inputRef"
                    hidden
                    type="file"
                    :multiple="!single"
                    @change="handleAddFile"
                    :id="id" 
                    :accept="acceptedFilesString"
               >
            </n-button>
            <div class="n-flex n-col gap-s" style="width: unset">
                <div v-if="single && input_files.length > 0" class="n-flex gap-m n-file-input-file"  v-for="file in input_files">
                    <div class="n-flex gap-s align-center">
                        <i class="fa-sharp fa-regular fa-file"></i>
                        <a :href="file?.access_url" target="_blank">
                           [[[file?.name]]]
                        </a>
                    </div>
                </div>
                <div v-if="!single" class=" n-flex n-file-input-file gap-m" v-for="file in input_files">
                        <i class="fa-sharp fa-regular fa-file"></i>
                    <a :href="file?.access_url" target="_blank">
                        [[[file?.name]]]
                    </a>
                    <i v-if="!disabled" class="fa-sharp fa-solid fa-trash remove-item" @click="removeItem(file)"></i>
                </div>
            </div>
        </div>
    `,
}