import NMapboxPopup from "./n-mapbox-popup.js";
import NIconButton from "../buttons/n-icon-button.js";

const ZOOM_LEVEL_DEFAULT = 5
const ZOOM_LEVEL_CLOSE = 11

class EVENTS {
}

EVENTS.ON_SEARCH = 'on-search'
EVENTS.ON_CREATED = 'on-created'
EVENTS.SELECT_LOCATION = 'on-select-location'
export default {
    props: {
        placesData: Object,
        center: Array,
    },
    emits: Object.values(EVENTS),
    components: {
        'n-mapbox-popup': NMapboxPopup,
        'n-icon-button': NIconButton
    },
    watch: {
        placesData: {
            handler(newValue) {
                mapboxgl.accessToken = this.$root.MAPBOX_API;
                const hasUndefinedValue = this.center?.some(latLang => latLang === undefined)
                if (this.center?.length > 0 && !hasUndefinedValue) {
                    this.zoom = ZOOM_LEVEL_CLOSE
                    this.setupMap(this.center);
                    return
                }

                this.getUserLocation()
                    .then(position => {
                        const userCenter = [position.coords.longitude, position.coords.latitude];
                        this.setupMap(userCenter);

                    })
                    .catch(error => {
                        console.error('Error getting user location:', error);
                        const swedenCenter = [18.643501, 60.128161];
                        this.setupMap(swedenCenter);
                    });
            },
            deep: true,
        },
    },
    data() {
        return {
            map: null,
            startLocation: null,
            zoom: ZOOM_LEVEL_DEFAULT,
            popup: null,
            mapboxSearchField: null,
            searchMarker: null,
            selectedLayerStyle: NORSE_MAP_LAYER_STYLES.STREETS
        };
    },
    methods: {
        setupMap(center) {
            this.map = new mapboxgl.Map({
                container: 'map',
                style: `mapbox://styles/mapbox/${NORSE_MAP_LAYER_STYLES.STREETS}-v9`, // style URL
                projection: 'globe',
                center: center,
                zoom: this.zoom
            });

            this.map.on('load', () => {
                // Add a popup to the map
                this.popup = new mapboxgl.Popup({
                    className: 'n-map-popup'
                });
                this.addMarker()
                this.loadPlaces()
                this.addMapEvents()
                this.addControls()
                this.addControlEvents()
                this.setMapInteractivity()
                this.map.resize()
            })
        },
        addMarker() {
            const el = document.createElement('div');
            el.className = NORSE_MAP_MARKER.CLASS_NAME;
            el.style.backgroundImage = MAPBOX_FACILITY_ICONS.FACILITY
            el.style.width = NORSE_MAP_MARKER.WIDTH;
            el.style.height = NORSE_MAP_MARKER.HEIGHT;

            this.searchMarker = new mapboxgl.Marker(el, {
                draggable: true
            })
        },
        addControls() {
            this.mapboxSearchField = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
            })
            this.map.addControl(this.mapboxSearchField);
            this.map.addControl(new mapboxgl.NavigationControl());
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            }));
        },
        addLayer() {

            this.map.addLayer({
                id: this.placesData.layerID,
                type: 'symbol',
                source: 'places',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': ['get', 'icon'],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
            });
        },
        loadPlaces() {
            if (this.placesData.data.features.length > 1) {
                console.error("Facility map only handles one entry.")
                console.error("Entries: ", this.placesData.data.features.length)
                return
            }
            //should only be one
            const feature = this.placesData.data.features[0]
            this.startLocation = feature.geometry.coordinates
            this.searchMarker.setLngLat(this.startLocation).addTo(this.map)
        },
        addControlEvents() {
            this.mapboxSearchField.on('result', (e) => {
                this.searchMarker.setLngLat(e.result.center).addTo(this.map);
                const lngLat = this.searchMarker.getLngLat();
                this.$emit(EVENTS.ON_SEARCH, lngLat)
            });
            this.searchMarker.on('dragend', (e) => {
                this.searchMarker.getElement().style.backgroundImage = MAPBOX_FACILITY_ICONS.PENDING_FACILITY
                const lngLat = this.searchMarker.getLngLat();
                this.$emit(EVENTS.SELECT_LOCATION, lngLat)
            });
        },
        addMapEvents() {
            this.map.on('mouseenter', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });
            this.map.on('touchstart', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });

            this.map.on('mouseleave', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove()
            });
            this.map.on('touchend', this.placesData.layerID, () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX];
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY;
            }
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            const lng = e.lngLat.lng;

            const coordinates = feature.geometry.coordinates.slice();

            this.calculateCoordinates(coordinates, lng);

            const props = feature.properties;
            const popupContent = document.createElement('div');

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxPopup, {...props});
                }
            });

            tempApp.component('n-mapbox-popup', NMapboxPopup);
            tempApp.config.compilerOptions.delimiters = ["[[[", "]]]"]
            tempApp.mount(popupContent);

            this.popup
                .setLngLat(coordinates)
                .setDOMContent(popupContent)
                .addTo(this.map);
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'));
                }
            });
        },
        setMapInteractivity() {
            const controls = document.getElementsByClassName('mapboxgl-ctrl')
            const mapEl = document.getElementById('map')

            if (!this.isDisabled) {
                mapEl.style.pointerEvents = 'unset'
                for (let control of controls) {
                    control.style.visibility = 'unset'
                }
                return
            }
            mapEl.style.pointerEvents = 'none'
            for (let control of controls) {
                control.style.visibility = 'hidden'
            }
        },
        resetMarkerCoordinates() {
            this.searchMarker.getElement().style.backgroundImage = MAPBOX_FACILITY_ICONS.FACILITY
            this.searchMarker.setLngLat(this.startLocation)
            this.map.jumpTo({center: this.startLocation })
        },
        switchStyle(layer) {
            this.selectedLayerStyle = layer
            this.map.setStyle('mapbox://styles/mapbox/' + this.selectedLayerStyle + '-v9');
        },
    },
    mounted() {
    },
    created() {
        this.$emit(EVENTS.ON_CREATED, this)
    },
    template: `
        <div id='map' style='width: 100%; height: 500px; border-radius: 5px; border: 2px solid #55b8ff'>
            <div class="n-flex gap-xs" style="position:absolute; top:4px; left: 4px; z-index: 1000">
                <n-icon-button tiny :outlined="selectedLayerStyle != 'streets'" icon-class="fa-sharp fa-solid fa-road" @click="switchStyle('streets')"> </n-icon-button>
                <n-icon-button tiny :outlined="selectedLayerStyle != 'satellite'" icon-class="fa-sharp fa-regular fa-earth-europe" @click="switchStyle('satellite')"> </n-icon-button>
            </div>
        </div>
`,
};
