import NTooltip from "../n-tooltip.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'
EVENTS.SAVE = 'auto-save'

export default {
    inject: ["errors"],
    components: {
        "n-tooltip": NTooltip,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        }
    },
    emits: Object.values(EVENTS),
    props: {
        isFilter: Boolean,
        modelValue: String,
        optional: Boolean,
        hideTooltip: Boolean,
        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        placeholder: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        hideLabel: Boolean,
        fullWidth: Boolean,

        choices: Array,  // of objects with text and value
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            value: null,
            error: null,
            labelClass: null,
            optionalText: gettext('Optional')

        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            if (this.error) {
                this.initStyle()
                this.error = false
            }
            const event = {'name': this.name, 'value': this.value}
            this.$emit(EVENTS.CHANGE, value)
            this.$emit(EVENTS.SAVE, event)
        },
        initStyle() {
            this.labelClass = 'field-title'
        },
        makeInvalid(error) {
            this.error = error
            this.selectClass = 'select error'
        },
        choiceIsSelected(choice) {
            if (this.modelValue) {
                return choice.value === this.modelValue
            }
            return this.value === choice.value
        },
        getInputSelectClass() {
            let selectClass = 'select'

            if (this.fullWidth) {
                selectClass += ' full-width'
            }

            if (this.disabled) {
                selectClass += ' disabled'
            }

            return selectClass
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        }
    },
    created() {
        this.initStyle()

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            this.propagateValue(this.initial)
            return
        }
        if (this.modelValue)
            this.value = this.modelValue

        if (!this.value && this.choices?.length > 0)
            this.propagateValue(this.choices[0].value)
    },
    template: `
    <div :class="getContainerClass()">
         <div class="n-flex gap-m " style="justify-content: space-between">
            <label v-if="!hideLabel" :class="labelClass" :for="id">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
           <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <select :name="name" :class="getInputSelectClass()"
                :disabled="disabled"
                @input="propagateValue($event.target.value)" 
                @change="onChange($event.target.value)">
            <option v-if="placeholder" value="" disabled selected hidden>[[[ placeholder ]]]</option>
            <template v-for="choice in choices">
                <option v-if="choiceIsSelected(choice)" selected :value="choice.value">[[[ choice.text ]]]</option>
                <option v-else :value="choice.value">[[[ choice.text ]]]</option>
            </template>
        </select>
        <div v-if="error" class="invalid-field-error">
        [[[ error ]]]
        </div>
    </div>

    `,
}