import NFlex from "../styling/n-flex.js";

class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.SELECT_CHOICE = 'toggle'

class SEVERITY_SHORT_NAME {
}

SEVERITY_SHORT_NAME.OK = 'OK'
SEVERITY_SHORT_NAME.MEDIUM = '1'
SEVERITY_SHORT_NAME.HIGH = '2'

export default {
    components: {
        'n-flex': NFlex
    },
    props: {
        modelValue: String,

        initial: String,
        name: String,

        choices: Array,
        disabled: Boolean,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            value: null
        }
    },
    methods: {
        propagateValue(value) {
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.SELECT_CHOICE, value)
        },
        getChoiceClass(choice) {
            let choiceClass = 'n-severity-toggle-button' + this.getCurrentChoiceStateClass(choice.text)

            if (this.value === choice.value) {
                choiceClass += ' selected'
            }

            if (this.disabled) {
                choiceClass += ' disabled'
            }

            return choiceClass
        },
        getCurrentChoiceStateClass(text) {
            switch (text) {
                case SEVERITY_SHORT_NAME.OK:
                    return ' low';
                case SEVERITY_SHORT_NAME.MEDIUM:
                    return ' medium';
                case SEVERITY_SHORT_NAME.HIGH:
                    return ' high';
                default:
                    return ""
            }
        },
    },
    created() {
        if (this.initial) {
            this.propagateValue(this.initial)
            return
        }
        if (this.modelValue)
            this.value = this.modelValue

        if(!this.value && this.choices.length > 0)
            this.propagateValue(this.choices[0].value)
    },
    template: `
    <div class="n-severity-toggle-container">
        <div v-for="(choice, index) in choices" 
                :class="getChoiceClass(choice)" 
                @click="onChange(choice.value)" >
            <p>[[[choice.text]]]</p>
        </div>
        <input type="hidden" :name="name" :value="value" />
    </div>
    `,
}
