export default {
    props: {
        direction:{
        type: String,
        default: "row",
        },
    },
    methods: {
        getStyle() {
            return this.types[this.direction]
        },
    },
    created() {
        this.types = {
            "column": "flex-direction: column;",
            "row": "flex-direction: row;",
        }
    },
    template: `<div style="display: flex; gap: 1rem; flex-wrap: wrap" :style="getStyle()"><slot></slot></div>`,
};