import NInputHistory from "./n-input-history.js";
import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    SAVE: 'inlineSave',
}

export default {
    components: {
        "n-input-history": NInputHistory,
        "n-tooltip": NTooltip,
    },
    inject: ["errors"],
    watch: {
        errors(newValues, oldValue) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            return false
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: null,
        optional: Boolean,
        disabled: Boolean,
        helpText: String,
        hideLabel: Boolean,
        id: String,
        name: String,
        label: String,
        initial: String,
        placeholder: String,
        fullWidth: Boolean,
        enableUndo: Boolean,
        historyItems: {
            type: Array,
            default: []
        },
        hideTooltip: Boolean,
    },
    data() {
        return {
            showSave: false,
            value: null,
            saveText: gettext('Save'),
            initialValue: null,
            inputClass: null,
            error: null,
            optionalText: gettext('Optional'),
            previousValue: null,
            showHistory: false,
            history_: []
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        save() {
            if (this.value !== this.initialValue) {
                // Makes sure that we store the last value that differed from current value
                this.previousValue = this.initialValue
                this.history_.push(this.previousValue)
            }

            this.showSave = false
            const event = {name: this.name, value: this.value, oldValue: this.initialValue}
            this.initialValue = this.value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.value)
            this.$emit(EVENTS.SAVE, event)

            this.$refs.inputRef.blur()
        },
        triggerEnable() {
            this.showSave = true
        },
        triggerDisable() {
            this.propagateValue(this.initialValue)
            this.showSave = false
        },
        onClickEdit() {
            this.$refs.inputRef.focus()
        },
        getInputClass() {
            let textClass = 'textfield inline'

            if (this.disabled) {
                textClass += ' disabled'
            }

            return textClass
        },
        getSaveButtonClass() {
            let saveClass = 'n-button save '

            if (this.disabled) {
                saveClass += ' disabled'
            }

            return saveClass
        },
        getEditButtonClass() {
            let editClass = 'n-button outlined edit '

            if (this.disabled) {
                editClass += ' disabled'
            }

            return editClass
        },
        makeInvalid(error) {
            this.error = error
            this.value = this.previousValue

            if (this.value !== this.initialValue) {
                this.previousValue = this.initialValue
            }

            this.showSave = false
            this.initialValue = this.value

            this.$refs.inputRef.focus()
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            containerClass += ' inline'

            return containerClass
        },
        hasPreviousValue() {
            if (!this.enableUndo) return false

            if (!!this.previousValue?.length) {
                return this.previousValue !== this.initialValue
            }
        },
        // undoLatestInput() {
        //     this.initialValue = this.previousValue
        //     this.propagateValue(this.initialValue)
        //     this.save()
        // },
        selectHistoryValue(value) {
            this.initialValue = value
            this.propagateValue(this.initialValue)
            this.save()
        },
        toggleShowHistory() {
            this.showHistory = !this.showHistory
        },
        showHelpText(event) {
            this.$refs.tooltip.classList.add('show')
            const rect = this.$refs.tooltip.getBoundingClientRect()
            if (rect.top < 5) {
                this.$refs.tooltip.classList.add('show')
                this.$refs.tooltip.classList.toggle('bottom')

            }
            document.addEventListener('click', this.handleClickedOutside, true)
        },
        handleClickedOutside(event) {
            const elementToTrack = this.$refs.tooltip;
            if (!!(elementToTrack && !elementToTrack.contains(event.target))) {
                this.hideHelpText()
            }
        },
        hideHelpText(event) {
            this.$refs.tooltip.classList.remove('show')
            document.removeEventListener('click', this.handleClickedOutside, true)
        }
    },
    mounted() {
        this.history_ = this.historyItems

    },
    created() {
        // initial has precedence over modelValue
        if (this.initial) {
            this.initialValue = this.initial
            this.propagateValue(this.initial)
            return
        }
        // Make sure to propagate initial modelValue to value
        if (this.modelValue) {
            this.initialValue = this.modelValuea
            this.value = this.modelValue
        }
    },
    template: `
        <div :class="getContainerClass()" >
            <div class="n-flex gap-m align-end" style="justify-content: space-between">
                <label v-if="!hideLabel" class="field-title" :for="id">[[[ label ]]]</label><em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em>
                <n-tooltip v-if="!hideTooltip"  :help-text="helpText" :label="label" >
                    <i class="fa-sharp fa-regular fa-circle-info"></i>
                </n-tooltip>
            </div>
            <div class="inline-edit" >
                <input :id="id" :name="name"
                :disabled="disabled"
                ref="inputRef" 
                :value="value" 
                @input="propagateValue($event.target.value)"
                :placeholder="placeholder" 
                :class="getInputClass()" 
                @focus="triggerEnable" 
                @click.stop="" 
                @blur="triggerDisable"
                @keydown.tab.exact="save"
                 />
                 
                 <button :class="getEditButtonClass()" tabindex="-1" v-show="!showSave"  @click.stop="onClickEdit" ><i class="fa fa-pen"></i></button>
                <!--  @mousedown.prevent prevents mouseDown to fire the onBlur method on input before save method is invoked -->
                 <button :class="getSaveButtonClass()" tabindex="-1" v-show="showSave" @mousedown.prevent @click.stop="save">[[[saveText]]]</button >
            </div>
            <div v-if="error" class="invalid-field-error inline">
                [[[ error ]]]
            </div>
            <div v-if="hasPreviousValue()" class="n-flex gap-m align-center inline-undo-container">
                <a class="n-flex gap-s align-center inline-undo " @click.stop="toggleShowHistory" >
                    <p style="text-decoration: line-through" title="Previous value">[[[previousValue]]]</p> 
                    <i class="fa-sharp fa-regular fa-clock-rotate-left"></i>
                </a>
            </div>
            <n-input-history :show-history="showHistory" :history-items="history_" @on-select-item="selectHistoryValue($event)" @on-close="toggleShowHistory"></n-input-history>
        </div>
    `,
}
