export default {
    props: {
        id: String,
        initialOpen: {type: Boolean, default: false},

    },
    data() {
        return {
            nClass: 'tab-container '
        };
    },
    methods: {},
    computed: {},
    created() {
        if (this.initialOpen)
            this.nClass += ' active'
    },
    template: `
         <div
                :id="id"
                 :class="nClass"
                 role="tabpanel"
                 :aria-labelledby="id"
                 >
                 
             <slot></slot>
         </div > 
    `,
};