import NCheckbox from "../inputs/n-checkbox.js";
import NButton from "../buttons/n-button.js";

class EVENTS {
}

EVENTS.CHECK = 'on-check'
EVENTS.CHECK_ALL = 'on-check-all'
EVENTS.CLICK = 'on-click'

export default {
    props: {
        expandable: Boolean,
        checkableTd: Boolean,
        checkableTh: Boolean,
        border: {
            type: Boolean,
            default: true
        },
        isChecked: Boolean,
    },
    components: {
        'n-checkbox': NCheckbox,
        'n-button': NButton,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            details: gettext('See details')

        };
    },
    methods: {
        getRowClass() {

            let rowClass = 'expandable-row'
            if (!this.expandable) {
                rowClass = 'unexpandable-row'
            }

            if (!this.border) {
                rowClass += ' no-border'
            }

            if (this.$attrs['onClick']) {
                rowClass += ' clickable'
            }

            return rowClass
        },
        check(ev) {
            this.$emit(EVENTS.CHECK, ev)
            this.$emit(EVENTS.CHECK_ALL, ev)
        },
        expand() {
            this.$emit(EVENTS.CLICK)
        }
    },
    mounted() {
    },
    template: `
        <tr  v-bind="$attrs" :class="getRowClass()"  @click="expand">
            <td v-if="checkableTd">
                <n-checkbox @click="check"  :is-checked="isChecked"></n-checkbox>
            </td>
             <th v-if="checkableTh">
                <n-checkbox @click="check"></n-checkbox>
            </th>
            <slot name="main-content"></slot>
            <td v-if="expandable" class="see-details-td"> <n-button tiny :text="details" outlined></n-button></td>
        </tr>

        <tr v-if="expandable" class="expanded-row">
                <slot name="expanded-content"></slot>
        </tr>
    `,
};