import NReviewOptions from "./n-review-options.js";
import WizardCommentBlock from "./wizard-comment-block.js";
import NWizardFormBlock from "./wizard-form-block.js";
import NonConformityItem from "./non-conformity-item.js";

export default {
    "n-review-options": NReviewOptions,
    "wizard-comment-block": WizardCommentBlock,
    "wizard-form-block": NWizardFormBlock,
    "non-conformity-item": NonConformityItem
}

