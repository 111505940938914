export default {
    inject: ['templates'],
    watch: {
        templates(updatedTemplates, oldTemplates) {
            this.load()
        },
    },
    props: {
        id: String,
        template: String
    },
    data() {
        return {
            html: null,
            content: null,
            properties: null
        }
    },
    methods: {
        load() {
            const html = this.templates[this.id]
            if (!html)
                return

            delete this.templates[this.id]
            this.renderContent(html)

        },
        renderContent(template) {
            const app = this.$root
            this.html = template
            this.content = Vue.shallowRef({
                template: template,
                methods: app.$options.methods,
                data: () => app.$data,
                computed: app.$options.computed,
                components: app.$options.components,
                watch: app.$watch,
                provide: app.$options.provide
            })
        },
    },
    mounted() {
    },
    template:
        `<component :is="content" :id="this.id"></component>`,
}