// Vue3DatePicker docs
//
// CURRENTLY USED PROPS
// placeholder ->> https://vue3datepicker.com/api/props/#placeholder
// modelType --> https://vue3datepicker.com/api/props/#modeltype
// cancelText --> https://vue3datepicker.com/api/props/#canceltext
// selectText --> https://vue3datepicker.com/api/props/#selecttext
// textInputOptions --> https://vue3datepicker.com/api/props/#textinputoptions
// disabled --> https://vue3datepicker.com/api/props/#disabled
// inputClassName --> https://vue3datepicker.com/api/props/#inputclassname
// state --> https://vue3datepicker.com/api/props/#state
// autoApply --> https://vue3datepicker.com/api/props/#autoapply
// closeOnAutoApply --> https://vue3datepicker.com/api/props/#closeonautoapply
// textInput --> https://vue3datepicker.com/api/props/#textinput
// locale --> https://vue3datepicker.com/api/props/#locale
// enableTimePicker --> https://vue3datepicker.com/api/props/#enabletimepicker
// @update:modelValue --> https://vue3datepicker.com/api/events/#update-modelvalue

import NFlex from "../styling/n-flex.js";
import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    CHANGE: 'change',
    SAVE: 'inlineSave',
}

export default {
    inject: ["errors"],
    components: {
        'n-date-picker': VueDatePicker,
        'n-flex': NFlex,
        "n-tooltip": NTooltip,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        optional: Boolean,
        hideTooltip: Boolean,

        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        placeholder: String,
        proposedDate: String,
        hideLabel: Boolean,
        fullWidth: Boolean,
        enableUndo: Boolean,

        //VueDatepickerProps
        autoApply:
            {
                type: Boolean,
                default: true
            },
        allowTextInput:
            {
                type: Boolean,
                default: true
            },
    },
    data() {
        return {
            showSave: false,
            initialValue: null,
            value: null,
            isValid: null,
            error: null,
            inputElm: null,
            //Vue3DatePicker data
            inputClass: null,
            cancelText: gettext('Cancel'),
            selectText: gettext('Select'),
            saveText: gettext('Save'),
            optionalText: gettext('Optional'),
            previousValue: null,

            textInputOptions: {
                enterSubmit: true,
                tabSubmit: true,
                openMenu: true,
                format: (val) => this.onInput(val),
            },
            proposedDateText: gettext('Click to apply'),
            validationState: null // set to false on error, reset to null when handled
        }
    },
    methods: {
        propagateValue(value) {
            const formattedValue = moment(value).format(Constants.DATE_FORMAT)

            this.error = null
            this.value = formattedValue
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, formattedValue)
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.CHANGE, value)
            this.resetError()
        },
        onInput(value) {
            if (this.isValidDateString(value)) {
                this.resetError()
                return moment(value).toDate()
            }
            this.makeInvalid("Invalid date")
            return moment(this.value).toDate()
        },
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'datepicker inline'
        },
        resetError() {
            this.error = ''
            this.inputClass = 'datepicker inline'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        },
        save() {
            this.showSave = false
            const event = {name: this.name, value: this.value, oldValue: this.initialValue}
            this.previousValue = this.initialValue
            this.initialValue = this.value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.value)
            this.$emit(EVENTS.SAVE, event)
            this.inputElm.blur()
        },
        triggerEnable() {
            this.showSave = true
        },
        triggerDisable() {
            this.propagateValue(this.initialValue)
            this.showSave = false
        },
        onClickEdit() {
            this.inputElm.focus()
        },
        applyProposedDate() {
            if (this.isValidDateString(this.proposedDate)) {
                //just in case guard
                this.resetError()
                this.propagateValue(this.proposedDate)
                this.inputElm.focus()
                return
            }
            this.makeInvalid("Invalid date")
        },
        isValidDateString(date) {
            const isStrict = true
            return moment(date, [Constants.DATE_FORMAT, 'YYYYMMDD'], isStrict).isValid()
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            containerClass += ' inline'

            return containerClass
        },
        getSaveButtonClass() {
            let saveClass = 'n-button save '

            if (this.disabled) {
                saveClass += ' disabled'
            }

            return saveClass
        },
        getEditButtonClass() {
            let editClass = 'n-button outlined edit '

            if (this.disabled) {
                editClass += ' disabled'
            }

            return editClass
        },
        hasPreviousValue() {
            if (!this.enableUndo) return false

            if (!!this.previousValue?.length) {
                return this.previousValue !== this.initialValue
            }
        },
        undoLatestInput() {
            this.initialValue = this.previousValue
            this.propagateValue(this.initialValue)
            this.save()
        },
    },
    created() {
        this.initStyle()
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            let value = moment(new Date(this.initial)).format(Constants.DATE_FORMAT);
            this.initialValue = this.initial
            this.propagateValue(value)
            return
        }
        if (this.modelValue) {
            this.initialValue = this.modelValue
            this.value = this.modelValue
        }

    },
    mounted() {
        this.inputElm = document.getElementById(`dp-input-${this.id}`)
    },
    template: `
    <div :class="getContainerClass()">
        <div class="n-flex gap-m align-center" style="justify-content: space-between">
            <label v-if="!hideLabel" :for="id" :class="labelClass">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
            <div v-if="proposedDate" class="proposed-date-container" @click="applyProposedDate()">
                [[[proposedDateText]]]: <p>[[[proposedDate]]]</p>
            </div>
            <n-tooltip v-if="!hideTooltip"  :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <div class="inline-edit" >
            <n-date-picker 
                v-model="value" 
                :uid="id" 
                :name="name" 
                format="yyyy-MM-dd" 
                :placeholder="placeholder || 'yyyy-mm-dd'"
                modelType="format"
                :cancelText="cancelText"
                :selectText="selectText"
                :clearable="showSave"
                :textInputOptions="textInputOptions"
                :disabled="disabled"
                :inputClassName="inputClass"
                :state="validationState"
                :autoApply="autoApply"
                :textInput="allowTextInput" 
                :closeOnAutoApply="true"
                locale="sv" 
                :enableTimePicker="false" 
                @focus="triggerEnable"
                v-click-away="triggerDisable"
                @update:modelValue="onChange(value)" 
            >
                <template #input-icon>
                    <i class="fa fa-calendar" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>
            <button :class="getEditButtonClass()" v-show="!showSave"  @click.stop="onClickEdit" ><i class="fa fa-pen"></i></button>
                <!--  @mousedown.prevent prevents mouseDown to fire the onBlur method on input before save method is invoked -->
             <button :class="getSaveButtonClass()" v-show="showSave" @mousedown.prevent @click.stop="save">[[[saveText]]]</button >
        </div>
        <div v-if="error" class="invalid-field-error inline" >
        [[[ error ]]]
        </div>
        <div v-if="hasPreviousValue()" class="n-flex gap-s inline-undo-container">
            <p style="text-decoration: line-through" title="Previous value">[[[previousValue]]]</p> 
            <a class="n-flex gap-xs align-center inline-undo " @click.once="undoLatestInput">
                <i class="fa-sharp fa-xs fa-solid fa-rotate-left"></i>
                undo
            </a>
        </div>
    </div>
    `,
}