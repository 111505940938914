import NToast from "./n-toast.js";

export default {
    inject: ['toaster'],
    watch: {
        toaster: {
            handler(toaster) {
                this.setActiveMessages(toaster.messages)
            },
            deep: true
        }
    },
    components: {
        'n-toast': NToast
    },
    props: {},
    data() {
        return {
            activeMessages: []
        }
    },
    methods: {
        setActiveMessages(messages) {
            this.activeMessages = messages
        },
        removeToaster(message) {
            this.$root.toaster.removeMessage(message)
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
    <div v-if="activeMessages.length > 0" class="n-toast-container">
        <template v-for="(activeMessage, index) in activeMessages">
            <n-toast :message="activeMessage.message"  :status="activeMessage.status" @handle-remove="removeToaster(activeMessage)"></n-toast>
        </template>
    </div>

    `,
}