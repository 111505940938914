export default {
    props: {},
    data() {
        return {
            element: null
        };
    },
    methods: {
        isOpen() {
            return !!this.element
        },
        handleClick(ev) {
            this.element = this.element ? null : ev.currentTarget
        },
        handleClickAway() {
            if (this.element) {
                this.element = null
            }
        }
    },
    created() {
    },
    mounted() {
        document.addEventListener('click', (event) => {
            event.stopPropagation()

        })
    },

    template: `
    <div ref="popper" style="position:relative"  @click.stop="handleClick($event)" >
        <slot></slot>
        <div v-if="isOpen()" class="n-popper" >
            <slot name="content"></slot>
        </div>
    </div>
    `,
};