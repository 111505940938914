
export default {
    props: {
        id: String,
        name: String,
        initial: String,
    },
    data() {
        return {
            value: null,
        }
    },
    methods: {
    },
    created() {
        if (this.initial) {
            this.value = this.initial
            return
        }
        if (this.modelValue)
            this.value = this.modelValue
    },
    template: `
      <input type="hidden" :name="name" :id="id" :value="value">
    `,
}