import NIconButton from "../buttons/n-icon-button.js";

class EVENTS {
}

EVENTS.UPLOAD_IMAGES = 'upload-images'

const allowedFileTypes = ['png', 'jpg', 'jpeg', 'webp', 'tif', 'tiff', 'jfif', 'bmp']

export default {
    inject: ['toaster'],
    emits: Object.values(EVENTS),
    components: {
        "n-icon-button": NIconButton,
    },
    props: {
        disabled: Boolean,
        id: String,
        small: Boolean,
    },
    data() {
        return {
            dataTransfer: new DataTransfer(),
            value: null,
            buttonStyle: {},
        }
    },
    methods: {

        handleInputClick() {
            this.$refs.inputRef.click()
        },
        handleAddImage(e) {
            if (e.target.files?.length) {
                this.dataTransfer = new DataTransfer()

                const fileName = e.target.files[0].name
                const result = fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length)

                const isAcceptedFiletype = allowedFileTypes.includes(result.toLowerCase())

                if (!isAcceptedFiletype) {
                    this.toaster.addMessage(gettext(`Accepted file types: ${allowedFileTypes.join(', ')}`))
                    return
                }

                for (let i = 0; i < e.target.files.length; ++i) {
                    this.dataTransfer.items.add(e.target.files[i])
                }
                this.$emit(EVENTS.UPLOAD_IMAGES, this.dataTransfer.files)
            }
        },
        initStyle() {
            if (this.small) {
                this.buttonStyle = {
                    minWidth: '70px', width: '70px', height: '50px',
                }
                return
            }

            this.buttonStyle = {
                minWidth: '135px', width: '135px', height: '100px',
            }
        },
        getStyle() {
            return this.buttonStyle
        }

    },
    created() {
        this.initStyle()
    },
    template: `
        <n-icon-button v-if="!disabled" @click="handleInputClick" color="sand" icon-class="fa fa-plus" :style="getStyle()">
            <input 
                ref="inputRef"
                hidden
                type="file"
                multiple
                @change="handleAddImage"
                :id="id" 
                accept="image/*"
           >
        </n-icon-button>
    `,
}