import NButton from "../buttons/n-button.js";
import NTextareaInput from "../inputs/n-textarea-input.js";
import NIconButton from "../buttons/n-icon-button.js";

const EVENTS = {
    ADD_COMMENT: 'on-add-comment',
}

export default {
    inject: ['current_device'],
    components: {
        "n-button": NButton,
        "n-icon-button": NIconButton,
        "n-textarea-input": NTextareaInput
    },
    data() {
        return {
            text: '',
            mock_comments: [],
            mock_non_comformities: [],
            comment_block: null,
            conformity_block: null,
            content_block: null,
            currentBlock: null,
        }
    },
    emits: Object.values(EVENTS),
    props: {
        title: String,
        currentTab: String,
    },
    methods: {
        scrollToNewestMessage() {
            const chatBoxes = document.querySelectorAll('div.chat-message');
            if (chatBoxes.length < 1) return
            const lastBox = chatBoxes[chatBoxes.length - 1]
            lastBox.scrollIntoView({behavior: "smooth", block: "end"})
        },
        closeChatBox() {
            this.content_block.classList.toggle("closed")
            this.comment_block.classList.add("closed")
            this.conformity_block.classList.add("closed")
        },
        saveComment() {
            if (this.text.length > 0) {
                this.mock_comments.push(this.text)
                this.text = ''
            }
        },
        saveNonConformity() {
            if (this.text.length > 0) {
                this.mock_non_comformities.push(this.text)
                this.text = ''
            }
        },
        showComments() {
            this.comment_block.classList.remove("closed")
            this.conformity_block.classList.add("closed")
            this.currentBlock = 'COMMENT'
        },
        showConformities() {
            this.comment_block.classList.add("closed")
            this.conformity_block.classList.remove("closed")
            this.currentBlock = 'CONFORMITY'
        },
        initBlock() {
            this.content_block = document.querySelector('div.wizard-comment-block')
            this.conformity_block = document.querySelector('div.conformity-block')
            this.comment_block = document.querySelector('div.comment-block')
        },
        isComment() {
            return !this.comment_block?.classList.contains("closed")
        },
        isConformity() {
            return !this.conformity_block?.classList.contains("closed")
        },
        getCurrentBlock(type) {
            if (this.isComment()) {
                this.currentBlock = 'COMMENT'
            }
            if (this.isConformity()) {
                this.currentBlock = 'CONFORMITY'
            }

            if (type === this.currentBlock) {
                return 'n-table-toggle selected'
            }
            return 'n-table-toggle'
        },
    },
    created() {
    }
    ,
    mounted() {
        this.initBlock()
        this.scrollToNewestMessage()
    }
    ,
    template: `
        <div class="wizard-comment-block closed" >
            <div class="comment-close-icon" @click="closeChatBox()" >
                <i class="fa-sharp fa-xmark"></i> 
            </div>  
            <h3>[[[title]]]</h3>
            <div  class="n-flex gap-m" style="margin-top: 1rem; margin-bottom: .25rem"  :key="currentTab">
                <div :class="getCurrentBlock('COMMENT')" @click="showComments()">
                    <h4>Comment</h4>
                </div>
                <div :class="getCurrentBlock('CONFORMITY')" @click="showConformities()">
                    <h4>Non-conformities</h4>
                </div>
            </div>
            <div class="comment-block closed">
                <div class="chat-box" >
                    <div v-for="comment, index in mock_comments" class="n-flex chat-message" :class=" (index % 2) === 0  ? ' left' : ' right'">
                    [[[comment]]]
                    </div>      
                </div>
                <div class="n-flex n-col align-end gap-m" style="height: 200px" >
                    <n-textarea-input help-text="Write a  comment..." v-model="text"></n-textarea-input>
                    <n-button v-if="current_device ==='DESKTOP'" icon-right="fa-sharp fa-light fa-paper-plane-top" text="Save" @click="saveComment()" small></n-button>
                    <n-button v-if="current_device !=='DESKTOP'" style="max-width: 450px; margin:0 auto " full-width text="Save" @click="saveComment()"></n-button>
                </div>
            </div>
            <div class="n-flex n-col gap-s conformity-block closed">
                <div class="n-flex n-col chat-box" >
                    <div v-for="comment, index in mock_non_comformities" class="n-flex chat-message" :class=" (index % 2) === 0  ? ' left' : ' right'">
                    [[[comment]]]
                    </div>      
                </div>
                <div class="n-flex n-col align-end gap-m"  style="height: 200px">
                    <n-textarea-input help-text="Write a non-conformity..." v-model="text"></n-textarea-input>
                    <n-button v-if="current_device ==='DESKTOP'" icon-right="fa-sharp fa-light fa-paper-plane-top" text="Save" @click="saveNonConformity()" small></n-button>
                    <n-button v-if="current_device !=='DESKTOP'" style="max-width: 450px; margin:0 auto " full-width text="Save" @click="saveNonConformity()"></n-button>
                </div>
            </div>
            <n-icon-button small outlined  @click="closeChatBox()" icon-class="fa-sharp fa-xmark" class="comment-close-icon-button"></n-icon-button>
        </div>
        `,
}
