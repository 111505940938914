import NButton from "./buttons/n-button.js";

export default {
    components: {
        "n-button": NButton,
    },
    props: {
        maxHeight: {
            type: Number,
            default: 50,
        },
    },
    data() {
        return {
            seeMoreButtonText: gettext('See more'),
            seeLessButtonText: gettext('See less'),
            expanded: false,
            isOverflowing: false,
        };
    },
    methods: {
        checkOverflow() {
            const textContainer = this.$refs.textContainer;
            if (textContainer) {
                this.isOverflowing = textContainer.scrollHeight > this.maxHeight;
            }
        },
        toggleExpand() {
            this.expanded = !this.expanded;
        },
        getContentContainerClass() {
            let containerClass = 'show-more-content-container'

            if (this.isOverflowing) {
                containerClass += ' overflowing'
            }
            if (this.expanded) {
                containerClass += ' show-more'
            }
            return containerClass
        }
    },
    mounted() {
        setTimeout(() => {
            this.checkOverflow();
        }, 0)
    },
    template: `
<div class="show-more-container">
    <div
      ref="textContainer"
      :class="getContentContainerClass()"
      :style="{ height: expanded ? 'auto' : maxHeight + 'px', }"
    >
      <slot></slot>
    </div>
    <n-button v-if="isOverflowing" outlined tiny :text="expanded ? seeLessButtonText: seeMoreButtonText" @click="toggleExpand"></n-button>
  </div>
    `,
}
