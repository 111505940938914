export default {
    data() {
        return {
            contentWidth: '100%',
        };
    },
    methods: {
        toggleDropdown() {
            this.$refs.el.classList.toggle('open')
        },
    },
    created() {
    },
    mounted() {
    },
    template: `
    <div class="n-accordion" ref="el">
        <div @click="toggleDropdown" class="n-accordion-trigger">
          <slot name="trigger"></slot>
          <i class="fas fa-chevron-down" style="position: absolute; right: 4px; top: 4px;"></i>
        </div>
        <div class="n-accordion-content" :style="{ width: contentWidth}">
          <slot name="content"></slot>
        </div>
      </div>
    `,
};