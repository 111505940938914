export default {
    props: {
        column: {
            type: Boolean,
            default: false
        },
        wrap: {
            type: Boolean,
            default: false
        },
        justifyContent: String,
        flexGrow: String,
        flexBasis: String, Number,
        flexShrink: Number,
        flexWrap: String,
        flex: String,
        alignItems: String,
        margin: String,
        padding: String,
        width: String,
        height: String,
        maxWidth: String,
        bgColor: String,
        gap: String,
    },
    methods: {
        getContainerClass() {
            let conClass = "n-flex ";

            conClass += (this.column ? "n-col" : "n-row");

            if (this.wrap) conClass += " wrap";
            return conClass
        },
        getStyle() {
            return {
                "justify-content": this.justifyContent || undefined,
                "flex-grow": this.flexGrow || undefined,
                "flex-basis": this.flexBasis || undefined,
                "flex-shrink": this.flexShrink || undefined,
                flex: this.flex || undefined,
                "align-items": this.alignItems || undefined,
                margin: this.margin || undefined,
                padding: this.padding || undefined,
                width: this.width || undefined,
                height: this.height || undefined,
                "max-width": this.maxWidth || undefined,
                "background-color": this.bgColor || undefined,
                gap: this.gap || undefined,
            }
        },
    },
    template: `
        <div :class="getContainerClass()" :style="{...getStyle(), flexBasis: flexBasis, flexGrow: flexGrow}">
          <slot></slot>
        </div>
        `,
}
