export default {
    delimiters: ["[[[", "]]]"],
    props: {
        type: String,
    },
    methods: {
        getStyle() {
            return this.types[this.type]
        },
    },
    created() {
        this.types = {
            "column": "display:flex; flex-direction: column; flex-wrap: wrap",
            "row": "display:flex; flex-direction: row; flex-wrap: wrap",
        }
    },
    template: `<div :style="getStyle()"><slot></slot></div>`,
};