// Vue3DatePicker docs
//
// CURRENTLY USED PROPS
// placeholder ->> https://vue3datepicker.com/api/props/#placeholder
// modelType --> https://vue3datepicker.com/api/props/#modeltype
// cancelText --> https://vue3datepicker.com/api/props/#canceltext
// selectText --> https://vue3datepicker.com/api/props/#selecttext
// textInputOptions --> https://vue3datepicker.com/api/props/#textinputoptions
// disabled --> https://vue3datepicker.com/api/props/#disabled
// inputClassName --> https://vue3datepicker.com/api/props/#inputclassname
// state --> https://vue3datepicker.com/api/props/#state
// autoApply --> https://vue3datepicker.com/api/props/#autoapply
// closeOnAutoApply --> https://vue3datepicker.com/api/props/#closeonautoapply
// textInput --> https://vue3datepicker.com/api/props/#textinput
// locale --> https://vue3datepicker.com/api/props/#locale
// enableTimePicker --> https://vue3datepicker.com/api/props/#enabletimepicker
// @update:modelValue --> https://vue3datepicker.com/api/events/#update-modelvalue


import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    CHANGE: 'change',
}

export default {
    inject: ['errors', 'current_device'],
    components: {
        'n-date-picker': VueDatePicker,
        'n-tooltip': NTooltip
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        optional: Boolean,
        hideTooltip: Boolean,
        range: Boolean,
        filterField: String,
        filterOn: String,
        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        placeholder: String,
        proposedDate: String,
        fullWidth: Boolean,
        inline: Boolean,
        //VueDatepickerProps

        autoApply:
            {
                type: Boolean,
                default: true
            },
        allowTextInput:
            {
                type: Boolean,
                default: true
            },
    },
    data() {
        return {
            value: null,
            fromValue: null,
            toValue: null,
            isValid: null,
            error: null,
            //Vue3DatePicker data
            inputClass: null,
            cancelText: gettext('Cancel'),
            selectText: gettext('Select'),
            fromText: gettext('From'),
            toText: gettext('To'),
            textInputOptions: {
                enterSubmit: true,
                tabSubmit: true,
                openMenu: true,
                format: (val) => this.onInput(val),
            },
            proposedDateText: gettext('Apply date'),
            validationState: null, // set to false on error, reset to null when handled
            optionalText: gettext('Optional')

        }
    },
    methods: {
        propagateValue(value) {

            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setDateParam(this.filterField, value)
            }
            this.$nextTick(() => {
                this.propagateValue(value)
                this.$emit(EVENTS.CHANGE, value)
                this.resetError()
            })
        },
        onChangeFrom(value) {
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setFromDateParam(this.filterField, value)
            }
            this.$nextTick(() => {
                this.propagateValue(value)
                this.fromValue = value
                this.$emit(EVENTS.CHANGE, value)
                this.resetError()
            })
        },
        onChangeTo(value) {
            if (this.filterOn) {
                pHandler.getOrRegisterParamBuilder(this.filterOn).setToDateParam(this.filterField, value)
            }
            this.$nextTick(() => {
                this.propagateValue(value)
                this.toValue = value
                this.$emit(EVENTS.CHANGE, value)
                this.resetError()
            })
        },
        onInput(value) {
            if (this.isValidDateString(value)) {
                this.resetError()
                return moment(value).toDate()
            }
            this.makeInvalid("Invalid date")
            return moment(this.value).toDate()
        },
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'datepicker'
        },
        resetError() {
            this.error = ''
            this.inputClass = 'datepicker'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        },
        applyProposedDate() {
            if (this.isValidDateString(this.proposedDate)) {
                //just in case guard
                this.resetError()
                this.propagateValue(this.proposedDate)
                return
            }
            this.makeInvalid("Invalid date")
        },
        isValidDateString(date) {
            const isStrict = true
            return moment(date, [Constants.DATE_FORMAT, 'YYYYMMDD'], isStrict).isValid()
        }, getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }
            if (this.range) {
                containerClass += ' range'
            }

            return containerClass
        },
        handleOpenPortal() {
            const modal = document.getElementsByClassName('n-modal-body')
            const modalContent = document.getElementsByClassName('modal-content')
            if (modal.length > 0) {
                modal[0].style.overflowY = 'unset'
            }
            if (modalContent.length > 0) {
                modalContent[0].style.overflow = 'unset'
            }
        },
        handleClosePortal() {
            const modal = document.getElementsByClassName('n-modal-body')
            const modalContent = document.getElementsByClassName('modal-content')
            if (modal.length > 0) {
                modal[0].style.overflowY = 'auto'
            }
            if (modalContent.length > 0) {
                modalContent[0].style.overflow = 'auto'
            }
        }
    },
    created() {
        this.initStyle()

        if (this.filterOn) {
            pHandler.registerParamBuilder(this.filterOn)
        }

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            let value = moment(new Date(this.initial)).format(Constants.DATE_FORMAT);
            this.propagateValue(value)
            return
        }
        if (this.modelValue)
            this.value = this.modelValue
    },
    mounted() {
        if (this.filterOn) {
            const params = pHandler.getOrRegisterParamBuilder(this.filterOn)
            if (this.range) {
                this.toValue = params.getToDateParam(this.filterField)
                this.fromValue = params.getFromDateParam(this.filterField)
                return
            }
            this.value = params.getDateParam()
        }
    },
    template: `
    <div :class="getContainerClass()" :key="modelValue">
        <div class="n-flex gap-m align-center" style="justify-content: space-between">
            <label :for="id" :class="labelClass">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
            <div v-if="proposedDate" class="proposed-date-container" @click="applyProposedDate()">
                [[[proposedDateText]]]: <p>[[[proposedDate]]]</p>
            </div>
            <n-tooltip v-if="!hideTooltip"  :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
         <n-date-picker  
            v-if="!range"
            v-model="value" 
            :uid="id" 
            :name="name" 
            format="yyyy-MM-dd" 

            :placeholder="placeholder || 'yyyy-mm-dd'"
            modelType="format"
            :cancelText="cancelText"
            :selectText="selectText"
            :textInputOptions="textInputOptions"
            :disabled="disabled"
            :inputClassName="inputClass"
            :state="validationState"
            :autoApply="autoApply"
            :textInput="current_device !== 'MOBILE'" 
            :closeOnAutoApply="true"
            :inline="inline"
            locale="sv" 
            :enableTimePicker="false" 
            @update:modelValue="onChange(value)" 
            @closed="handleClosePortal"
            @open="handleOpenPortal"    
            >
                <template #input-icon>
                    <i class="fa fa-calendar" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>
        <div v-if="range" style="display: flex; gap:.25rem; align-items: center">
            <n-date-picker 
            v-model="fromValue" 
            :uid="id" 
            :name="name" 
            format="yyyy-MM-dd" 
            :placeholder="fromText"
            modelType="format"
            :cancelText="cancelText"
            :selectText="selectText"
            :textInputOptions="textInputOptions"
            :disabled="disabled"
            :inputClassName="inputClass"
            :state="validationState"
            :autoApply="autoApply"
            :textInput="current_device !== 'MOBILE'" 
            :closeOnAutoApply="true"
            :inline="inline"
            locale="sv" 
            :enableTimePicker="false" 
            @update:modelValue="onChangeFrom(fromValue)" 
            >
                <template #input-icon>
                    <i class="fa fa-calendar" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>
            <div class="range-divider"></div>
            <n-date-picker 
            v-model="toValue" 
            :uid="id" 
            :name="name" 
            format="yyyy-MM-dd" 
            :placeholder="toText"
            modelType="format"
            :cancelText="cancelText"
            :selectText="selectText"
            :textInputOptions="textInputOptions"
            :disabled="disabled"
            :inputClassName="inputClass"
            :state="validationState"
            :autoApply="autoApply"
            :textInput="current_device !== 'MOBILE'" 
            :closeOnAutoApply="true"
            :inline="inline"
            locale="sv" 
            :enableTimePicker="false" 
            @update:modelValue="onChangeTo(toValue)" 
            >
                <template #input-icon>
                    <i class="fa fa-calendar" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>
        </div>
        <div v-if="error" class="invalid-field-error" >
        [[[ error ]]]
        </div>
    </div>
    `,
}