const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    SAVE: 'inlineSave',
    CHANGE: 'change'
}

export default {
    inject: ["errors"],
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        optional: Boolean,

        id: String,
        name: String,
        label: String,
        initial: String,
        placeholder: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        hideLabel: Boolean,
        fullWidth: Boolean,

        choices: Array,  // of objects with text and value
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            value: null,
            error: null,
            labelClass: null,
            showSave: false,
            initialValue: null,
            inputClass: null,
            optionalText: gettext('Optional'),
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            if (this.error) {
                this.initStyle()
                this.error = false
            }
            this.$emit(EVENTS.CHANGE, value)
        },
        initStyle() {
            this.labelClass = 'field-title'
        },
        makeInvalid(error) {
            this.error = error
            this.selectClass = 'select error'
        },
        getInputSelectClass() {
            let selectClass = 'select inline'

            if (this.disabled) {
                selectClass += ' disabled'
            }

            return selectClass
        },
        save() {
            this.showSave = false
            const event = {name: this.name, value: this.value, oldValue: this.initialValue}
            this.initialValue = this.value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.value)
            this.$emit(EVENTS.SAVE, event)
            this.$refs.inputRef.blur()
        },
        triggerEnable() {
            this.showSave = true
        },
        triggerDisable() {
            this.propagateValue(this.initialValue)
            this.showSave = false
        },
        onClickEdit() {
            this.$refs.inputRef.focus()
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            containerClass += ' inline'

            return containerClass
        },
        getSaveButtonClass() {
            let saveClass = 'n-button save '

            if (this.disabled) {
                saveClass += ' disabled'
            }

            return saveClass
        },
        getEditButtonClass() {
            let editClass = 'n-button outlined edit '

            if (this.disabled) {
                editClass += ' disabled'
            }

            return editClass
        },
    },
    created() {
        this.initStyle()

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            this.initialValue = this.initial
            this.propagateValue(this.initial)
            return
        }
        if (this.modelValue) {
            this.initialValue = this.modelValue
        }
        this.value = this.modelValue

        if (!this.value && this.choices?.length > 0)
            this.propagateValue(this.choices[0].value)
    },
    template: `
    <div :class="getContainerClass()">
        <label v-if="!hideLabel" :for="id" :class="labelClass">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
        <div class="inline-edit" >
            <select :name="name" :class="getInputSelectClass()"
                    :disabled="disabled"
                    @input="propagateValue($event.target.value)" 
                    @change="onChange($event.target.value)"
                    ref="inputRef"
                    @focus="triggerEnable" 
                    @click.stop="" 
                    @blur="triggerDisable"
                    style="border-right: 0"
                    >
                <option v-if="placeholder" value="" disabled selected hidden>[[[ placeholder ]]]</option>
                <template v-for="choice in choices">
                    <option v-if="choice.value===value" selected :value="choice.value">[[[ choice.text ]]]</option>
                    <option v-else :value="choice.value">[[[ choice.text ]]]</option>
                </template>
            </select>
            <button :class="getEditButtonClass()" v-show="!showSave"  @click.stop="onClickEdit" ><i class="fa fa-pen"></i></button>
            <!--  @mousedown.prevent prevents mouseDown to fire the onBlur method on input before save method is invoked -->
            <button :class="getSaveButtonClass()" v-show="showSave" @mousedown.prevent @click.stop="save">Save</button >
        </div>
        <div v-if="error" class="invalid-field-error inline">
        [[[ error ]]]
        </div>
    </div>

    `,
}