import NButton from "./n-button.js";
import NBtnGroup from "./n-button-group.js";
import NSeverityToggle from "./n-severity-toggle.js";
import NIconButton from "./n-icon-button.js";
import NButtonDropdown from "./n-button-dropdown.js";
import NIconButtonDropdown from "./n-icon-button-dropdown.js";

export default {
    "n-button": NButton,
    "n-button-dropdown": NButtonDropdown,
    "n-icon-button-dropdown": NIconButtonDropdown,
    "n-icon-button": NIconButton,
    "n-btn-group": NBtnGroup,
    "n-severity-toggle": NSeverityToggle,
};
