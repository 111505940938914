import NIconButton from "../buttons/n-icon-button.js";
import NFlex from "../styling/n-flex.js";
import InspectionPlanningWeek from "./inspection-planning-week.js";
import InspectionPlanningWeekdays from "./inspection-planning-weekdays.js";

class EVENTS {
}

EVENTS.UPDATE_START_DATE = 'update-start-date'


export default {
    components: {
        'n-icon-button': NIconButton,
        'n-flex': NFlex,
        "inspection-planning-week": InspectionPlanningWeek,
        "inspection-planning-weekdays": InspectionPlanningWeekdays
    },
    emits: Object.values(EVENTS),
    props: {
        calendarColumns: Array,
        calendarType: Number,
    },
    data() {
        return {
            CALENDAR_TYPES: CALENDAR_TYPES,
            startDate: moment(new Date())
        };
    },
    methods: {
        initCalendar() {
        },
        getInspectors() {
            return this.calendarColumns.filter(item => item.name !== null)
        },
        navigateDown() {
            let newDate = moment(new Date(this.getStartDate()))

            switch (this.calendarType) {
                case CALENDAR_TYPES.WEEK:
                    newDate = newDate.add(ONE_WEEK, 'w')
                    this.startDate = newDate
                    break;
                case CALENDAR_TYPES.WEEK_DAYS:
                    newDate = newDate.day("Monday").add(ONE_WEEK, 'w')
                    this.startDate = newDate
                    break;
                default:
                    break;
            }

            this.$emit(EVENTS.UPDATE_START_DATE, this.startDate)
        },
        navigateUp() {
            let newDate = moment(new Date(this.getStartDate()))

            switch (this.calendarType) {
                case CALENDAR_TYPES.WEEK:
                    newDate = newDate.subtract(ONE_WEEK, 'w')
                    this.startDate = newDate
                    break;
                case CALENDAR_TYPES.WEEK_DAYS:
                    newDate = newDate.day("Monday").subtract(ONE_WEEK, 'w')
                    this.startDate = newDate
                    break;
                default:
                    break;
            }

            this.$emit(EVENTS.UPDATE_START_DATE, this.startDate)
        },
        onAssign(data) {//data: {from_date: String, to_date: String, to_inspector_id: String, from_inspector_id: String, inspection_id: String}
            console.log("Assign: ", data)
        },
        onUnassign(data) {//data: {from_date: String, to_date: String, to_inspector_id: null , from_inspector_id: String, inspection_id: String}
            console.log("Unassign: ", data)
        },
        getStartDate() {
            return this.startDate.format(Constants.DATE_FORMAT)
        },
        stringToColour(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            let colour = '#';
            for (let i = 0; i < 3; i++) {
                let value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
        }
    },
    created() {
        this.initCalendar()
    },

    template: `
    <n-flex column height="100%">
        <table class="planning-table">
            <thead>
            <tr class="planning-table-tr-header">
                <th class="planning-table-th-week">
                    <n-icon-button 
                        small 
                        color="transparent" 
                        outlined icon-class="fas fa-chevron-up"
                        @click="navigateUp">
                    </n-icon-button>
                    <p class="calendar-header-text">Inspectors</p>
                    <n-icon-button  
                        small 
                        color="transparent" 
                        outlined icon-class="fas fa-chevron-down"
                        @click="navigateDown">
                    </n-icon-button>
                </th>
                <template v-for="inspector in getInspectors()">
                    <th v-if="inspector.id !== null" class="planning-table-th-inspector ">
                    <div class="inspector-marker" :style="{backgroundColor: stringToColour(inspector.name)}"></div>
                        <p class="calendar-header-text">[[[inspector.name]]]</p>
                    </th>        
                </template>
                <th class="planning-table-th-week">
                    <p class="calendar-header-text">Unassigned</p>
                </th>
            </tr>
            </thead>
            <tbody style="display:flex; height: 100%; flex-direction: column">
            
            <!-- TYPES: -->
            
            <!-- WEEK: -->
            <inspection-planning-week
            v-if="calendarType === CALENDAR_TYPES.WEEK"
            @on-assign="onAssign"
            @on-unassign="onUnassign"
            :start-date="startDate.toString()" 
            :columns="calendarColumns"
            ></inspection-planning-week>    
            <!-- WEEKDAYS: -->
            <inspection-planning-weekdays
            v-if="calendarType === CALENDAR_TYPES.WEEK_DAYS"
            @on-assign="onAssign"
            @on-unassign="onUnassign"
            :start-date="startDate.toString()" 
            :inspectors="calendarColumns"
            ></inspection-planning-weekdays>
            </tbody>
        </table>
    </n-flex>
    `,
};
