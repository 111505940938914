class EVENTS {
}

EVENTS.NAVIGATE_MODAL = 'navigate-modal'

const SMALL_BREAKPOINT = 5
export default {
    emits: Object.values(EVENTS),
    props: {
        currentStep: Number,
        stepCount: Number
    },
    data() {
        return {
            breakpoint: SMALL_BREAKPOINT
        }
    },
    methods: {
        navigateToModalStep(step) {
            this.$emit(EVENTS.NAVIGATE_MODAL, step)
        },
        getStepClass(step) {
            let stepClass = 'modal-step'

            if (this.currentStep === step) {
                stepClass += ' current'
            }

            if (this.stepCount > this.breakpoint) {
                stepClass += ' small'
            }
            return stepClass
        }
    },
    created() {

    },
    template:
        `
        <div class="modal-stepper">
            <div class="modal-step-container" v-for="step in stepCount">
                <div :class="getStepClass(step)" @click="navigateToModalStep(step)">              
                    <p>[[[step]]]</p>
                </div>
                <template v-if="stepCount < breakpoint">
                    <div v-if="step !== stepCount " class="modal-step-line"></div>
                </template>
            </div>
        </div>
        `,
}