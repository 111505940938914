import NFlex from "../styling/n-flex.js";
import NBlock from "../styling/n-block.js";
import NIconButton from "../buttons/n-icon-button.js";

const EVENTS = {
    CHANGE: 'change'
}


export default {
    emits: ['change'],
    components: {
        "n-flex": NFlex,
        "n-block": NBlock,
        "n-icon-button": NIconButton
    },
    props: {
        id: String,
        variant: String,
        isPaddingCell: Boolean,
        isCurrentDay: Boolean,

        title: String,
        day: String,
        week: String,
        month: String,

        // Array of things to be shown in the cell
        items: Array
    },
    data() {
        return {
            isSelected: null
        }
    },
    methods: {
        initCell() {
            this.currentDate = new Date()
            this.selectedDate = this.currentDate


            this.loadMonth(this.currentDate)
            this.$emit(EVENTS.CHANGE, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1))

        },
        getItemClass(state) {
            switch (state) {
                case "Skapad":
                    return "n-calendar-cell-item";
                case "Klar":
                    return "n-calendar-cell-item approved";
                default:
                    return "n-calendar-cell-item";
            }
        },
        getLocaleTime(date) {
            const inspectionDate = new Date(date)
            return inspectionDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
        },
        selectCell(ev){
            console.log(ev)
            console.log(ev.target)
            console.log(ev.target.tagName)
        },
        getCellClass(){
            return isPaddingCell ? "n-calendar-cell-month ": "n-calendar-cell-month"
        }
    },
    created() {
        this.currentVariant = "month"
        this.initCalendar()

    },
    template:
        `
            <n-flex gap="2rem" justify-content="center" align-items="center">
                <n-flex gap="1rem" width="300px" justify-content="flex-end" align-items="center">
                    <h1 style="text-transform: capitalize">[[[ getCurrentMonth() ]]] [[[ getCurrentYear() ]]]</h1>
                    <n-flex gap="1rem" width="max-content">
                        <n-icon-button small color="sand" @click="decreaseMonth()" icon-class="fas fa-chevron-left"></n-icon-button>
                        <n-icon-button small color="sand" @click="increaseMonth()" icon-class="fas fa-chevron-right"></n-icon-button>
                    </n-flex>
                </n-flex>
            </n-flex>
            <n-flex wrap bg-color="#d7d0c8" max-width="100%" gap="2px" padding="1px">
            <!--  Padding Before -->
                <template v-if="hasPaddingDaysBefore()">
                    <div class="n-calendar-cell-month padding" v-for="n in paddingDaysBefore "  >
                        <span class="n-calendar-cell-title" v-if="!(n > weekdays.length)"> [[[ weekdays[n - 1] ]]]  </span>
                        <template  v-if="n === 1" >
                            <p class="n-calendar-cell-week-marker">[[[getWeek()]]] </p> <!--  Calculate week function  -->
                            <p class="n-calendar-cell-month-marker">[[[getPreviousMonth()]]] </p>
                        </template>
                        <span :class="getCurrentDayClass(getPreviousMonthLastDay(n),true)">
                            <h4 >[[[getPreviousMonthLastDay(n)]]]</h4>
                        </span>
                    </div>            
                </template>
                 <!--  Current Month -->
                <div class="n-calendar-cell-month" @click.self="selectCell" v-for="days in daysInCurrentMonth"  >
                    <span class="n-calendar-cell-title" v-if="!(days > weekdays.length)">[[[ weekdays[days + paddingDaysBefore - 1] ]]]</span>
                    <p v-if="(days + paddingDaysBefore) % 7=== 1" class="n-calendar-cell-week-marker">[[[getWeek()]]]</p>
                    <span :class="getCurrentDayClass(days,false)">
                        <h4 >[[[days]]]</h4> 
                    </span>
                    <div class="n-calendar-cell-item-list">
                    <template v-for="(inspection, index) in monthDateDict[days]"  >
                    
                        <div :class="getItemClass(inspection.state)" v-if="index < 2">
                            <p>[[[getLocaleTime(inspection.date)]]]</p>
                        </div>                    
                    </template>
                    </div>
                </div>
                 <!--  Padding After -->
                <template v-if="hasPaddingDaysAfter()">
                    <div class="n-calendar-cell-month padding" v-for="n in paddingDaysAfter "  >
                        <template  v-if="n === 1" >
                            <p class="n-calendar-cell-month-marker">[[[getNextMonth()]]]</p>
                        </template>
                        <span :class="getCurrentDayClass(getPreviousMonthLastDay(n),true)">
                            <h4 >[[[n]]]</h4>
                        </span>
                    </div>            
                </template>
            </n-flex>

        `,
}