import PlanningItem from "./planning-item.js";


class EVENTS {
}

EVENTS.DRAG_ASSIGN = 'on-assign'
EVENTS.DRAG_UNASSIGN = 'on-unassign'
EVENTS.RELOAD_CALENDAR = 'reload'

export default {
    components: {
        'planning-item': PlanningItem,
    },
    emits: Object.values(EVENTS),
    props: {
        startDate: String,
        inspectors: Array,
        isExpanded: Boolean,
    },
    data() {
        return {
            assignedInspections: [],
            unassignedInspections: [],
        };
    },
    methods: {
        updateDays(startW) {
            let weekList = []

            const startDay = moment(new Date(startW)).day("Monday")
            weekList.push({date: startDay.format(Constants.DATE_FORMAT)})

            for (let i = 1; i < TOTAL_DAYS; ++i) {
                const date = startDay.add(ONE_WEEK, 'd').format(Constants.DATE_FORMAT);

                weekList.push({date: date})
            }
            return weekList
        },
        getInspectors() {
            return this.inspectors
        },
        getDays(startDate) {
            return this.updateDays(startDate)
        },
        getMonth(date) {
            return getMonth(date)
        },
        getYear(date) {
            return getYear(date)
        },
        getWeek(date) {
            return getWeek(date)
        },
        isCurrentWeek(date) {
            return isCurrentWeek(date)
        },
        isFirstOfMonth(date) {
            return isFirstOfMonth(date)
        },
        getDay(date) {
            return getDay(date)
        },
        getDate(date) {
            return getDate(date)
        },
        isCurrentDay(date) {
            return isCurrentDay(date)
        },
        getDayTagClass(date) {
            return isCurrentDay(date) ? 'day-tag current' : 'day-tag'
        },
        getWeekTagClass(date) {
            return isCurrentDay(date) ? 'week-tag current' : 'week-tag'
        },
        getInspectorTDClass(date) {
            let className = 'planning-table-td-inspector draggable-day'

            if (isCurrentDay(date)) {
                className += ' current-date'
            }

            return className
        },
        getUnassignedTDClass(date) {
            let className = 'planning-table-td-unassigned draggable-day'

            if (isCurrentDay(date)) {
                className += ' current-date'
            }

            return className
        },
        getDayTDClass(date) {
            let className = 'planning-table-td-day'

            if (isCurrentDay(date)) {
                className += ' current-date'
            }

            return className
        },
        getInspectionsOfDay(date, work_orders) {
            return work_orders.filter(work_order => date === work_order.date)
        },
        onDragAndAddToInspector(event) {
            const {planningInspectorId: toId, planningDate: toDate} = event.to.dataset
            const {planningInspectorId: fromId, planningDate: fromDate} = event.from.dataset

            let to_inspector = toId;
            let to_date = toDate;

            let from_inspector = fromId;
            let from_date = fromDate;

            this.$emit(EVENTS.DRAG_ASSIGN, {
                from_date: from_date,
                to_date: to_date,
                to_inspector_id: to_inspector,
                from_inspector_id: from_inspector,
                inspection_id: event.items.length > 0 ? event.items.map(item => item.id) : event.item.id
            })
        },
        initDraggableAreas() {
            const draggableList = document.querySelectorAll("td.draggable-day")
            draggableList.forEach((item, index) => {
                item.setAttribute('id', index.toString());
                const inspectorGroups = new Sortable.create(item,
                    {
                        group: //Groups need to match between unassigned & inspectors
                            {name: `day-container`},
                        // animation: 150,
                        onAdd: this.onDragAndAddToInspector,
                    });

            })
        }
    },
    created() {
        this.unassignedInspections = this.inspectors.filter((inspector) => inspector.id === null)[0]
        this.assignedInspections = this.inspectors.filter((inspector) => inspector.id !== null)

    },
    mounted() {
        this.initDraggableAreas()
    },
    template: `
        <tr v-for="(day, index) in getDays(startDate)" class="planning-table-tr">
            <td :class="getDayTDClass(day.date)">
                <p :class="getDayTagClass(day.date)"> [[[getDay(day.date)]]]</p>
                <div v-if="index === 0 || isFirstOfMonth(day.date)" class="small-month-tag"><strong>[[[getMonth(day.date)]]], [[[getYear(day.date)]]]</strong></div>
                <div v-if="isCurrentDay(day.date)" class="current-week-marker">
                    <p :class="getWeekTagClass(day.date)">[[[getDate(day.date)]]]</p>
                </div>
                <template v-else>
                     <p :class="getWeekTagClass(day.date)">[[[getDate(day.date)]]]</p>
                </template>
                <p v-if="index === 0" class="week-tag" style="position:absolute; bottom:.5rem; right: .5rem"> v.[[[getWeek(day.date)]]]</p>
            </td>
            <!--       Inspectors     -->
            <td :class="getInspectorTDClass(day.date)" 
                v-for="inspector in assignedInspections"
                :data-planning-inspector-id="inspector.id"
                :data-planning-date="day.date">
                <template v-for="inspection in getInspectionsOfDay(day.date, inspector.inspections)">
                    <planning-item 
                    :id="inspection.id" 
                    :state="inspection.state" 
                    :name="inspection.name">
                    </planning-item>
                </template>
            </td>
            <!--       unassigned     -->
            <td :class="getUnassignedTDClass(day.date)" 
                :data-planning-date="day.date">
                <template v-for="inspection in getInspectionsOfDay(day.date, unassignedInspections.inspections)">
                    <planning-item 
                    :id="inspection.id" 
                    :state="inspection.state" 
                    :name="inspection.name">
                    </planning-item>
                </template>
            </td>
        </tr>
    `,
};