import NButton from "../buttons/n-button.js";

const EVENTS = {
    APPROVE: 'on-approve',
    REJECT: 'on-reject',
    SAVE: 'on-save',
}

export default {
    components: {
        'n-button': NButton,
    },
    props: {
        item: null,
        isWaiting: Boolean
    },
    data() {
        return {
            approveText: gettext('Approve'),
            rejectText: gettext('Reject'),
            focusField: '',
        }
    },
    emits: Object.values(EVENTS),
    methods: {

        approve(fieldName) {
            this.saveFieldResult(fieldName, FIELD_CONTROL_POINT_RESULT.APPROVED)
        },
        reject(fieldName) {
            this.saveFieldResult(fieldName, FIELD_CONTROL_POINT_RESULT.REJECTED)
        },
        saveFieldResult(fieldName, result) {
            let data = {
                'field_name': fieldName,
                'result': result,
            }
            this.$emit(EVENTS.SAVE, data)
        },
        toggleContent(element, field = null) {
            const items = document.getElementsByClassName('conformity-item-header ')

            for (let item of items) {
                if (item.classList.contains('open')) {
                    const openedContent = item.nextElementSibling;
                    openedContent.style.maxHeight = null;
                    if (item === element) {
                        element.classList.remove('open')
                        return
                    }
                }
                item.classList.remove('open')
            }
            element.classList.toggle('open')

            const PADDING = 64

            const content = element.nextElementSibling;
            if (field) {
                this.focusField = field
                pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CUSTOM).setCustomParam('field', this.focusField)
            }

            if (content.style.maxHeight) {
                content.style.maxHeight = null;
                return
            }

            content.style.maxHeight = `${content.scrollHeight + PADDING}px`;
            content.style.height = `max-content`;
        },
        getFocusFieldFromURL() {
            let field = pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CUSTOM).getCustomParam('field')
            if (field) {
                return field
            }
            let url = new URL(window.location)
            field = url.searchParams.get('field')
            pHandler.clearInitial()
            return field || null
        },
        handleFocusField() {
            if (this.focusField) {
                const element = document.getElementsByClassName(this.focusField)[0]
                if (element) {
                    this.toggleContent(element, this.focusField)
                }
            }
        },
        initComponent() {
            if (this?.item?.name === this.focusField) {
                this.handleFocusField()
                pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CUSTOM).setCustomParam('field', this.focusField)
            }
        },
    },
    created() {
        pHandler.registerParamBuilder(NORSE_FILTERS.CUSTOM);
    },
    mounted() {
        this.focusField = this.getFocusFieldFromURL()
        this.initComponent()
    },
    template: `
        <div class="conformity-item">
            <div class="conformity-item-header" :class="item.name" @click="toggleContent($event.target, item.name)">
            <h4>[[[item.label]]]</h4>
                <i class="fas fa-chevron-down"></i>
            </div>
            <div class="conformity-item-content">
                <div class="n-flex n-col gap-m padding-m">
                    <div class="conformity-chat-box">
                        <div class="non-conf-comment">
                            <div class="n-flex align-center gap-s">
                            <h4>[[[item.user]]]</h4>
                            <p style="font-size: 11px;">[[[item.modified]]]</p>
                            </div>
                            <p>[[[item.description]]]</p>
                        </div>
                        <div v-for="conversationItem, index in item.conversation" class="non-conf-comment"
                        :class="index % 2 === 1 ? 'comment': 'recipient'">
                            <div class="n-flex align-center gap-s comment-meta">
                                <h4>[[[conversationItem.user]]]</h4>
                                <p style="font-size: 11px;">[[[conversationItem.modified]]]</p>
                            </div>
                            <p>[[[conversationItem.comment]]]</p>
                        </div>
                    </div>
                    <slot name="comment-form"></slot>
                    <div v-if="!isWaiting" class="n-flex gap-m" style="margin-top: 1rem">
                        <n-button @click="approve(item.name)"
                        full-width small :text="approveText"
                        color="approved"></n-button>
                        <n-button @click="reject(item.name)"
                        full-width small :text="rejectText"
                        color="rejected"></n-button>
                    </div>
                </div>
            </div>
        </div>
    `,
}
