import NFlex from "./styling/n-flex.js";

class EVENTS {
}

EVENTS.REMOVE_TOAST = 'handle-remove'

export default {
    inject: ["current_device"],
    components: {
        'n-flex': NFlex
    },
    emits: Object.values(EVENTS),
    props: {
        status: String,
        message: String,
    },
    data() {
        return {
            toastClass: 'n-toast'
        }
    },
    methods: {
        initToast() {
            if (this.breakpoint !== 'DESKTOP') {
                this.toastClass = 'n-toast-mobile'
            }

            let toastStatusClass = ""

            switch (this.status) {
                case 'success':
                    toastStatusClass = ' toast-success'
                    break;
                case 'error':
                    toastStatusClass = ' toast-error'
                    break;
                case 'warning':
                    toastStatusClass = ' toast-warning'
                    break;
                default:
                    break;
            }
            this.toastClass += toastStatusClass

        },
        getToastClass() {
            return this.toastClass
        },
        removeToaster() {
            this.$emit(EVENTS.REMOVE_TOAST)
        }
    },
    created() {
    },
    mounted() {
        this.initToast()
        let dialog = document.getElementsByClassName('n-dialog')[0]
        dialog && dialog.classList.add('custom-backdrop');
    },
    template: `
    <div :class="getToastClass()">
        <n-flex column>
            <h3>[[[status]]]</h3>
            <h4>[[[message]]]</h4>
        </n-flex>
        <span class="close-toast-container" @click="removeToaster()">
            <i class="fa fa-times"></i>
        </span>
    </div>

    `,
}