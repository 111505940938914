import NIconButton from "../buttons/n-icon-button.js";
import PlanningItem from "./planning-item.js";
import InspectionPlanningWeekdays from "./inspection-planning-weekdays.js";

class EVENTS {
}

EVENTS.DRAG_ASSIGN = 'on-assign'
EVENTS.DRAG_UNASSIGN = 'on-unassign'
EVENTS.RELOAD_CALENDAR = 'reload'

export default {
    components: {
        'n-icon-button': NIconButton,
        'planning-item': PlanningItem,
        "inspection-planning-weekdays": InspectionPlanningWeekdays
    },
    emits: Object.values(EVENTS),
    props: {
        startDate: String,
        columns: Array,
    },
    data() {
        return {
            expandedWeek: null,
        };
    },
    methods: {
        updateWeeks(startW) {
            let weekList = []

            const startWeek = moment(new Date(startW))

            weekList.push({date: startWeek.format(Constants.DATE_FORMAT)})


            for (let i = 1; i < FIVE_WEEKS; ++i) {
                const date = startWeek.add(ONE_WEEK, 'w').format(Constants.DATE_FORMAT);

                weekList.push({date: date})
            }

            this.$nextTick(() => this.initDraggableAreas())

            return weekList
        },
        getInspectors(columns) {
            return columns.filter((inspector) => inspector.id !== null)
        },
        getUnassignedInspections(columns) {
            let unassignedColumn = columns.filter((column) => column.id === null)
            if (unassignedColumn.length) {
                return unassignedColumn[0].inspections
            }
            return []
        },
        getWeeks(startDate) {
            return this.updateWeeks(startDate)
        },
        getWeekTagClass(date) {
            let weekTagClass = 'week-tag'

            if (this.isCurrentWeek(date)) {
                weekTagClass += ' current'
            }

            return weekTagClass
        },
        getInspectorTDClass(date) {
            let className = 'planning-table-td-inspector draggable-week'

            if (isCurrentWeek(date)) {
                className += ' current-date'
            }

            return className
        },
        getUnassignedTDClass(date) {
            let className = 'planning-table-td-unassigned draggable-week'

            if (isCurrentWeek(date)) {
                className += ' current-date'
            }

            return className
        },
        getWeekTDClass(date) {
            let className = 'planning-table-td-week'

            if (isCurrentWeek(date)) {
                className += ' current-date'
                if (this.expandedWeek) {
                    className += ' expanded'
                }
            }

            return className
        },
        getInspectionsOfWeek(date, inspections) {

            return inspections.filter(inspection => {

                if (getYear(date) !== getYear(inspection.date)) {
                    return
                }

                if ((getWeek(date) === getWeek(inspection.date))) {
                    return inspection
                }
            })
        },
        getMonth(date) {
            return getMonth(date)
        },
        getYear(date) {
            return getYear(date)
        },
        getWeek(date) {
            return getWeek(date)
        },
        setExpandWeek(date) {
            if (!date) {
                this.expandedWeek = null
                return
            }
            this.expandedWeek = getWeek(date)
        },
        isWeekExpanded(date) {

            if (!isCurrentYear(date))
                return false

            return this.expandedWeek === getWeek(date)
        },
        isCurrentWeek(date) {
            return isCurrentWeek(date)
        },
        onDragAndAddToInspector(event) {
            const {planningInspectorId: toId, planningDate: toDate} = event.to.dataset
            const {planningInspectorId: fromId, planningDate: fromDate} = event.from.dataset

            let to_inspector = toId;
            let to_date = toDate;

            let from_inspector = fromId;
            let from_date = fromDate;

            this.$emit(EVENTS.DRAG_ASSIGN, {
                from_date: from_date,
                to_date: to_date,
                to_inspector_id: to_inspector,
                from_inspector_id: from_inspector,
                inspection_id: event.items.length > 0 ? event.items.map(item => item.id) : event.item.id
            })
        },
        initDraggableAreas() {
            const draggableList = document.querySelectorAll("td.draggable-week")

            draggableList.forEach((item, index) => {
                item.setAttribute('id', index.toString());
                const inspectorGroups = new Sortable.create(item,
                    {
                        multiDrag: true,
                        multiDragKey: 'CTRL',
                        group: //Groups need to match between unassigned & inspectors
                            {name: `week-container`},
                        // animation: 150,
                        onAdd: this.onDragAndAddToInspector,
                        onSelect: (event) => {
                            event.item.classList.toggle("selected") // The selected item
                        },

                        // Called when an item is deselected
                        onDeselect: (event) => {
                            event.item.classList.toggle("selected") // The deselected item
                        }
                    });

            })
        },
        onAssign(data) {//data: {week: string, to_inspector_id: string, inspection_id: string}
            this.$emit(EVENTS.DRAG_ASSIGN, data)
        },
        onUnassign(data) {//data: {week: string, inspection_id: string}
            this.$emit(EVENTS.DRAG_ASSIGN, data)
        },
    },
    template: `
    <template v-for="(week, index) in getWeeks(startDate)"  >
        <tr class="planning-table-tr">
            <td :class="getWeekTDClass(week.date)" >
            <div v-if="!isWeekExpanded(week.date)">
                <div class="small-month-tag"><strong>[[[getMonth(week.date)]]], [[[getYear(week.date)]]]</strong></div>
                <div v-if="isCurrentWeek(week.date)" class="current-week-marker">
                    <p :class="getWeekTagClass(week.date)"> v.[[[getWeek(week.date)]]]</p>
                </div>
                <p v-else :class="getWeekTagClass()"> v.[[[getWeek(week.date)]]]</p>
                <n-icon-button color="transparent" 
                style="position:absolute; transform: scale(.8); top: .1rem; right:.1rem;" small
                @click="setExpandWeek(week.date)" 
                :icon-class="isWeekExpanded(week.date) ? 'fa fa-compress': 'fa fa-expand'">
                </n-icon-button>
            </div>
            <div v-else>
                <n-icon-button outlined
                color="secondary"
                style="position:absolute; transform: scale(.8); top: .1rem; right:.1rem;" small 
                @click="setExpandWeek(null)" 
                :icon-class="isWeekExpanded(week.date) ? 'fa fa-compress': 'fa fa-expand'" 
                >
                </n-icon-button>
                <div class="small-month-tag">[[[getMonth(week.date)]]], [[[getYear(week.date)]]]</div>
                <p :class="getWeekTagClass()"> v.[[[getWeek(week.date)]]]</p>
            </div>
            </td>
            <!--       Inspectors     -->
            <td :class="getInspectorTDClass(week.date)" 
                v-for="inspector in getInspectors(columns)" 
                :data-planning-inspector-id="inspector.id"
                :data-planning-date="week.date">
                <template v-for="inspection in getInspectionsOfWeek(week.date,inspector.inspections)">
                    <planning-item 
                    :id="inspection.id" 
                    :state="inspection.state" 
                    :name="inspection.name"
                    >
                    </planning-item>
                </template>
            </td>
            <!--       unassigned     -->
            <td :class="getUnassignedTDClass(week.date)" 
            :data-planning-date="week.date">
                <span class="planning-data" id="date">[[[week.date]]]</span>
                <template v-for="inspection in getInspectionsOfWeek(week.date, getUnassignedInspections(columns))">
                    <planning-item 
                    :id="inspection.id" 
                    :state="inspection.state" 
                    :name="inspection.name"
                    >
                    </planning-item>
                </template>
            </td>
        </tr>
        <div  v-if="isWeekExpanded(week.date)" class="expanded-section">
            <inspection-planning-weekdays
            @on-assign="onAssign"
            @on-unassign="onUnassign"
            :is-expanded="true"
            :start-date="week.date" 
            :inspectors="columns"
            ></inspection-planning-weekdays>
        </div>
    </template>
    `,
};
