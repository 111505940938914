import NIconButton from "../buttons/n-icon-button.js";

class EVENTS {
}

EVENTS.UPLOAD_FILES = 'upload-files'

const allowedFileTypes = [
    'png', 'jpg', 'jpeg', 'webp',
    'svg', 'dot', 'doc', 'docx',
    'xls', 'xlsx', 'csv', 'pdf',
    'ogm', 'wmv', 'mpg', 'webm',
    'mov', 'mpeg', 'mp4', 'avi'
]


export default {
    inject: ['toaster'],
    emits: Object.values(EVENTS),
    components: {
        "n-icon-button": NIconButton,
    },
    props: {
        disabled: Boolean,
        id: String,
        small: Boolean,
        image: Boolean,
        video: Boolean,
        word: Boolean,
        excel: Boolean,
        pdf: Boolean,
        single: Boolean
    },
    data() {
        return {
            dataTransfer: new DataTransfer(),
            value: null,
            buttonStyle: {},
            acceptedFilesString: ""
        }
    },
    methods: {

        handleInputClick() {
            this.$refs.inputRef.click()
        },
        handleAddFile(e) {
            if (e.target.files?.length) {
                this.dataTransfer = new DataTransfer()
                const files = Array.from(e.target.files)

                if (this.acceptedFilesString.length > 0) {
                    const fileEndingsArray = files.map(file => {
                            const fileEnding = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()
                            if (!allowedFileTypes.includes(fileEnding)) {
                                return fileEnding
                            }
                        }
                    )
                    const isAcceptedFiletype = fileEndingsArray.filter(fileEnding => fileEnding)

                    if (isAcceptedFiletype.length > 0) {
                        this.toaster.addMessage(gettext(`File types not supported: ${isAcceptedFiletype.join(', ')}`))
                        return
                    }
                }

                for (let i = 0; i < e.target.files.length; ++i) {
                    this.dataTransfer.items.add(e.target.files[i])
                }
                this.$emit(EVENTS.UPLOAD_FILES, this.dataTransfer.files)
            }
        },
        initStyle() {
            if (this.small) {
                this.buttonStyle = {
                    minWidth: '70px', width: '70px', height: '50px',
                }
                return
            }

            this.buttonStyle = {
                minWidth: '135px', width: '135px', height: '100px',
            }
        },
        getStyle() {
            return this.buttonStyle
        },
        initAcceptedString() {
            let acceptedFiles = ''

            if (this.image) {
                acceptedFiles += 'image/*,'
            }
            if (this.video) {
                acceptedFiles += 'video/*,'
            }
            if (this.word) {
                // borrowed from mdn Web Docs for accepting word files
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
                acceptedFiles += '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
            }
            if (this.excel) {
                // .csv : For CSV files
                // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet : Excel Files 2007+ (.xlsx)
                // application/vnd.ms-excel : For  Excel Files 97-2003 (.xls)
                acceptedFiles += '.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
            }
            if (this.pdf) {
                acceptedFiles += 'application/pdf,'
            }

            this.acceptedFilesString = acceptedFiles
        },
        getAcceptedFiles() {
            return this.acceptedFilesString
        },

    },
    created() {
        this.initStyle()
        this.initAcceptedString()
    },
    template: `
        <n-icon-button v-if="!disabled" @click="handleInputClick" color="sand" icon-class="fa fa-plus" :style="getStyle()">
            <input 
                ref="inputRef"
                hidden
                type="file"
                :multiple="single === false"
                @change="handleAddFile"
                :id="id" 
                :accept="getAcceptedFiles()"
           >
        </n-icon-button>
    `,
}