// IOApplicationStates
class CASE_STATE {
}

CASE_STATE.CREATED = '1'
CASE_STATE.PENDING = '2'
CASE_STATE.IN_REVIEW = '3'
CASE_STATE.AWAITING_DECISION = '5'
CASE_STATE.DONE = '4'

export default {
    components: {},
    props: {
        small: Boolean,
        status: String,
    },
    data() {
        return {
            createdText: gettext('Created'),
            pendingText: gettext('Pending'),
            inReviewText: gettext('In review'),
            awaitingDecisionText: gettext('Awaiting decision'),
            doneText: gettext('Done'),
        }
    },
    methods: {
        getStatusClass() {
            let statusClass = 'status-label '
            if (this.status === CASE_STATE.CREATED) {
                statusClass += ' created'
            }
            if (this.status === CASE_STATE.PENDING) {
                statusClass += ' confirmed'
            }
            if (this.status === CASE_STATE.IN_REVIEW) {
                statusClass += ' decision'
            }
            if (this.status === CASE_STATE.AWAITING_DECISION) {
                statusClass += ' decision'
            }
            if (this.status === CASE_STATE.DONE) {
                statusClass += ' approved'
            }
            if (this.small) {
                statusClass += ' small'
            }
            return statusClass
        },
        getStatusText() {
            if (this.status === CASE_STATE.CREATED) {
                return this.createdText
            }
            if (this.status === CASE_STATE.PENDING) {
                return this.pendingText
            }
            if (this.status === CASE_STATE.IN_REVIEW) {
                return this.inReviewText
            }
            if (this.status === CASE_STATE.AWAITING_DECISION) {
                return this.awaitingDecisionText
            }
            if (this.status === CASE_STATE.DONE) {
                return this.doneText
            }
        }
    },
    created() {
    },
    mounted() {
    },
    template: `
       <div :class="getStatusClass()" :data-string="getStatusText()"></div>
        `,
}
