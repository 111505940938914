class EVENTS {
}

EVENTS.SORT = 'on-sort'


export default {
    inject: [
        'tableparam'
    ],
    props: {
        sortOn: {
            type: String,
            default: null
        },
        center: Boolean,
        left: Boolean,
        right: Boolean,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            SORT: {
                'ASCENDING': 1,
                'DESCENDING': 2,
            },
            currentSort: null
        };
    },
    methods: {
        sort(order) {
            this.tableparam.setOrderParam(order)
            this.$emit(EVENTS.SORT)
        },
        getIconClass() {
            switch (this.currentSort) {
                case this.SORT.ASCENDING:
                    return ' ascending'
                case this.SORT.DESCENDING:
                    return ' descending'
                default:
                    return ' hidden'
            }
        },
        initSort() {
            let orderParam = this.tableparam.getOrderParam()

            if (!orderParam?.includes(this.sortOn)) {
                return
            }

            this.currentSort = orderParam.includes('-') ?
                this.SORT.DESCENDING : this.SORT.ASCENDING;
        },
        getPClass() {
            let pClass = ''
            if (this.center) {
                pClass += ' center-text'
            }
            if (this.left) {
                pClass += ' left-text'
            }
            if (this.right) {
                pClass += ' right-text'
            }
            return pClass
        },
        onSort() {
            switch (this.currentSort) {
                case this.SORT.ASCENDING:
                    this.currentSort = this.SORT.DESCENDING;
                    this.sort(`-${this.sortOn}`);
                    break;

                case this.SORT.DESCENDING:
                    this.currentSort = null;
                    this.sort('');
                    break;

                default:
                    this.currentSort = this.SORT.ASCENDING;
                    this.sort(this.sortOn);
            }
        }
    },
    mounted() {
        this.initSort()
    },
    created() {
    },
    template: `
        <th v-bind="$attrs" v-if="sortOn" @click="onSort()">
            <div class="n-th-container can-sort" >
                <p :class="getPClass()">
                    <slot></slot>
                </p>
                <div class="sortable-th" :class="getIconClass()">
                    <i class="fa-sharp fa-solid fa-arrow-up" ></i>
                </div>
            </div>
        </th>
        <th v-bind="$attrs" v-if="!sortOn" >
            <div class="n-th-container"  >
                <p :class="getPClass()">
                    <slot></slot>
                </p>
            </div>
        </th>
    `,
};