import NFlex from "../styling/n-flex.js";
import NButton from "../buttons/n-button.js";
import NBlock from "../styling/n-block.js";

export default {
    delimiters: ["[[[", "]]]"],
    components: {
        "n-flex": NFlex,
        "n-block": NBlock,
        "n-button": NButton
    },
    props: {
        isActive: Boolean,
        onClose: Function,
    },
    data() {
        return {
            sheetClass: ""
        };
    },
    methods: {
        handleClose() {
            this.onClose()
            this.sheetClass = "n-sheet"
        },
        getSheetClass() {
            return this.isActive ? "n-sheet n-show" : "n-sheet"
        }
    },
    template: `
        <div :class="getSheetClass()" v-click-away="handleClose">
                <span class="icon-button-container">
                    <i @click="onClose" style="font-size: 1.5rem" class="fa fa-times"></i> 
                </span>
            <!-- Sheet Content -->
            <!-- Don't forget to stop click events in slot from bubbling with @click.stop -->
            <slot></slot>
        </div>
        `,
};
