import NFlex from "../styling/n-flex.js";
import NBlock from "../styling/n-block.js";
import CalendarCell from "./calendar-cell.js";
import NIconButton from "../buttons/n-icon-button.js";

const EVENTS = {
    CHANGE: 'change'
}


export default {
    emits: ['change'],
    components: {
        "n-flex": NFlex,
        "n-block": NBlock,
        "n-icon-button": NIconButton,
        "calendar-cell": CalendarCell,
    },
    props: {
        id: String,
        variant: String,

        // Array of things to be shown on the calendar
        items: Array
    },
    data() {
        return {
            currentDate: null,
            selectedDate: null,
            weekdays: ["mån", "tis", "ons", "tors", "fre", "lör", "sön"],
            months: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"],
            variantOptions: ["quarterly", "month", "week"],
            currentVariant: null,

            //QUARTERLY VARIANT
            qs: ["Q1", "Q2", "Q3", "Q4"],

            //MONTH VARIANT
            paddingDaysBefore: null,
            paddingDaysAfter: null,
            daysInCurrentMonth: null,
            monthDateDict: null,
            itemDict: null
        }
    },
    methods: {
        decreaseMonth() {
            const newDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate())
            newDate.setMonth(this.selectedDate.getMonth() - 1)
            this.selectedDate = newDate
            this.$emit(EVENTS.CHANGE, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1))
            this.loadMonth(this.selectedDate)


        },
        increaseMonth() {
            const newDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate())
            newDate.setMonth(this.selectedDate.getMonth() + 1)
            this.selectedDate = newDate
            this.$emit(EVENTS.CHANGE, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1))
            this.loadMonth(this.selectedDate)

        },
        getDaysInMonth() {

        },
        loadMonth(date) {
            this.monthDateDict = {}

            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();


            // 0 as day gives us the last day of the previous month as days are not indexed => 1 is first day of THIS month, 0 is last day of PREVIOUS month
            const firstDayOfMonth = new Date(year, month, 1)
            const lastDayOfMonth = new Date(year, month + 1, 0)
            //Compensate for indexed value of month
            this.daysInCurrentMonth = new Date(year, month + 1, 0).getDate()

            const localeOptions = {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric"
            }
            const dateString = firstDayOfMonth.toLocaleDateString("sv-SE", localeOptions)
            const dateStringAfter = lastDayOfMonth.toLocaleDateString("sv-SE", localeOptions)


            this.paddingDaysBefore = this.weekdays.indexOf(dateString.split(', ')[0])
            this.paddingDaysAfter = this.weekdays.length - this.weekdays.indexOf(dateStringAfter.split(', ')[0]) - 1
            // console.log(this.items)
            this.items.forEach((inspection) => {
                const date = new Date(inspection.date)
                if (this.monthDateDict[date.getDate()]) {
                    this.monthDateDict[date.getDate()].push(inspection)
                    return
                }
                this.monthDateDict[date.getDate()] = [inspection]
            })


        },
        initCalendar() {
            this.currentDate = new Date()
            this.selectedDate = this.currentDate


            this.loadMonth(this.currentDate)
            this.$emit(EVENTS.CHANGE, new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1))

        },
        hasPaddingDaysBefore() {
            return this.paddingDaysBefore > 0
        },
        hasPaddingDaysAfter() {
            return this.paddingDaysAfter > 0
        },
        getCurrentMonth() {
            return this.selectedDate.toLocaleDateString("sv-SE", {month: "long"})
        },
        getNextMonth() {
            const month = this.selectedDate.getMonth();
            const year = this.selectedDate.getFullYear();
            return new Date(year, month + 1, 1).toLocaleDateString("sv-SE", {month: "short"})
        },
        getPreviousMonth() {
            const month = this.selectedDate.getMonth();
            const year = this.selectedDate.getFullYear();
            return new Date(year, month, 0).toLocaleDateString("sv-SE", {month: "short"})
        },
        getCurrentYear() {
            return this.selectedDate.toLocaleDateString("sv-SE", {year: "numeric"})
        },
        getPreviousMonthLastDay(index) {
            const lastDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 0).getDate()
            return lastDay - this.paddingDaysBefore + index
        },
        getCurrentDayClass(date, isPadding) {

            if (isPadding) {
                // console.log("is padding")
                return "n-calendar-day padding"
            }

            const dateToday = new Date()
            if ((dateToday.getDate() === date) && (this.selectedDate.getMonth() === dateToday.getMonth()) && (this.selectedDate.getFullYear() === dateToday.getFullYear())) {
                return "n-calendar-day current"
            }

            return "n-calendar-day"
        },
        getWeek() {
            return "v. "
        },
        getItemClass(state) {

            switch (state) {
                case "Skapad":
                    return "n-calendar-cell-item";
                case "Klar":
                    return "n-calendar-cell-item approved";
                default:
                    return "n-calendar-cell-item";
            }
        },
        getLocaleTime(date) {
            const inspectionDate = new Date(date)
            return inspectionDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
        },
        selectCell(ev) {
            // console.log(ev)
            // console.log(ev.target)
            // console.log(ev.target.tagName)
            ev.srcElement.classList.add("selected-cell")
        }
    },
    created() {
        this.currentVariant = "month"
        this.initCalendar()

    },
    template:
        `
            <n-flex gap="2rem" justify-content="center" align-items="center">
                <n-flex gap="1rem" width="300px" justify-content="flex-end" align-items="center">
                    <h1 style="text-transform: capitalize">[[[ getCurrentMonth() ]]] [[[ getCurrentYear() ]]]</h1>
                    <n-flex gap="1rem" width="max-content">
                        <n-icon-button small color="sand" @click="decreaseMonth()" icon-class="fas fa-chevron-left"></n-icon-button>
                        <n-icon-button small color="sand" @click="increaseMonth()" icon-class="fas fa-chevron-right"></n-icon-button>
                    </n-flex>
                </n-flex>
            </n-flex>
            <n-flex wrap bg-color="#d7d0c8" max-width="100%" gap="2px" padding="1px">
            <!--  Padding Before -->
                <template v-if="hasPaddingDaysBefore()">
                    <div class="n-calendar-cell-month padding" v-for="n in paddingDaysBefore "  >
                        <span class="n-calendar-cell-title" v-if="!(n > weekdays.length)"> [[[ weekdays[n - 1] ]]]  </span>
                        <template  v-if="n === 1" >
                            <p class="n-calendar-cell-week-marker">[[[getWeek()]]] </p> <!--  Calculate week function  -->
                            <p class="n-calendar-cell-month-marker">[[[getPreviousMonth()]]] </p>
                        </template>
                        <span :class="getCurrentDayClass(getPreviousMonthLastDay(n),true)">
                            <h4 >[[[getPreviousMonthLastDay(n)]]]</h4>
                        </span>
                    </div>            
                </template>
                 <!--  Current Month -->
                <div class="n-calendar-cell-month" @click.self="selectCell" v-for="days in daysInCurrentMonth"  >
                    <span class="n-calendar-cell-title" v-if="!(days > weekdays.length)">[[[ weekdays[days + paddingDaysBefore - 1] ]]]</span>
                    <p v-if="(days + paddingDaysBefore) % 7=== 1" class="n-calendar-cell-week-marker">[[[getWeek()]]]</p>
                    <span :class="getCurrentDayClass(days,false)">
                        <h4>[[[days]]]</h4> 
                    </span>
                    <div class="n-calendar-cell-item-list">
                    <template v-for="(inspection, index) in monthDateDict[days]"  >
                    
                        <div :class="getItemClass(inspection.state)" v-if="index < 2">
                            <p>[[[getLocaleTime(inspection.date)]]]</p>
                        </div>                    
                    </template>
                    </div>
                </div>
                 <!--  Padding After -->
                <template v-if="hasPaddingDaysAfter()">
                    <div class="n-calendar-cell-month padding" v-for="n in paddingDaysAfter "  >
                        <template  v-if="n === 1" >
                            <p class="n-calendar-cell-month-marker">[[[getNextMonth()]]]</p>
                        </template>
                        <span :class="getCurrentDayClass(getPreviousMonthLastDay(n),true)">
                            <h4 >[[[n]]]</h4>
                        </span>
                    </div>            
                </template>
            </n-flex>

        `,
}