import NChecklistColumn from "./n-checklist-column.js";

class EVENTS {
}

EVENTS.UPDATE_COLUMNS = 'update'

export default {
    components: {
        'n-checklist-column': NChecklistColumn
    },
    props: {
        isLoading: Boolean,
    },
    emits: Object.values(EVENTS),
    data() {
        return {};
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    template: `
        <div v-if="isLoading" class="checklist-column-container" > 
            <n-checklist-column :is-loading="isLoading"  :breadcrumb="false">
                <template #header>
                </template>
                <template #content>
                <div style="margin: auto" class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </template>
            </n-checklist-column>
        </div>
        <div v-show="!isLoading" id="grid-container" class="checklist-column-container">
            <slot></slot>
        </div>
    `,
};