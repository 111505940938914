import NIconButton from "../buttons/n-icon-button.js";

const EVENTS = {
    APPROVE: 'on-approve',
    REJECT: 'on-reject',
    COMMENT: 'on-comment',
}

const MOCK_FIELD_STATES = {
    HAS_COMMENT: 0,
    IS_APPROVED: 0,
    HAS_NON_CONFORMITY: 0,
}


export default {
    components: {
        "n-icon-button": NIconButton
    },
    props: {
        fieldState: Object,
        fieldResult: {
            type: Number,
            default: FIELD_CONTROL_POINT_RESULT.NOT_SET
        },
        field: String,
        disableComment: Boolean,
        align: String,
        column: Boolean,
    },
    data() {
        return {
            inputElement: null,
            state: {
                isApproved: false,
                hasComment: false,
                hasNonConformity: false,
            }
        }
    },
    emits: Object.values(EVENTS),
    methods: {
        onApprove() {
            this.state.isApproved = !this.state.isApproved
            this.$emit(EVENTS.APPROVE, this.field)
        },
        onReject() {
            this.$emit(EVENTS.REJECT, this.field)
        },
        onComment() {
            this.$emit(EVENTS.COMMENT, this.field)
        },
        updateDecision() {
            this.state.hasNonConformity = false
            this.state.isApproved = false
        },
        initFieldState() {
            // todo: Handle some kind of initiation of buttons depending on field state
            const {comment, state} = this.fieldState

            if (comment) this.state.hasComment = true
            if (state === MOCK_FIELD_STATES.IS_APPROVED) this.state.isApproved = true
            if (state === MOCK_FIELD_STATES.HAS_NON_CONFORMITY) this.state.hasNonConformity = true
        },
        getAlignment() {

            if (this.align === 'center') return ' g-align-center'
            if (this.align === 'end') return ' g-align-end'
            if (this.align === 'start') return ' g-align-start'

        }
    },
    created() {
        if (this.fieldResult === FIELD_CONTROL_POINT_RESULT.APPROVED) {
            this.state.isApproved = true
        } else if (this.fieldResult === FIELD_CONTROL_POINT_RESULT.REJECTED
            || this.fieldResult === FIELD_CONTROL_POINT_RESULT.RESOLVED) {
            this.state.hasNonConformity = true
        }
    },
    mounted() {
    },
    template: `
        <div class="n-flex review-options" :class="getAlignment()">
            <span style="width: 100%">
                <slot name="input"></slot>
            </span>
 
            <div class="n-flex gap-m options-container ">
               <n-icon-button @click="onApprove" small :outlined="!state.isApproved" icon-class="fa fa-check" color="approved" :style="state.hasNonConformity ? {opacity: 0.5} : {}" ></n-icon-button>
               <n-icon-button @click="onReject"  small :outlined="!state.hasNonConformity" icon-class="fa fa-times" color="rejected" :style="state.isApproved ? {opacity: 0.5} : {}"></n-icon-button>
               <n-icon-button v-if="!disableComment" @click="onComment" small :outlined="!state.hasComment" icon-class="fa fa-comment"></n-icon-button>
            </div>
        </div>

        `,
}
