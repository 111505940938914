export default {
    components: {},
    props: {
        title: String,
    },
    data() {
        return {}
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    template: `
    <div class="n-flex page-header">
    <h1>[[[title]]]</h1>
        <slot name="tabs"></slot>
        <slot name="profile-info"></slot>
    </div>

    `,
}