// Vue3DatePicker docs
//
// CURRENTLY USED PROPS
// placeholder ->> https://vue3datepicker.com/api/props/#placeholder
// modelType --> https://vue3datepicker.com/api/props/#modeltype
// cancelText --> https://vue3datepicker.com/api/props/#canceltext
// selectText --> https://vue3datepicker.com/api/props/#selecttext
// textInputOptions --> https://vue3datepicker.com/api/props/#textinputoptions
// disabled --> https://vue3datepicker.com/api/props/#disabled
// inputClassName --> https://vue3datepicker.com/api/props/#inputclassname
// state --> https://vue3datepicker.com/api/props/#state
// autoApply --> https://vue3datepicker.com/api/props/#autoapply
// closeOnAutoApply --> https://vue3datepicker.com/api/props/#closeonautoapply
// textInput --> https://vue3datepicker.com/api/props/#textinput
// locale --> https://vue3datepicker.com/api/props/#locale
// enableTimePicker --> https://vue3datepicker.com/api/props/#enabletimepicker
// @update:modelValue --> https://vue3datepicker.com/api/events/#update-modelvalue

import NTooltip from "../n-tooltip.js";

const STRING_LENGTH = 2

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    CHANGE: 'change',
    SAVE: 'inlineSave',
}

export default {
    inject: ['errors', 'current_device'],
    components: {
        'n-date-picker': VueDatePicker,
        "n-tooltip": NTooltip,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,
        optional: Boolean,

        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        placeholder: String,
        proposedTime: String,
        fullWidth: Boolean,
        hideLabel: Boolean,
        enableUndo: Boolean,
        hideTooltip: Boolean,

        //VueDatepickerProps
        autoApply:
            {
                type: Boolean,
                default: true
            },
        allowTextInput:
            {
                type: Boolean,
                default: true
            },
    },
    data() {
        return {
            showSave: false,
            initialValue: {hours: 7, minutes: 0},
            value: {hours: 7, minutes: 0}, //07:00
            isValid: null,
            error: null,
            inputElm: null,
            optionalText: gettext('Optional'),
            previousValue: null,
            //Vue3DatePicker data
            inputClass: null,
            cancelText: gettext('Cancel'),
            selectText: gettext('Select'),
            saveText: gettext('Save'),
            textInputOptions: {
                enterSubmit: true,
                tabSubmit: true,
                openMenu: true,
                format: (val) => this.onInput(val),
            },
            proposedTimText: gettext('Click to apply proposed time'),
            validationState: null, // set to false on error, reset to null when handled

        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.getStringFromTimeObject(value))
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.CHANGE, value)
            this.resetError()
        },
        onInput(value) {
            const time = this.getTimeObjectFromString(value)

            const dateTime = new Date()
            dateTime.setHours(time.hours)
            dateTime.setMinutes(time.minutes)

            if (this.isValidTimeString(value)) {
                this.resetError()
                return dateTime
            }
            this.makeInvalid("Invalid time")
            return dateTime
        },
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'datepicker inline'
        },
        resetError() {
            this.error = ''
            this.inputClass = 'datepicker inline'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        },
        save() {
            this.showSave = false
            const event = {
                name: this.name,
                value: this.getStringFromTimeObject(this.value),
                oldValue: this.getStringFromTimeObject(this.initialValue)
            }
            this.previousValue = this.getStringFromTimeObject(this.initialValue)
            this.initialValue = this.value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.value)
            this.$emit(EVENTS.SAVE, event)
            this.inputElm.blur()
        },
        triggerEnable() {
            this.showSave = true
        },
        triggerDisable() {
            this.propagateValue(this.initialValue)
            this.showSave = false
        },
        onClickEdit() {
            this.inputElm.focus()
        },
        applyProposedTime() {
            if (this.isValidTimeString(this.proposedTime)) {
                //just in case guard
                this.resetError()
                this.propagateValue(this.getTimeObjectFromString(this.proposedTime))
                this.inputElm.focus()
                return
            }
            this.makeInvalid("Invalid time")
        },
        getTimeObjectFromString(string) {
            const timeString = moment(string, [Constants.TIME_FORMAT, 'HHmm']).format(Constants.TIME_FORMAT)
            const timeSplit = timeString.split(':')
            const hour = timeSplit[0] //HH
            const minutes = timeSplit[1] // mm
            return {hours: hour, minutes: minutes, seconds: 0}
        },
        getStringFromTimeObject(obj) {
            const {hours, minutes} = obj || {}

            let hourString = hours?.toString() || '00'
            let minuteString = minutes?.toString() || '00'

            if (hourString.length < STRING_LENGTH) {
                hourString = hourString.padStart(2, '0')
            }
            if (minuteString.length < STRING_LENGTH) {
                minuteString = minuteString.padStart(2, '0')
            }

            return hourString + ':' + minuteString
        },
        isValidTimeString(time) {
            const isStrict = true
            return moment(time, [Constants.TIME_FORMAT, 'HHmm'], isStrict).isValid()
        }, getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            containerClass += ' inline'

            return containerClass
        },
        getSaveButtonClass() {
            let saveClass = 'n-button save '

            if (this.disabled) {
                saveClass += ' disabled'
            }

            return saveClass
        },
        getEditButtonClass() {
            let editClass = 'n-button outlined edit '

            if (this.disabled) {
                editClass += ' disabled'
            }

            return editClass
        },
        hasPreviousValue() {
            if (!this.enableUndo) return false

            if (!!this.previousValue?.length) {
                return this.previousValue !== this.getStringFromTimeObject(this.initialValue)
            }
        },
        undoLatestInput() {
            this.initialValue = this.getTimeObjectFromString(this.previousValue)
            this.propagateValue(this.initialValue)
            this.save()
        },
    },
    created() {
        this.initStyle()

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            let value = this.getTimeObjectFromString(this.initial)
            this.initialValue = value
            this.propagateValue(value)
            return
        }
        if (this.modelValue) {
            this.initialValue = this.getTimeObjectFromString(this.modelValue)
            this.value = this.getTimeObjectFromString(this.modelValue)
        }

    },
    mounted() {
        this.inputElm = document.getElementById(`dp-input-${this.id}`)
    },
    template: `
    <div :class="getContainerClass()">
        <div class="n-flex gap-m align-center" style="justify-content: space-between">
                <label  v-if="!hideLabel" :for="id" :class="labelClass">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
                <div v-if="proposedTime" class="proposed-date-container" @click="applyProposedTime()">
                    [[[proposedTimText]]]: <p>[[[proposedTime]]]</p>
                </div>
                <n-tooltip v-if="!hideTooltip" v-if="helpText" :help-text="helpText" :label="label" >
                    <i class="fa-sharp fa-regular fa-circle-info"></i>
                </n-tooltip>
        </div>
        <div class="inline-edit" >
            <n-date-picker 
            v-model="value" 
            :uid="id" 
            :name="name" 
            timePicker
            :placeholder="placeholder || '--:--'"
            modelType="format"
            :clearable="showSave"
            :cancelText="cancelText"
            :selectText="selectText"
            :textInputOptions="textInputOptions"
            :disabled="disabled"
            :inputClassName="inputClass"
            :state="validationState"
            :autoApply="autoApply"
            :textInput="current_device !== 'MOBILE'" 
            :closeOnAutoApply="true"
            locale="sv" 
            @focus="triggerEnable"
            @blur="triggerDisable"
            @update:modelValue="onChange(value)" 
            >
                <template #input-icon>
                    <i class="fa fa-clock" style="margin-left: 1rem; width: 20px; height: 20px"></i>
                </template>
            </n-date-picker>
            <button :class="getEditButtonClass()" v-show="!showSave"  @click.stop="onClickEdit" ><i class="fa fa-pen"></i></button>
                <!--  @mousedown.prevent prevents mouseDown to fire the onBlur method on input before save method is invoked -->
            <button :class="getSaveButtonClass()" v-show="showSave" @mousedown.prevent @click.stop="save">[[[saveText]]]</button >
        </div>
        <div v-if="error" class="invalid-field-error inline" >
        [[[ error ]]]
        </div>
        <div v-if="hasPreviousValue()" class="n-flex gap-s inline-undo-container">
            <p style="text-decoration: line-through" title="Previous value">[[[previousValue]]]</p> 
            <a class="n-flex gap-xs align-center inline-undo " @click.once="undoLatestInput">
                <i class="fa-sharp fa-xs fa-solid fa-rotate-left"></i>
                undo
            </a>
        </div>
    </div>
    `,
}