import NFlex from "../styling/n-flex.js";

export default {
    components: {
        "n-flex": NFlex,
    },
    props: {
        items: Array,
        pages: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            currentPage: 0,
        }
    },
    methods: {
        getItems(){
            let index = this.currentPage * this.pages
        }
    },
    created() {

    },
    template: `
        <n-flex container column 
                bg-color="#F2F0ED" gap="1rem">
            <table class="n-table bordered expandable">
                <thead>
                <tr>
                    <th><h3>{% trans 'Name' %}</h3></th>
                    <th><h3>{% trans 'Address' %}</h3></th>
                    <th><h3>{% trans 'Owner' %}</h3></th>
                    <th><h3>{% trans 'Last inspection' %}</h3></th>
                    <th><h3>{% trans 'Next inspection' %}</h3></th>
                </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </n-flex>
        `,
}
