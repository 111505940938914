class EVENTS {
}

EVENTS.NEXT = 'on-next'
EVENTS.PREVIOUS = 'on-previous'
EVENTS.GO_TO = 'on-goto'

export default {
    inject: ['tableparam'],
    props: {
        totalItems: Number,
        itemsPerPage: {
            type: Number,
            default: 10
        },
        currentPage: Number,
        previousPage: {
            type: Number,
            default: -1
        },
        nextPage: {
            type: Number,
            default: -1
        },
        pagesToShowCount: {
            type: Number,
            default: 5
        },
        isFilter: Boolean,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            nextText: gettext('Next'),
            previousText: gettext('Previous'),
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        pagesToShow() {
            const sidePagesCount = Math.floor(this.pagesToShowCount * .5);
            let startPage = Math.max(this.currentPage - sidePagesCount, 1);
            let endPage = Math.min(startPage + this.pagesToShowCount - 1, this.totalPages);

            if (endPage === this.totalPages) {
                startPage = Math.max(this.totalPages - this.pagesToShowCount + 1, 1);
            }

            return Array.from({length: (endPage - startPage + 1)}, (v, k) => k + startPage);
        }
    },
    methods: {
        goToPage(page) {
            if (this.isFilter) {
                this.tableparam.setPageParam(page)
            }
            this.$emit(EVENTS.GO_TO, page)
        }
    },
    mounted() {
    },
    template: `
  <div class="pagination-container">
    <button @click="goToPage(previousPage)"  class="pagination-button end" :disabled="previousPage <= 0"><i class="fa-solid fa-chevron-left"></i> <p class="is-desktop">[[[ previousText ]]]</p>
  </button>

    <button
      v-for="page in pagesToShow"
      :key="page"
      class="pagination-button"
      @click="goToPage(page)"
      :class="{'active': currentPage === page}"
    >
      [[[ page ]]]
    </button>

    <button @click="goToPage(nextPage)"  class="pagination-button end" :disabled="nextPage <= 0"><p class="is-desktop">[[[ nextText ]]]</p> <i class="fa-solid fa-chevron-right"></i></button>
  </div>
    `,
};