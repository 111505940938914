import NorseTableRow from "./n-tr.js"
import NorseTableData from "./n-td.js"
import NorseTableRowCollapse from "./n-tr-collapse.js"
import NorseList from "./n-list.js"
import NTable from "./n-table.js";
import NTh from "./n-th.js";
import NPaginator from "./n-paginator.js";
import NSectionTr from "./n-section-tr.js";

export default {
    "n-tr": NorseTableRow,
    "n-section-tr": NSectionTr,
    "n-td": NorseTableData,
    "n-tr-collapse": NorseTableRowCollapse,
    "n-list": NorseList,
    "n-table": NTable,
    "n-th": NTh,
    "n-paginator": NPaginator
};
