import NTooltip from "../n-tooltip.js";

const EVENTS = {
    CHANGE: 'onChange',
}

export default {
    inject: ["errors"],
    components: {
        'n-tooltip': NTooltip
    },
    watch: {
        errors(newValues, oldValue) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                // this.makeInvalid(this.error)
                return true
            }
            return false
        },
        initialError(newError) {
            if (newError) {
                // this.makeInvalid(newError)
            }
        },
    },
    emits: Object.values(EVENTS),
    props: {
        color: String,
        modelValue: null,
        initial: Boolean,
        initialError: String,
        disabled: Boolean,
        onToggle: Function,
    },
    data() {
        return {
            isActive: false,
            previousState: false,
        };
    },
    methods: {
        initToggle() {
            if (this.initial) {
                this.isActive = this.initial;
                this.previousState = this.initial;
            }
        },
        async toggle() {
            this.previousState = this.isActive
            this.isActive = !this.isActive
            const success = await this.onToggle(this.isActive)

            if (!success) {
                this.isActive = this.previousState
            }
        },
        getLabelClass() {
            let labelClass = 'switch'

            if (this.isActive) {
                labelClass += ' is-active'
            }

            if (this.disabled) {
                labelClass += ' disabled'
            }
            return labelClass
        }
    },
    created() {
        this.initToggle()
    },
    template: `
    <div class="toggle-container " :class="color" @click.stop="">
        <label :class="getLabelClass()">
            <input :disabled="disabled" type="checkbox" @change="toggle" :checked="isActive"/>
            <span class="slider round">
                <template v-if="disabled">
                    <i class="fas fa-do-not-enter disabled-right-toggle-icon"></i>
                    <i class="fas fa-do-not-enter disabled-left-toggle-icon"></i>
                </template>
                <template v-if="!disabled">
                    <i v-if="isActive" class="fas fa-check left-toggle-icon"></i>
                </template>
            </span>
        </label>
    </div>
    `,
}
