import NChecklistBreadcrumb from "./n-checklist-breadcrumb.js";

class EVENTS {
}

EVENTS.UPDATE_COLUMNS = 'update'

export default {
    components: {
        'n-checklist-breadcrumb': NChecklistBreadcrumb
    },
    props: {
        columnId: String,
        title: String,
        isLoading: Boolean,
    },
    watch: {
        activeColumns: {
            handler(columns) {
                const columnArr = [...columns] // converting proxy to array
                const idIndex = columnArr.indexOf(this.columnId)

                if (idIndex === -1) {
                    return
                }

                const lastColumn = columnArr.pop()
                if (this.columnId === lastColumn) {
                    // Need to clear any selected checklistItems
                    this.clearSelected()
                }
            },
            deep: true
        }
    },
    inject: ['checkList', 'current_device', 'visibleColumns'],
    emits: Object.values(EVENTS),
    data() {
        return {
            selectedType: ''
        };
    },
    computed: {
        activeColumns() {
            //Hack-isch, because vue cannot track reactivity in ordinary Javascript classes (Like ChecklistController)
            // We "convert" it into a computed variable, and watches for changes within the computed variable
            if (this.isLoading) {
                return
            }
            return this.checkList.activeColumns;
        }
    },
    provide() {
        return {
            selectItem: this.selectItemCallback,
            columnId: this.columnId
        };
    },
    methods: {
        selectItemCallback(event) {
            this.$refs.column.querySelectorAll('.checklist-item').forEach(nDiv => nDiv.classList.remove('active'));
            const item = event.target.closest('.checklist-item');
            if (item) {
                item.classList.add('active');
            }
        },
        initColumn(initial) {
            this.$refs.column.querySelectorAll('.checklist-item').forEach((nDiv, index) => {
                const item = nDiv.getAttribute('data-item');
                const type = nDiv.getAttribute('data-type');
                if (item === initial && this.selectedType === type) {
                    nDiv.classList.add('active')
                }
            });
        },
        clearSelected() {
            this.$refs.column.querySelectorAll('.checklist-item').forEach(nDiv => nDiv.classList.remove('active'));
        },
        shouldShowBreadCrumb() {
            let showBreadCrumb = false

            if (this.current_device !== 'DESKTOP') {
                showBreadCrumb = true
            }
            const lastIndex = this.visibleColumns.length - 1
            if (lastIndex >= 0) {
                if (this.columnId !== this.visibleColumns[this.visibleColumns.length - 1]) {
                    showBreadCrumb = false
                }
            }
            return showBreadCrumb
        }
    },
    created() {
    },
    mounted() {
        const handler = pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CHECKLIST)
        const initial = handler.getChecklistParam(this.columnId)
        if (initial) {
            const {type, item} = this.checkList.extractValueAndType(initial)
            this.selectedType = type
            this.initColumn(item)
        }
    },
    template: `
        <div ref="column" class="checklist-column" :class="isLoading ? 'is-loading' : ''">
            <div class="checklist-column-header">
                <div class="n-flex align-center gap-l" style="justify-content: space-between">
                    <n-checklist-breadcrumb v-if="shouldShowBreadCrumb()"></n-checklist-breadcrumb>
                    <slot name="header"></slot>
                </div>
                <slot name="header-content"></slot>
            </div>
            <slot name="content"></slot>
        </div>
    `,
};