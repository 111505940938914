class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'
EVENTS.KEY_ENTER = 'enter'

export default {
    emits: Object.values(EVENTS),
    inject: ["errors"],
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.nonFieldErrorKey, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
    },
    props: {
        nonFieldErrorKey: {
            type: String,
            default: '__all__'
        },
    },
    data() {
        return {
            error: null
        }
    },
    methods: {
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'textfield'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        }
    },
    created() {
        this.initStyle()
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
    },
    template: `
        <div v-if="error" class="invalid-field-error">
            [[[ error ]]]
        </div>
    `,
}