import NIconButton from "./buttons/n-icon-button.js";
import NButton from "./buttons/n-button.js";

class EVENTS {
}

EVENTS.REMOVE_IMAGE = 'remove-image'


export default {
    components: {
        "n-icon-button": NIconButton,
        "n-button": NButton,
    },
    props: {
        imageSrc: String,
        altText: String,
        small: Boolean,
        hideRemove: Boolean,
    },
    data() {
        return {
            removeButtonText: gettext('Remove image'),
            isPopupVisible: false
        };
    },
    methods: {
        showPopup() {
            this.isPopupVisible = true;
        },
        hidePopup() {
            this.isPopupVisible = false;
        },
        removeImage() {
            this.isPopupVisible = false;
            this.$emit(EVENTS.REMOVE_IMAGE)
        }
    },
    mounted() {
    },
    template: `
    <div class="image-wrapper" @click="showPopup">
        <img :src="imageSrc" :alt="altText" class="preview-img" :class="small ? ' small' : ''">
        <div v-if="isPopupVisible" class="popup" @click.stop="hidePopup">
            <div class="enlarged-image-container">
                <img :src="imageSrc" class="enlarged-image" @click.stop>
                <n-icon-button small icon-class="fa fa-times" outlined style="position: absolute; top: 1rem; right: 1rem"></n-icon-button>
                <n-button v-if="!hideRemove && small" @click="removeImage()" style="position: absolute; top: 1rem; left: 1rem;" :text="removeButtonText" small  color="rejected" icon-right="fa-sharp fa-solid fa-trash"></n-button>
            </div>
        </div>
    </div>
    `,
}
