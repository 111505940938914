class EVENTS {
}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.CHANGE = 'change'


const CHECKMARK_LIFETIME = 1500 //1second


export default {
    emits: Object.values(EVENTS),
    inject: ["errors"],
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
        modelValue(newValue) {
            this.value = newValue
        }
    },
    props: {
        modelValue: null,
        optional: Boolean,

        rows: {
            type: Number,
            default: 4
        },
        hideLabel: Boolean,
        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        fullWidth: Boolean,
        modelPrecedence: Boolean,
    },
    data() {
        return {
            value: null,
            error: null,
            labelClass: null,
            inputClass: null,
            optionalText: gettext('Optional')
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        onChange(value) {
            this.propagateValue(value)
            this.$emit(EVENTS.CHANGE, value)
        },
        shouldShowSuccess() {
            const {loader, checkmark} = this.$refs;

            loader.classList.add('show')
            checkmark.classList.add('show')
            loader.classList.add('load-complete')
            checkmark.classList.add('draw')
            if (!this.persistResult) {
                setTimeout(() => {
                    loader.classList.remove('load-complete')
                    checkmark.classList.remove('draw')
                    loader.classList.remove('show')
                    checkmark.classList.remove('show')
                }, CHECKMARK_LIFETIME)
            }
        },
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'n-textarea'
        },
        makeInvalid(error) {
            this.error = error
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        },
        getInputTextClass() {
            let textClass = 'n-textarea'

            if (this.disabled) {
                textClass += ' disabled'
            }

            if (this.error) {
                textClass += ' error'
            }

            return textClass
        },
    },
    created() {
        this.initStyle()
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if(this.modelPrecedence && this.modelValue)
        {
            this.value = this.modelValue
            return
        }
        if (this.initial) {
            this.propagateValue(this.initial)
            return
        }
        if (this.modelValue) {
            this.value = this.modelValue
        }
    },
    template: `
    <div :class="getContainerClass()" style="width:100%">
        <label v-if="!hideLabel" :class="labelClass" :for="id">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
        <textarea 
        :rows="rows"
        :id="id" 
        :name="name" 
        :value="value" 
        :class="getInputTextClass()" 
        :placeholder="helpText" 
        :disabled="disabled"
        @input="propagateValue($event.target.value)"
        @change="onChange($event.target.value)"
        ></textarea>
        <div ref="loader" class="circle-loader">
            <div ref="checkmark" class="checkmark draw"></div>
        </div>
        <!--    todo: need backend testing to check "throwing" error    -->
        <div v-if="error" class="invalid-field-error">
        [[[ error ]]]
        </div>
    </div>

    `,
}