import NButton from "../buttons/n-button.js";


const EVENTS = {
    ADD_COMMENT: 'on-add-comment',
    GO_BACK: 'on-go-back',
    GO_FORWARD: 'on-go-forward',
    FINISH: 'on-finish',
    EXIT: 'on-exit'
}


export default {
    components: {
        "n-button": NButton,
    },
    data() {
        return {
            previousStep: null,
            nextStep: null,
            currentStep: null,
        }
    },
    watch: {
        currentStepIndex(newValue) {
            this.setupWizard(newValue)
        }
    },
    props: {
        steps: Array,
        currentStepIndex: Number,
        isLoading: Boolean,
        canFinish: {
            type: Boolean,
            default: true
        },
        showFinish: {
            type: Boolean,
            default: true
        },
        buttonNextText: {
            type: String,
            default: gettext('Next')
        },
        buttonPreviousText: {
            type: String,
            default: gettext('Previous')
        },
        buttonExitText: {
            type: String,
            default: gettext('Exit')
        },
        buttonFinishText: {
            type: String,
            default: gettext('Finish')
        },

    },
    emits: Object.values(EVENTS),
    methods: {
        goToNextStep() {
            this.$emit(EVENTS.GO_FORWARD)
        },
        goToPreviousStep() {
            this.$emit(EVENTS.GO_BACK)
        },
        finish() {
            this.$emit(EVENTS.FINISH)
        },
        exit() {
            this.$emit(EVENTS.EXIT)
        },
        openChatBox() {
            document.querySelector('div.wizard-comment-block').classList.toggle("closed")
        },
        setupWizard(currentStepIndex) {
            if (currentStepIndex !== undefined) {
                this.previousStep = this.steps[currentStepIndex - 1]
                this.currentStep = this.steps[currentStepIndex]
                this.nextStep = this.steps[currentStepIndex + 1]
            }
        },
        updateShadowClass() {
            const scrollableDiv = document.querySelector('.wizard-content-container')
            const topDiv = document.querySelector('.wizard-top-container')
            const bottomDiv = document.querySelector('.wizard-bottom-container')
            if (scrollableDiv?.scrollHeight > scrollableDiv?.clientHeight) {
                topDiv.classList.add('has-scroll')
                bottomDiv.classList.add('has-scroll')
                return
            }
            topDiv.classList.remove('has-scroll')
            bottomDiv.classList.remove('has-scroll')
        },
        observeSlotContentChanges() {
            //elegant but naive solution. To really make it pop a resizeObserver should complement it as well.
            // if the content of wizard-content-container changes, we invoke methods where mutations where of type:
            // childlist
            const targetNode = document.querySelector('.wizard-content-container')

            const config = {attributes: true, childList: true, subtree: true};

            const callback = (mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === "childList") {
                        this.updateShadowClass()
                    }
                }
            };
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        },
    },
    created() {
    },
    mounted() {
        this.setupWizard(this.currentStepIndex)
        this.observeSlotContentChanges()
    },
    template: `

        <div class="wizard-box" >
            <div class="wizard-top-container">
                    <div v-if="previousStep" @click="goToPreviousStep()" class="wizard-step-container"> 
                        <div class="previous-step-icon">
                            <i class="fa fa-arrow-left"></i>      
                        </div>
                        <div class="n-flex n-col gap-xs">
                            <h3>[[[buttonPreviousText]]]:</h3>
                            <div class="n-flex gap-s align-center">
                                <h3 style="font-size: 24px;"> [[[previousStep.step]]]</h3>
                                <p style="font-size: 12px">[[[previousStep.name]]]</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentStep"  class="wizard-step-container current-step">
                        <div class="n-flex gap-m align-center">
                            <h1> [[[currentStep.step]]]</h1>
                            <div class="n-flex n-col gap-xs" style="max-width: 400px">
                                <h3>[[[currentStep.name]]]</h3>
                                <p :title="currentStep.description" class="main-description">[[[currentStep.description]]]</p>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="nextStep" @click="goToNextStep()" class="wizard-step-container">
                        <div class="n-flex n-col gap-xs">
                            <h3>[[[buttonNextText]]]:</h3>
                            <div class="n-flex gap-s align-center">
                                <h3 style="font-size: 24px;"> [[[nextStep.step]]]</h3>
                                <p style="font-size: 12px">[[[nextStep.name]]]</p>
                            </div> 
                        </div>
                        <div class="next-step-icon">
                            <i class="fa fa-arrow-right"></i>
                        </div>
                    </div>
            </div>
            <div class="wizard-content-container" :style="isLoading ?  {overflow: 'hidden', marginRight: '15px'} : {}">
            <div style="position: relative">
                <slot name="content"></slot>
               <div v-if="isLoading" class="n-flex align-center jus-center" style="pointer-events: none; position:absolute; inset: 0; backdrop-filter: blur(5px)">
                </div>
            </div>
            </div>
             
            <!--    Make this a component        -->
            
            <div class="wizard-bottom-container">
               <n-button v-if="this.previousStep" icon-left="fa-solid fa-chevron-left" @click="goToPreviousStep()" small outlined :text="buttonPreviousText"></n-button>
               <div v-if="!this.previousStep"></div>
               <n-button v-if="this.nextStep" icon-right="fa-solid fa-chevron-right" @click="goToNextStep()" small outlined :text="buttonNextText"></n-button>
               <n-button v-if="this.showFinish && !this.nextStep" :disabled="!canFinish" @click="finish()" small :text="buttonFinishText"></n-button>
            </div>
        </div>
        `,
}
