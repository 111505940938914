import NIconButton from "./buttons/n-icon-button.js";
import NButton from "./buttons/n-button.js";
import NTextareaInput from "./inputs/n-textarea-input.js";

class EVENTS {
}

EVENTS.ON_REJECT = 'on-reject'
EVENTS.ON_APPROVE = 'on-approve'
EVENTS.ON_IGNORE = 'on-ignore'

class RESULTS_STATE_CHOICES {
}

RESULTS_STATE_CHOICES.NOT_SET = 1  //NONE
RESULTS_STATE_CHOICES.OK = 2 //approved
RESULTS_STATE_CHOICES.NOT_OK = 3 //rejected
RESULTS_STATE_CHOICES.CORRECTED = 4 //NONE
RESULTS_STATE_CHOICES.CORRECTED_OK = 5 //approved
RESULTS_STATE_CHOICES.NOT_APPLICABLE = 6  //ignored

class RESULTS {
}

RESULTS.APPROVAL = 1
RESULTS.REJECTION = 2
RESULTS.IGNORED = 3
RESULTS.NOT_SET = 4

export default {
    props: {
        comment: '',
        result: Number,
    },
    emits: Object.values(EVENTS),
    components: {
        'n-icon-button': NIconButton,
        'n-button': NButton,
        'n-textarea-input': NTextareaInput,
    },
    data() {
        return {
            RESULTS: RESULTS,
            decision: null,
            approveText: gettext('Add approval'),
            rejectText: gettext('Add rejection'),
            resetText: gettext('Reset control point'),
            ignoreText: gettext('Ignore control point'),
        };
    },
    methods: {
        initState() {
            switch (this.result) {
                case RESULTS_STATE_CHOICES.NOT_SET:
                    this.decision = null
                    break
                case RESULTS_STATE_CHOICES.OK:
                    this.decision = RESULTS.APPROVAL
                    break
                case RESULTS_STATE_CHOICES.NOT_OK:
                    this.decision = RESULTS.REJECTION
                    break
                case RESULTS_STATE_CHOICES.CORRECTED:
                    this.decision = RESULTS.NOT_SET
                    break
                case RESULTS_STATE_CHOICES.CORRECTED_OK:
                    this.decision = RESULTS.APPROVAL
                    break
                case RESULTS_STATE_CHOICES.NOT_APPLICABLE:
                    this.decision = RESULTS.IGNORED
                    break
                default:
                    console.error("Invalid result, failed to initialize decision")
                    break;
            }
        },
        onReject(choice) {
            this.$emit(EVENTS.ON_REJECT)
        },
        onApprove(choice) {
            this.$emit(EVENTS.ON_APPROVE)
        },
        onIgnore(event) {
            this.$emit(EVENTS.ON_IGNORE)
        },
        isIgnored() {
            return this.decision === RESULTS.IGNORED
        },
        isApproved() {
            return this.decision === RESULTS.APPROVAL
        },
        isRejected() {
            return this.decision === RESULTS.REJECTION
        },
    },
    created() {
    },
    mounted() {
        this.initState()
    },
    template: `
    <div class="n-flex n-col ">
    
        <div class="n-flex gap-m align-center "
             style="min-height: 50px; background-color: white; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 0 1rem">
            <n-icon-button @click="onApprove(RESULTS.APPROVAL)"
                           :outlined="!isApproved()"
                           :disabled="isIgnored()"
                           small
                           style="width:100%;max-width: unset"
                           color="approved"
                           icon-class="fas fa-check">
            </n-icon-button>
            <n-icon-button @click="onReject(RESULTS.REJECTION)"
                           :outlined="!isRejected()"
                           :disabled="isIgnored()"
                           small
                           style="width:100%;max-width: unset"
                           color="rejected"
                           icon-class="fas fa-times">
            </n-icon-button>
            <n-icon-button @click="onIgnore"
                           :title="ignoreText"
                           :outlined="!isIgnored()"
                           :icon-class="!isIgnored() ? 'fa fa-eye-slash' : 'fa fa-eye'"
                           small>
            </n-icon-button>
        </div>
    </div>
    `,
};