class EVENTS {
}

EVENTS.SELECT_MARKER = 'select-marker'
export default {
    watch: {
        markers: {
            handler() {
                this.initMap()
            },
            deep: true
        }
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            map: null,
            userMarker: null,
            facilityMarkers: [],
            markerClusterer: null,
            geocoder: null,
            autocomplete: null,
            loadingText: gettext('Loading map')
        }
    },
    props: {
        isLoading: Boolean,
        markers: Array,
        preferredCenter: {
            type: Object,
            default: undefined
        },
    },
    methods: {
        findAddress(searchKey) {
            let address = searchKey
            this.geocoder.geocode({'address': address}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    let viewport = results[0]?.geometry?.viewport
                    this.setViewport(viewport)
                }
            })
        },
        setViewport(viewport) {
            if (viewport) {
                this.map.fitBounds(viewport)
                this.map.setZoom(this.map.zoom + 1)
            }
        },
        createUserMarker() {
            const url = Constants.Map.MARKER_CONNECTED_BUILDING_ICON;
            this.userMarker = new google.maps.Marker({
                map: this.map,
                icon: {url: url},
            })
        },
        placeMarker(location) {
            this.userMarker.setPosition(location)
        },
        getMarkerContentString(marker) {
            const {facility} = marker
            if (!facility) {
                return 'Missing Facility Data'
            }
            return `<div class="map-content-container">` +
                `<div class="n-flex" style="justify-content: space-between">` +
                `<h3>${facility.address}</h3><h3><i class="fa-sharp fa-solid fa-users"></i>: ${facility.employee_count}</h3>` +
                `</div>` +
                `<h4>Last inspection: Date</h4>` +
                `<div class="n-flex gap-s">` +
                `<h4>Last result: </h4><div class="status-label approved">Approved</div>` +
                `</div>` +
                `</div>`
        },
        onMarkerClick(marker) {
            this.$emit(EVENTS.SELECT_MARKER, marker.data)
        },
        setupMarkerEvents(marker) {
            const {gMarker, data} = marker
            google.maps.event.addListener(gMarker, 'mouseover', () => {
                gMarker.popup = new google.maps.InfoWindow({content: this.getMarkerContentString(data)})
                gMarker.popup.open(this.map, gMarker)
            })
            google.maps.event.addListener(gMarker, 'mouseout', () => {
                gMarker.popup && gMarker.popup.close()
            })
            google.maps.event.addListener(gMarker, 'click', () => {
                this.onMarkerClick(marker)
            })
        },
        setupMarkers() {
            const markersForCluster = []

            this.markers.forEach((ioData) => {
                const {locations, company_name, pk} = ioData
                locations?.forEach((data) => {
                    const {location, facility} = data
                    const url = facility.is_main_facility ? Constants.Map.MARKER_IO_MAIN_ICON : Constants.Map.MARKER_IO_ICON;
                    const googleMarker = new google.maps.Marker({
                        position: {lat: parseFloat(location.lat), lng: parseFloat(location.lng)},
                        map: this.map,
                        icon: {url: url}
                    })
                    const marker = new MapMarker(googleMarker, data)
                    this.setupMarkerEvents(marker)
                    this.facilityMarkers.push(marker)
                    markersForCluster.push(googleMarker)
                })
            })

            const clusterOptions = {
                fitToMarkers: true,
                styles: [
                    Constants.Map.MARKER_CLUSTER_STYLE_M1,
                    Constants.Map.MARKER_CLUSTER_STYLE_M2,
                    Constants.Map.MARKER_CLUSTER_STYLE_M3,
                    Constants.Map.MARKER_CLUSTER_STYLE_M4
                ]
            }

            //needed to reset the maps cluster
            if (this.markerClusterer) {
                this.markerClusterer.clearMarkers()
                this.markerClusterer = null
            }

            this.markerClusterer = new MarkerClusterer(this.map,
                markersForCluster,
                clusterOptions
            )
        },
        createMap() {
            let mapEl = document.getElementById('map')
            if (!mapEl)
                return
            this.map = new google.maps.Map(mapEl, {
                center: this.preferredCenter || Constants.Map.DEFAULT_CENTER,
                zoom: Constants.Map.DEFAULT_ZOOM,
                styles: [{
                    featureType: 'poi.business',
                    stylers: [{visibility: 'off'}],
                },
                    {
                        featureType: 'poi',
                        stylers: [{visibility: 'off'}],
                    }]
            })

            this.map.addListener("bounds_changed", () => {
                this.autocomplete.setBounds(this.map.getBounds())
            })

            // this.map.addListener('click', (event) => {
            //     this.placeMarker(event.latLng)
            // })
        },
        setupAutocomplete() {
            // initialization of Google Maps Autocomplete
            let input = document.getElementById('searchAddressField')
            if (!input) console.log('No input field found for autocomplete')
            const options = {
                fields: ["address_components", "formatted_address", "geometry", "plus_code"],
                types: ["address"],
                strictBounds: false,
            }
            this.autocomplete = new google.maps.places.Autocomplete(input, options)
            this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace()
                if (!place) {
                    return
                }
                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds()

                if (!place.geometry || !place.geometry.location) {
                    return
                }

                const searchKey = place.formatted_address
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport)
                } else {
                    bounds.extend(place.geometry.location)
                }

                this.findAddress(searchKey)
            })

        },
        initMap() {
            this.setupAutocomplete()
            this.createMap()
            this.createUserMarker()
            if (this.markers.length > 0) {
                this.setupMarkers()
            }
            this.geocoder = new google.maps.Geocoder()
        },
    },
    created() {
    },
    mounted() {
    },
    template: `
      <div v-if="isLoading" class="n-flex n-col align-center jus-center loading-map-background"  style="margin: auto;" >
            <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>  
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1 > [[[loadingText]]] </h1>
        </div>
      <div v-show="!isLoading" id="map" style="position: absolute; inset:0"></div>`
}
